import React, { useRef, useState } from 'react';
import Popover from 'react-awesome-popover'
import { Button, Row, Col, Dropdown} from 'react-bootstrap';
import FilterByFilterLine from './FilterByFilterLine';
import { ChevronDown } from 'react-bootstrap-icons';

import CONSTANTS from '../../constants';

function FilterByPopover(props) {

    const target=useRef(null);
    
    const [filterRows, setFilterRows] = useState([]);

    const fieldsAvailable = [
        { label: 'Created', fieldName: 'date_created', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.DATE_TYPE, value: 'datePicker'},
        { label: 'Last Updated', fieldName: 'date_updated', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.DATE_TYPE, value: 'datePicker'},
        { label: 'Intervention Title', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.TEXT_TYPE, value: ''},
        { label: 'Status', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.STATUS_TYPE, value: props.statusList },
        { label: 'HMF Category', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.LABEL_LIST_TYPE, value: [] },
        // { label: '', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.NUMERICAL_TYPE, value: ''},
        { label: 'Assigned To', fieldName: 'custom__Assigned To', type: CONSTANTS.MARKET_ACTIONS_FIELD_TYPES.ASSIGNEE_TYPE, value: props.assigneeList}
    ]

    const onFieldSet = (itemToSet) => {
        //find the item by id in our list
        const idxOfItem = filterRows.findIndex( (item) => {
            return item.id === itemToSet.id;
        });

        //set the field on that item
        let fRows = [...filterRows];
        fRows[idxOfItem][itemToSet.field] = itemToSet.value;
        setFilterRows(fRows);

        props.onChange({src: 'FILTERBY', data: fRows});
    }

    const addRow = () => {
        let fRows = [...filterRows, {
            id: Math.random().toString(36).slice(2),
            fieldForFilter: null,
            operator: null,
            comparator: null
        }];
        setFilterRows(fRows);
    }

    const onDeleteLine = (id) => {
        //remove the filter from the array
        let fRowsD = [...filterRows];

        let indOfRow = fRowsD.findIndex( (item) => {
            return item.id === id;
        });

        fRowsD.splice(indOfRow, 1);
        setFilterRows(fRowsD);
        
        props.onChange({src: 'FILTERBY', data: fRowsD});
    }

  return (
    <Popover 
        id="popover-filter-popover"
        className='standard-dropdown dropdown-text-left tooltip-arrow'
        action="click" 
        placement="bottom-end"
        overlayColor="rgba(0,0,0,0)">
        <Dropdown variant="light" ref={target} className='standard-dropdown' >
            <Dropdown.Toggle className='dropdown-text-left'>{props.opt.label} <ChevronDown /> </Dropdown.Toggle></Dropdown>
        <div id="popover-container-filter-by"className="popover-container filter-by no-arrow">
            <Row>
                <Col>
                    <h4 className='m-i-start-06 m-b-start-04'>Filters</h4>
                </Col>
            </Row>
            {
                filterRows.map( (thisFilterRow) => {
                    return (
                        <FilterByFilterLine
                            deps={{ assigneeList: props.assigneeList, statusList: props.statusList, hmfCategories: props.hmfCategories }}
                            filterItem={thisFilterRow}
                            onSet={onFieldSet}
                            fieldList={fieldsAvailable} 
                            onDeleteLine={onDeleteLine}
                        />
                    )
                })
            }
            <div className='m-i-start-06 m-i-end-06 m-b-end-04 m-b-start-04'>
            <Button className='add-filter-btn' onClick={() => addRow()}>+ Add Filter</Button>
            </div>
        </div>
    </Popover>
  )
}

export default FilterByPopover