import React, { useEffect, useState} from 'react'; 

import _ from "lodash" // For making deep copies of objects
import { Table, Button, Modal, ButtonGroup, ButtonToolbar} from 'react-bootstrap'

import utils from "../../../utils"
import CONSTANTS from "../../../constants"
import '../../../styles/layout/_layout.scss'

function EnumEditor(props) {
  /*--------------------------State variables---------------------------*/
  const [lbWord, setLbWord] = useState(props.enum === null?
    _.cloneDeep(CONSTANTS.SHELL_ENUM): 
    props.enum)
  
  const [errors, setErrors] = useState([])
  
  useEffect(() => {
    var tempEnum = props.enum === null?
    _.cloneDeep(CONSTANTS.SHELL_ENUM): 
    props.enum
    tempEnum.enum_type = props.enumType
    setLbWord(tempEnum)
  }, [props.enum, props.enumType])
  

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();
    let foundPartners = new Set()
    var submissionErrors = []
    for (let i = 0; i<lbWord.alt_enums.length; i++){
      if (foundPartners.has(lbWord.alt_enums[i].partner)){
        submissionErrors.push(lbWord.alt_enums[i].partner + " has two or more alternatives")
      }
      if (lbWord.alt_enums[i].partner !== null){
        foundPartners.add(lbWord.alt_enums[i].partner)
      }
    }
    setErrors(submissionErrors)
    if (submissionErrors.length > 0)  {
      return
    }

    const data = lbWord
    var result

    if (props.enum === null){
      result = await utils.upsert("create_enum", props.apiKey, data)
    } else{
      result = await utils.upsert('update_enum', props.apiKey, data)
    }
    
    if (result !== null) {
      props.closePopup()
    }
  }


  /*--------------------------------Manage state variables-----------------------*/
  function addSynonym() {
    const tempAltEnums = lbWord.alt_enums
    tempAltEnums.push({alt_enum: '', partner: null})
    setLbWord({...lbWord, alt_enums:tempAltEnums})
  }

  function setSynonym(synonym, index){
    const synonyms = [...lbWord.alt_enums]
    synonyms[index].alt_enum = synonym
    setLbWord({...lbWord, alt_enums: synonyms})
  }

  function deleteSynonym(index) {
    const tempAltEnums = [...lbWord.alt_enums]
    tempAltEnums.splice(index, 1)
    setLbWord({...lbWord, alt_enums: tempAltEnums})
  }

  function printLbWord(){
    console.log(lbWord)
  }
  return (
    <Modal size='lg' show = {true} onHide={props.closePopup} className={'alc-app'}>
      <Modal.Header>
        <Modal.Title>LB Word Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='input-text'>Word</div>
        <input type='text'
          className='form-ctrl'
          disabled={props.enum !== null}
          value={lbWord.enum}
          onChange={(e) => (e) = setLbWord({ ...lbWord, enum: e.target.value })}
        />
          <div className='input-text'>Type</div>
          <input type='text'
            className='form-ctrl'
            disabled
            value={lbWord.enum_type}
            onChange={(e) => (e) = setLbWord({ ...lbWord, enum_type: e.target.value })}
          />
        <div><strong>Synonyms</strong></div>

        <Table>
          <thead className='table-heading'>
            <tr>
              <td>Synonym</td>
              {props.enum !== null ? <td><div className='clickable-text'
                onClick={addSynonym} size="sm">add</div></td> : null
              }
            </tr>
          </thead>
          <tbody>
            {lbWord.alt_enums.map((ae, i) => {
              return <tr key={i}>
                <td>
                  <input
                    className='form-ctrl'
                    value={ae.alt_enum}
                    onChange={(e) => setSynonym(e.target.value, i)}
                  />
                </td>
                <td className='right-align'>
                  <Button variant="danger"
                    size="sm"
                    
                    onClick={() => deleteSynonym(i)}>-</Button>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
        {errors.length > 0 ?
          <div>
            <div className="error">Errors:</div>
            {
              errors.map((e) => <li className="error">{e}</li>)
            }
          </div>
          : null}
            <ButtonToolbar className='editor-btn'>
              <ButtonGroup>
                <Button onClick={props.closePopup} variant="secondary" className='m-i-end-03'>Close</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={handleSubmit} variant="primary"  className='m-i-end-03'>Submit</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={printLbWord} variant="primary">Check Word</Button>
              </ButtonGroup>
            </ButtonToolbar>
      </Modal.Body>
    </Modal>

  )
}

export default EnumEditor;