import React, { useState, useEffect } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import CountryFilter from './CountryFilter';
import DiseaseFilter from './DiseaseFilter';

function CEFilterBar({ onFilterChanged, countryList, diseaseList, curFilters }) {

  const [integration, setIntegration] = useState();
  const [checkedInt, setCheckedInt] = useState(false);
  const [checkedNoInt, setCheckedNoInt] = useState(false);


  const integrationAPI = 'tags';
  const integrationParams = 'search=integration'

  const getTagID = async (api, params) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_CE_WP_URL}${api}?${params}`, {
      });
      let data = await res.json();
      if (api === 'tags' && params === 'search=integration') {
        setIntegration(data);
      };
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getTagID(integrationAPI, integrationParams);
  }, [])

  const myFilterListChanged = (newChange) => {
    let intID = integration[0].id;

    if (newChange === 'integration-campaigns') {
      setCheckedInt(!checkedInt);

      if (!checkedInt === true && checkedNoInt === true) {
        setCheckedNoInt(false);
      }

      onFilterChanged({
        name: 'tags',
        label: 'integration-campaigns',
        value: intID,
        checked: !checkedInt
      })
    }
    else if (newChange === 'integration-none') {
      setCheckedNoInt(!checkedNoInt);

      if (!checkedNoInt === true && checkedInt === true) {
        setCheckedInt(false);
      }

      onFilterChanged({
        name: 'tags_exclude',
        label: 'integration-none',
        value: intID,
        checked: !checkedNoInt
      })
    } else {
      onFilterChanged(newChange);
    }
  };

  return (
    countryList && diseaseList && integration ?
      <>
      <Row className='extra-spacer'>
        <Col>
          <CountryFilter
            countryList={countryList}
            filterChangeHandler={myFilterListChanged}
            curFilter={curFilters}
          />
        </Col>
      </Row>
      <Row className='extra-spacer'>
        <Col>
          <DiseaseFilter
            diseaseList={diseaseList}
            filterChangeHandler={myFilterListChanged}
            curFilter={curFilters}
          />
        </Col>
      </Row>  
      <Row>
        <Col>
          <h5 className='int-h5'>Integration</h5>
        </Col>
      </Row>  
      <Row>
        <Col>
          <div className='checkbox'>
            <input type='checkbox'
              name="integration-none"
              checked={checkedNoInt}
              onChange={(e) => myFilterListChanged(e.target.name)}
            />
             <label style={{marginInlineStart: '6px'}} for="integration-none"> Non-integrated Campaigns</label>
          </div>
        </Col>
      </Row>  
      <Row>
        <Col>
          <div className='checkbox'>
            <input type='checkbox'
              name="integration-campaigns"
              checked={checkedInt}
              onChange={(e) => myFilterListChanged(e.target.name)}
            />
             <label style={{marginInlineStart: '6px'}} for="integration-campaigns"> Integrated Campaigns</label>
          </div>
        </Col>
      </Row>  
      </>
      :
      <Spinner animation='border' />
  )
}

export default CEFilterBar;