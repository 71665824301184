import { models } from 'powerbi-client';

const vUtils = {

    comparison:  ( field, comparator, valueTarget) => {
        switch (comparator) {
            case 'not_equal' :
                return field !== valueTarget;
            case 'contains':
                return (field.indexOf(valueTarget) > -1);
            default:
                //equals
                return field === valueTarget;
        }
    },

    replaceFieldValueFromName: (fieldsDisplayed, fieldName) => {
        //only continue if field exists in fields list with the appropriate field name
        if(fieldName.length < 1 || !(fieldsDisplayed.fields.hasOwnProperty(fieldName))) {
            return '';
        }

        return fieldsDisplayed.fields[fieldName];

    },


    replaceTokens: (arrayItem, fields) => {
        //first, split into array on semi-colons
        if(arrayItem.indexOf('**') > -1) {
            let thefieldItems = arrayItem.split('**');
            if(thefieldItems.length > 1){
                return this.replaceFieldValueFromName(fields,thefieldItems[1]);
            }
        }

        return arrayItem;
    },


    replaceFieldRefWithValue: function(fieldStr, fields) {
        //returns an array for a pyramid viz based on the meta field pyramidFilterString

        if(!fieldStr || fieldStr.length < 1) {
            return '';
        }

        //first, if there are multiples, split on semi-colons
        if(fieldStr.indexOf(';') > -1) {
            let multipleItems = fieldStr.split(';');
            if(multipleItems.length > 1) {
                return multipleItems.map( (thisItem) => {
                    return this.replaceTokens(thisItem.trim(), fields);
                })
            }
            
            
        }

        return [this.replaceTokens(fieldStr, fields)];
    },

    filterForApplicableOverrides: (overrideArray, contentFields, tenant) => {

        //first, check tenant on override, if not set then is universal

        let usedOverrides = overrideArray.filter( (thisOvr) => {
            return !thisOvr.fields.hasOwnProperty('specificToTenant_TextField') || 
                    thisOvr.fields.specificToTenant_TextField.toLowerCase() === tenant.tenant.fields.tenantslug.toLowerCase();
        })

        //then check to see if matches this particular item

        usedOverrides = usedOverrides.filter( (thisOverride) => {
            return this.comparison(contentFields.fields[thisOverride.fields.conditionFieldName], thisOverride.fields.conditionOperator, thisOverride.fields.conditionValue);
        })

        return usedOverrides;
    },

    addValueToFilter: function (fieldsDescrs, targetTable, targetColumn, operator, filterValue, requireSingleSelection) {
        let itemToAdd = {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: targetTable,
                column: targetColumn
            },
            filterType: models.FilterType.BasicFilter,
            operator: operator,
            values: this.replaceFieldRefWithValue(filterValue, fieldsDescrs),
            requireSingleSelection: requireSingleSelection ? requireSingleSelection : false
        }

        //because agility does not add the property if no items
        if(fieldsDescrs.fields.hasOwnProperty('pBI_Filter')) {
            return [itemToAdd, ...fieldsDescrs.pBI_Filter ];
        } else {
            return [itemToAdd ];
        }
    },

    getOptionsForPBI: function (fieldDescr, fields, tenant) {
        let retObj = { reportID: '', datasetID: '' };

        //temp fields called pBIReportID and pBIDatasetID

        if(fieldDescr.hasOwnProperty('pBIReportID') && fieldDescr.hasOwnProperty('pBIDatasetID')) {
            retObj.reportID = fieldDescr.pBIReportID;
            retObj.datasetID = fieldDescr.pBIDatasetID;
        }

        if(fieldDescr.hasOwnProperty('pBI_Filter')) {
            //filters for this power bi viz
           
             let filtersToAdd = fieldDescr.pBI_Filter.map( (thisFilter) => {
                return {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                        table: thisFilter.fields.targetTable,
                        column: thisFilter.fields.targetColumn
                    },
                    filterType: models.FilterType.BasicFilter,
                    operator: thisFilter.fields.operator,
                    values: this.replaceFieldRefWithValue(thisFilter.fields.filterValues, fields),
                    requireSingleSelection: thisFilter.fields.requireSingleSelection ? thisFilter.fields.requireSingleSelection : false
                }
            });

            retObj.filters = filtersToAdd;

        }

        //check overrides
        if(fieldDescr.hasOwnProperty('overrides')) {
            let functionalOverrides = this.filterForApplicableOverrides(fieldDescr.overrides, fields, tenant);

            functionalOverrides.forEach( (thisOverride) => {
                if(thisOverride.fields.hasOwnProperty("pBIReportID") && thisOverride.fields.hasOwnProperty("pBIDatasetID")) {
                   
                    retObj.reportID = thisOverride.fields.pBIReportID;
                    retObj.datasetID = thisOverride.fields.pBIDatasetID;
                }

                //TODO: check vizFilters in override
                if(thisOverride.fields.hasOwnProperty("pBI_Override_Filters") && thisOverride.fields.pBI_Override_Filters !== null){
                    //filters for this power bi viz
                    let filtersToAdd = thisOverride.fields.pBI_Override_Filters.map( (thisFilter) => {
                        return {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: thisFilter.fields.targetTable,
                                column: thisFilter.fields.targetColumn
                            },
                            filterType: models.FilterType.BasicFilter,
                            operator: thisFilter.fields.operator,
                            values: this.replaceFieldRefWithValue(thisFilter.fields.filterValues, fields),
                            requireSingleSelection: thisFilter.fields.requireSingleSelection ? thisFilter.fields.requireSingleSelection : false
                        }
                    });
        
                    retObj.filters = filtersToAdd;
                }
            });
        }

        return retObj;
    }
}

export default vUtils;
