import React, { useEffect, useState, useRef } from 'react';

import ResizableTable from './ResizableTable';
import ListPagination from '../Layout/ListPagination';
import utils from '../../utils';
import '../../styles/layout/_layout.scss';

/*
  Arguments
  data: a list of data  to be rendered in the table
  metaData: a list in the format: {
    title:<the column name>, 
    dispField:<the field to show in the table>, 
    filterField:<the field to filter on if applicable none implies no filter. A dropdown won't be rendered>
    isMulti:<true/false> true allows multiple selections in the dropdown, false allows only one
  }
  sortData: a list in the following format: {
    title: <the name in the select dropdown>
    field: <the field to sort by. This must be a single field. To sort by multiple concatenate them into on field>
  } sortData moved up to StatefulSearchTable, used by SettingsPanel and SortOrderSelect
  onRowClick: a function that should be triggered when a row is clicked. The return will be the content of the row.
  dispColSettings: The settings for the columns that should be displayed. A list in this format: {
    field: <a string that exists in the title field of the metaData argument>
    width: <the desired width of the column if applicable>
  }
  handleWidthChange: a function that should be called whenever there is a change to the column widths
  showDeleted: T/F for whether deleted items should be shown
  rowsPerPage: the number of rows per page
  dynamicColumns: whether the columns should be user selectable
  dynamicWidths: whether the column widths should be user adjustable
*/

function SearchTable({
  data,
  metaData,
  sortOrder,
  onRowClick,
  dispColSettings,
  handleWidthChange,
  showDeleted = false,
  secondRow = null,
  rowsPerPage = 20,
  dynamicColumns = false,
  dynamicWidths = false,
  mouseUp,
}) {
  // Stores the filters. Example data structure {'form': 'Liquid', 'product_name': ['Prevnar', 'Prevenar']}
  const [filters, setFilters] = useState({});
  // Holds the data after filtering
  const [filteredData, setFilteredData] = useState([]);
  // Holds the data after sorting
  const [sortedData, setSortedData] = useState([]);
  // Holds the sorted data that should be displayed on the current page
  const [pageData, setPageData] = useState([]);
  // Columns
  const [columnData, setColumnData] = useState([]);
  // Pagination
  const [curPage, setCurPage] = useState(1);
  const [paginationClicked, setPaginationClicked] = useState(false);
  const totalItems = filteredData.length;

  // Ref to store the scroll position
  const paginationRef = useRef(null);

  // Set column data based on user settings
  useEffect(() => {
    if (!metaData) {
      return;
    }
    let temp = dispColSettings.map((dcs) => {
      // Get the metaData element with a title matching the field
      const tempList = metaData.filter((md) => md.title === dcs.field);
      const tempElt = tempList[0];
      // It may not exist if the user adds a field to their settings then we delete
      // the field after the fact. Do a map returning null for that.
      if (tempElt) {
        tempElt.width = dcs.width;
        return tempElt;
      } else {
        return null;
      }
    });
    // Now filter out the nulls to set the shown columns.
    setColumnData(temp.filter((d) => d !== null));
  }, [metaData, dispColSettings]);

  // Filter the data based on user-selected filters
  useEffect(() => {
    if (data.length === 0) {
      return [];
    }
    var temp = [...data];
    const filterKeys = Object.keys(filters);

    if (!showDeleted) {
      temp = temp.filter((d) => d?.lb_status !== 'Deleted' && d?.deleted !== true);
    }

    for (let i = 0; i < filterKeys.length; i++) {
      let curField = filterKeys[i];
      let curFilter = filters[curField];
      if (curFilter === null || curFilter.length === 0) {
        continue;
      }
      let filterValue;
      if (Array.isArray(curFilter)) {
        filterValue = curFilter.map((elt) => {
          return elt.value;
        });
      } else {
        filterValue = curFilter.value;
      }
      const filterIsArray = Array.isArray(filterValue);
      const dataIsArray = Array.isArray(temp[0][curField]);
      let curFilter1 = filterIsArray ? filterValue[0] : null;
      let curFilter2 = filterIsArray && filterValue.length > 1 ? filterValue[1] : null;
      let curFilter3 = filterIsArray && filterValue.length > 2 ? filterValue[2] : null;
      let curFilter4 = filterIsArray && filterValue.length > 3 ? filterValue[3] : null;
      let curFilter5 = filterIsArray && filterValue.length > 4 ? filterValue[4] : null;

      /*
      Case 1: Filter and data are strings or null
      Case 2: Filter is a list, data is string or null
      Case 3: Filter is string or null, data is a list
      Case 4: Filter is a list, data is a list
      */
      switch (true) {
        case !filterIsArray && !dataIsArray:
          temp = temp.filter((d) => d[curField] === filterValue);
          break;
        case !filterIsArray && dataIsArray:
          temp = temp.filter((d) => d[curField].includes(filterValue));
          break;
        case filterIsArray && !dataIsArray:
          temp = temp.filter(
            (d) =>
              d[curField] === curFilter1 ||
              (d[curField] === curFilter2 && curFilter2) ||
              (d[curField] === curFilter3 && curFilter3) ||
              (d[curField] === curFilter4 && curFilter4) ||
              (d[curField] === curFilter5 && curFilter5)
          );
          break;
        case filterIsArray && dataIsArray:
          temp = temp.filter(
            (d) =>
              d[curField].includes(curFilter1) ||
              (d[curField].includes(curFilter2) && curFilter2) ||
              (d[curField].includes(curFilter3) && curFilter3) ||
              (d[curField].includes(curFilter4) && curFilter4) ||
              (d[curField].includes(curFilter5) && curFilter5)
          );
          break;
        default:
          console.log('Unknown option, please review');
      }
    }
    // Handle page number updates after filtered data changes
    setFilteredData(temp);
  }, [filters, rowsPerPage, data, showDeleted]);

  // Reset to page 1 when filters change
  useEffect(() => {
    setCurPage(1);
  }, [filters]);

  // Sort the data based on user-selected sort order
  useEffect(() => {
    if (filteredData.length === 0 || !sortOrder) {
      return;
    }
    var temp;
    const tempFilteredData = [...filteredData];
    const field = sortOrder.value.field;
    if (typeof filteredData[0][field] === 'string' || filteredData[0][field] === null) {
      temp = tempFilteredData.sort((a, b) =>
        utils.createStrSortOrder(a[field] ? a[field].toLowerCase() : '', b[field] ? b[field].toLowerCase() : '')
      );
    } else {
      if (sortOrder.value?.desc) {
        temp = tempFilteredData.sort((a, b) => {
          return b[field] - a[field];
        });
      } else {
        temp = tempFilteredData.sort((a, b) => {
          return a[field] - b[field];
        });
      }
    }
    setSortedData(temp);
  }, [sortOrder, filteredData]);

  // Get the data for the current page
  useEffect(() => {
    setPageData(sortedData.slice((curPage - 1) * rowsPerPage, curPage * rowsPerPage));
  }, [curPage, sortedData, rowsPerPage]);

  function handleFilterChange(field, filter) {
    const tempFilters = { ...filters };
    tempFilters[field] = filter;
    setFilters(tempFilters);
  }

  useEffect(() => {
    setFilters({});
  }, [dynamicColumns, dynamicWidths]);


  // Pagination
  const handlePageChange = (pageNumber) => {
    setCurPage(pageNumber);
    setPaginationClicked(true);
  };

  // Restore the scroll position after data is updated, only if pagination was clicked
  useEffect(() => {
    if (paginationClicked && paginationRef.current) {
      paginationRef.current.scrollIntoView({ behavior: 'smooth' });
      setPaginationClicked(false);
    }
  }, [pageData, paginationClicked]);

  return (
    <div>
      {secondRow}
      <ResizableTable
        headers={metaData}
        filteredHeaders={columnData}
        filteredData={filteredData}
        minCellWidth={120}
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleWidthChange={handleWidthChange}
        pageData={pageData}
        onRowClick={onRowClick}
        isResizable={dynamicWidths}
        mouseUp={mouseUp}
      />

      <div ref={paginationRef}>
        <ListPagination
          curPage={curPage}
          articlesPerPage={rowsPerPage}
          totalArticles={totalItems}
          paginate={handlePageChange}
        />
      </div>
    </div>
  );
}

export default SearchTable;
