import React from 'react';
import utils from '../../utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircleFill } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function ToolTip({tooltip}) {

  return (
    <>
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip
            placement='top'
            className="in" 
            id="tooltip-top"
            >
              <div dangerouslySetInnerHTML={tooltip ? utils.getHTML(tooltip) : ''}></div>
          </Tooltip>
        }
      >
        <InfoCircleFill />
      </OverlayTrigger>
    </>
  )
}

export default ToolTip