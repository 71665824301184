import React from 'react';
import AppSideNav from './Layout/Nav/AppSideNav';
import { Col, Row, Card } from 'react-bootstrap';
import CONSTANTS from '../constants';
import FieldDisplay from './FieldDisplay';
import { Route, useRouteMatch, Switch, Redirect, useParams } from 'react-router-dom';
import BreadCrumbBar from './Layout/Nav/BreadCrumbBar';
import utils  from '../utils';
import BubbleRow from './BubbleRow';

import '../styles/components/_urlDrivenApp.scss';
import RelatedArticleTeaser from './Layout/RelatedArticleTeaser';

function UrlDrivenAppDisplay({ tenant, content, navs, tntFor=null, appType, defaultPage, displayFieldName, logoFieldToShow=null, showRelatedNewsField=null, bubbleFields=[], hasSubItems=false }) {

  const { path } = useRouteMatch();
  const {slug} = useParams();
   

  let useNavs = utils.cleanUpNavs(navs, content);

  const valExistsInList = (valToCheck, listToLookAt) => {
    let fnd = listToLookAt.filter( (thisItem) => {
      return thisItem.field.toLowerCase() === valToCheck.toLowerCase();
    });

    return fnd.length > 0;
  }


  const takeOutFieldsInBubbles = (contentToStrip, bubbleFields) => {

    let returnedFields = {};

    Object.keys(contentToStrip).forEach( (thisProp) => {
      if(!valExistsInList(thisProp, bubbleFields)) {
        returnedFields[thisProp] = contentToStrip[thisProp];
      }
    });

    return { fields: returnedFields };

  }

  const getAppTitle = (tnt, appType) => {
    let appInQuestion = tnt.tenant.fields.apps.find( (thisApp) => {
      return thisApp.fields.apptype.toLowerCase() === appType.toLowerCase();
    });

    return appInQuestion && appInQuestion.fields && appInQuestion.fields.appAlternateTitle ? appInQuestion.fields.appAlternateTitle : null;
  };

  const noBubbleContent = takeOutFieldsInBubbles(content.fields, bubbleFields);

  return (
   
      <>
        <BreadCrumbBar 
          appName={appType} 
          appAltTitle={getAppTitle(tenant, appType)}
          displayFieldName={displayFieldName} 
        />
        <div className={`app-page-display ${appType}`}>
        <h2 className='m-b-end-08'>{content.fields.toolName ? content.fields.toolName : content.fields.name ? content.fields.name : '' }</h2>
          <Row>
            <Col sm={12} md={3}>
              <Card className="side-nav weird-corners app-sidenav">

                {
                  logoFieldToShow && content.fields.hasOwnProperty(logoFieldToShow) ?
                      <Card.Img variant="top" src={ content.fields[logoFieldToShow].url } />
                  : null
                }

                <AppSideNav
                  apppath={`${CONSTANTS.APPS_BY_APPTYPE[appType]}`}
                  tenantSettings={tenant}
                  appname={CONSTANTS.APPS_BY_APPTYPE[appType]}
                  item={content}
                  navs={useNavs} 
                  subItems={hasSubItems} />
              </Card>
            </Col>

            <Col sm={12} md={9}>
              <Switch>
                <Route exact path={`${path}/:infoID`}>
                    {
                      bubbleFields && bubbleFields.length > 0 ?
                        <BubbleRow itemArray={bubbleFields} objToRender={content} />
                      : null
                    }
                    {
                      showRelatedNewsField ?
                        <RelatedArticleTeaser
                        tenant={tenant}
                        entity={content} 
                        entityType={''} 
                        header='Related News & Company Intelligence'
                        tagQuery={false}
                        searchTerm={slug}
                        />
                      : null
                    }
                    {
                      
                      <FieldDisplay 
                        fields={noBubbleContent} 
                        fieldsDescr={navs} 
                        infoToShow={defaultPage} 
                        tenant={tenant} />

                    }
                </Route>
                <Redirect exact from={`${path}/`} to={`${path}/${defaultPage}`} />
              </Switch>
            </Col>
          </Row>
        </div>
      </>
  )
}
export default UrlDrivenAppDisplay;