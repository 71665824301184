import React, { useEffect, useState } from 'react'; 
import Select from 'react-select';
import { Table, FormControl, Col, Row, Button} from 'react-bootstrap';

import ProductSelectEditor from './ProductSelectionEditor';
import utils from "../../../utils"
import '../../../styles/layout/_layout.scss'

function ScheduleRoundEditor(props) {
  const [demandComputation, setDemandComputation] = useState([])
  const [coverageOptions, setCoverageOptions] = useState(null)
  const [defaultUptakes, setDefaultUptakes] = useState(null)
  
  
  useEffect(()=>{
    async function updateYearsAndUptake(){
      var doUpdate = true
      // Check all of the product selections to see if there is an error before making the
      // API call
      if (props.scheduleRound.product_selections.length===0){
        doUpdate=false
      }
      
      for (let i = 0; i<props.scheduleRound.product_selections.length; i++){
        if (
            props.scheduleRound.product_selections[i].min_age ===null ||
            props.scheduleRound.product_selections[i].max_age === null ||
            props.scheduleRound.product_selections[i].updateDemand === false
          ){
            doUpdate=false
          }
      }
      if (props.uptake !== null && doUpdate === true){
        console.log("Trying update")
        const demandResponse = await utils.upsert("get_demand", props.apiKey, {'schedule_round':props.scheduleRound, 
        'routine':props.routine,
        'gender': props.gender,
        'geography': props.geography,  
        'uptake':props.uptake,
        'vaccine_group':props.vaccineGroup
      })
      setDemandComputation(demandResponse.demand_response)
      setDefaultUptakes(demandResponse.default_uptakes)
      }
    }
    updateYearsAndUptake()
  },[props.scheduleRound, props.scheduleRound.product_selections.length, 
      props.uptake, props.apiKey, props.gender, props.geography, props.routine, props.vaccineGroup])

  useEffect(()=> {
    if (props.coverages === null) {
      return
    }
    setCoverageOptions(props.coverages.map((c) => {return {label: c.coverage_name, value: c}}))
  }, [props.coverages])


  function addSelection(){
    // Get all schedule rounds
    const temp = [...props.scheduleRound.product_selections,{
      'min_age': null,
      'min_age_unit':'Year(s)',
      'max_age': null,
      'max_age_unit':'Year(s)',
      'wastage_override': null,
      'coverage_id': null,
      'coverage':null,
      'start_year':2000,
      'start_month':1,
      'end_year':2040,
      'end_month':12,
      'activity_type':null,
      'product_characteristic_id':null,
      'product_characteristic':null,
      'wastage_id':null,
      'wastage':null,
      'multiplier':null,
      'is_intel':false,
      'uptake_overrides':[]
    }]
    props.onChange({...props.scheduleRound, product_selections:temp})
  }

  function deleteSelection(i){
    let temp = props.scheduleRound.product_selections
    temp.splice(i, 1)
    props.onChange({...props.scheduleRound, product_selections: temp})
  }

  function getDemandValue(y,field){
    if (!demandComputation){
      return null
    }
    const dc = demandComputation.filter((dc)=>{
      return dc.year === y
    })
    const maxDigits = field==='coverage'?2:0
    
    if (dc.length>0){
      const demandValue=dc[0][field]
      // If it is a campaign with no coverage then return 1
      if (field==='coverage' && (demandValue === null || demandValue === undefined)){
        return 1
      } else{
        return demandValue.toLocaleString('en', {maximumFractionDigits: maxDigits})
      }
        
    }
  }
  
  return (
    <div>
      {props.scheduleRound===null?null:
        <>
          <Row>
            <Col sm={3}>
                <div className='input-text'>Dose Sequence</div>
                <FormControl
                  type="number"
                  value={props.scheduleRound.dose_sequence}
                  disabled
                  onChange={(e) => {
                      props.onChange({...props.scheduleRound, dose_sequence:e.target.value})

                    }
                  } 
                />
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              {
                (props.routine === true && props.scheduleRound.coverage === null)?
                  <strong className='error'>Coverage</strong>
                :
                <div className='input-text'>Coverage</div>
              }
              
              <Select
                className='select'
                options ={coverageOptions}
                value={{label:props.scheduleRound.coverage === null?
                  null:
                  props.scheduleRound.coverage.coverage_name}}
                isClearable
                onChange= {(e) => {
                    if (e === null){
                      props.onChange({
                        ...props.scheduleRound,
                        coverage_id:null,
                        coverage:null
                      })
                    } else {
                      props.onChange({
                        ...props.scheduleRound,
                        coverage_id:e.value.coverage_id,
                        coverage:e.value
                      })
                    }
                  }
                }
              />
              <Col>
              <Button onClick={addSelection}>Add Selection</Button>{' '}
              <Button onClick={props.deleteRound} variant = "danger">Delete Round</Button>
            </Col>
            </Col>
          </Row>
          
          <Table striped size = "sm">
            <thead className="table-heading">
                <tr>
                  <th>Delete/Move</th>
                  <th>Min Age</th>
                  <th>Max Age</th>
                  <th>Product</th>
                  <th>Wastage</th>
                  <th>Multiplier</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Campaign Type</th>
                  <th>Is Intel?</th>
                  <th>Int. Notes</th>
                  <th>Ext. Notes</th>
                  <th>Schedule</th>
                  {props.programYears.map((y) => {
                    return <th>{y}</th>
                  })}
                </tr>
              </thead>
              <tbody className ="table-body">
              
              {props.scheduleRound.product_selections.map((ps, i) => {
                return <ProductSelectEditor
                    productSelection={ps}
                    routine = {props.routine}
                    gender = {props.gender}
                    productCharacteristics={props.productCharacteristics}
                    coverages={props.coverages}
                    wastages={props.wastages}
                    doseSequence={props.scheduleRound.dose_sequence}
                    noSchedRounds={props.noSchedRounds}
                    onMoveUp={()=>props.onMove(props.scheduleRound.dose_sequence, i, props.scheduleRound.dose_sequence-1)}
                    onMoveDown={()=>props.onMove(props.scheduleRound.dose_sequence, i, props.scheduleRound.dose_sequence+1)}
                    defaultUptake={(defaultUptakes)?defaultUptakes[i]: null}
                    doseYears={props.programYears}
                    onChange = {(e) => {
                      const temp = [...props.scheduleRound.product_selections]
                      temp[i] = e
                      props.onChange({...props.scheduleRound, product_selections:temp})
                      }
                    }
                    deleteSelection = {()=> deleteSelection(i)}
                    apiKey={props.apiKey}
                  />
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {props.routine===true && props.scheduleRound.coverage===null?
                      <strong className='error'>Coverage</strong>:
                      <strong>Coverage</strong>
                    }
                  
                  </td>
                  {props.programYears.map((y)=>{
                    return <td>
                      {getDemandValue(y, 'coverage')}
                    </td>
                  })}
                </tr>
                
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><strong>Target Pop</strong></td>
                  {props.programYears.map((y)=>{
                    return <td>
                      {getDemandValue(y, 'target_population')}
                    </td>
                  })}
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><strong>TRS</strong></td>
                  {props.programYears.map((y)=>{
                    return <td>
                      {getDemandValue(y, 'trs')}
                    </td>
                  })}
                </tr>
              </tbody>
            
          </Table>
          
          </>
        }
        
      </div>
      
  )
}


export default ScheduleRoundEditor