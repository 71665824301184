const checkForOrgErrors = (organizations) => {
  // Check organization errors
  const foundProdOrgs = new Set()
  var prodOrg
  let orgErrors = [];
  for (let i = 0; i<organizations.length; i++){
    prodOrg = organizations[i]
    if (prodOrg.organization_id === null){
      orgErrors.push("Product organization " + (i+1) + " has no organization defined")
    }
    if (prodOrg.org_role === null){
      orgErrors.push("Product organization " + (i+1) + " has no role defined")
    }
    if (foundProdOrgs.has(prodOrg.organization_id + prodOrg.org_role)){
      orgErrors.push("Product organization " + (i+1) + " already exists")
    }
    foundProdOrgs.add(prodOrg.organization_id + prodOrg.org_role)
  }
  
  return orgErrors
}

export default checkForOrgErrors;