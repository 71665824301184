import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';
import BreadCrumbBar from '../../components/Layout/Nav/BreadCrumbBar';
import CompanyDisplay from '../../components/CompanyProfiles/CompanyDisplay';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import '../../styles/layout/_layout.scss';
import '../../styles/components/_coProApp.scss'
import NavUtils from '../../NavUtils';
import appUtils from '../../appsUtils';


function CoProfileMainPage({ tenant, token, singularCompany=false }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES);

    if(!hasAccess) {
        history.push('/');
    }

    let { slug } = useParams();
    let [ profile, setProfile ] = useState();
    let [ navDescr, setNavDescr ] = useState();
    let [ loadingProfile, setLoadingProfile ] = useState(true);

    let tenantSlug = tenant.tenant.fields.tenantslug;

    const getProfiles = async () => {
        setLoadingProfile(true);
        let extraParams = `force_id=${tenant.tenant.fields.aDGroupID}`;
        let jsonresponse = await utils.getTenantContent(token, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES, tenantSlug, tenant.ourUserID, extraParams);

        if( jsonresponse.appFields){
            setNavDescr(jsonresponse.appFields);
        }
        
        let pickedProfile;

        if(singularCompany) {
            const profileApp = appUtils.getAppInWkspcListByAppType(tenant.tenant, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES);

            //we store what company slug to pull up in the appSpecificDataField of the profile app if it is a singular company profile
            if(profileApp && profileApp.fields.appSpecificDataField ) {
                
                pickedProfile = jsonresponse.content.filter( (thisProf) => {
                    return thisProf.fields.slugname.toUpperCase() === profileApp.fields.appSpecificDataField.toUpperCase();
                });    

            }
            
        } else {
            //pick the profile by the slug parameter
            pickedProfile = jsonresponse.content.filter( (thisProf) => {
                return thisProf.fields.slugname.toUpperCase() === slug.toUpperCase();
            });
        }

        

        if(pickedProfile && pickedProfile.length > 0) {
            setProfile(pickedProfile[0]);
        } else {
            setProfile(null);
        }

        setLoadingProfile(false);
    }

    useEffect( (tenantSlug, slug) => {
        try{
            setLoadingProfile(true);
            getProfiles();
        } catch(error) {
            console.log(error);
            setLoadingProfile(false);
        }
    // eslint-disable-next-line
    },[ tenantSlug, slug ]);

    const getBreadCrumbName = (tenInfo) => {
        let breadCrumb;

        tenInfo.tenant.fields.apps.forEach((app) => {
            if (app.fields.apptype === "company_profiles") {
                if (app.fields.appAlternateTitle) {
                    breadCrumb = 'my_company_profile';
                } else {
                    breadCrumb = 'company_profiles';
                }
            }
        })
        return breadCrumb;
    }

    return (
        !loadingProfile && tenant ?
            <>
                <BreadCrumbBar 
                    appName={getBreadCrumbName(tenant)} 
                    item={profile && profile.fields ? {title: profile.fields.agilityContentModelCompanyName} : null} 
                    displayFieldName="title" />
                {
                    profile !== null ?
                        <CompanyDisplay tenant={ tenant } profile={ profile } navs={navDescr} token={token} />
                    : <div className="text-center">No Company with the slug: {slug} is in our list.</div>
                }
            </>
        : <ListSkeletonLoader preview={false} />
    )
}

export default CoProfileMainPage;
