import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import CENewsList from './CENewsList';
import CEFilterBar from './CEFilterBar';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';

function WPEngineList({ tenantSetting, preview = false }) {
  //this component wraps a list item and provides the WP Engine get call results to the children

  const APIS = {
    countryAPI: 'post_region_country?categories=245&',
    diseaseAPI: 'post_health_domain?categories=245&',
    newsAPI: 'posts?categories=245&'
  }

  const [countryList, setCountryList] = useState();
  const [diseaseList, setDiseaseList] = useState();
  const [fullNewsList, setFullNewsList] = useState();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  //Looks for filter in filter array and removes filter
  const removeFilter = (filterList, filterType) => {

    for (let i = 0; i < filterList.length; i++) {
      if (filterList[i].name === filterType) {
        filterList.splice(i, 1);
        return filterList;
      }
    }
    return filterList;
  }

  const filterOff = (filterList, filterType) => {
    const index = filterList.findIndex((item) => {
      return item.name === filterType;
    })
    filterList.splice(index, 1)
    return filterList
  }

  // Adds new filter
  const addNewFilter = (filterList, newFilter) => {
    filterList.push(newFilter);
    return filterList;
  }

  const filterChanged = (newFilter) => {

    let tempState = [...filters];

    if (newFilter.name === 'post_region_country') {
      tempState = removeFilter(tempState, 'post_region_country')
      tempState = addNewFilter(tempState, newFilter)
    }

    if (newFilter.name === 'post_health_domain') {
      tempState = removeFilter(tempState, 'post_health_domain')
      tempState = addNewFilter(tempState, newFilter)
    }

    if (newFilter === 'clear-filter-country') {
      tempState = filterOff(tempState, 'post_region_country')
    }

    if (newFilter === 'clear-filter-disease') {
      tempState = filterOff(tempState, 'post_health_domain')
    }

    if (newFilter.name === 'tags') {

      if (newFilter.checked === false) {
        tempState = filterOff(tempState, newFilter.name)
      } else {
        tempState = removeFilter(tempState, 'tags_exclude')
        tempState = addNewFilter(tempState, newFilter)
      }
    }

    if (newFilter.name === 'tags_exclude') {

      if (newFilter.checked === false) {
        tempState = filterOff(tempState, newFilter.name)
      } else {
        tempState = removeFilter(tempState, 'tags')
        tempState = addNewFilter(tempState, newFilter)
      }
    }

    setFilters(tempState)
  }

  const setDataInState = (list, api) => {

    if (api === APIS.countryAPI) {
      setCountryList(list);
    }

    if (api === APIS.diseaseAPI) {
      setDiseaseList(list);
    }

    if (api === APIS.newsAPI) {
      setFullNewsList(list);
    }

    setLoading(false)
  }

  const getData = async (filters, api) => {
    let pageNo = 1;
    let params = '';

    if (filters && filters.length > 0) {
      filters.forEach((item) => {
        if (params) {
          params = `${params}&${item.name}=${item.value}&`;
        } else {
          params = `${item.name}=${item.value}&`;
        }
      })
      params = `${params}&per_page=100&page=${pageNo}`
    } else {
      params = `&per_page=100&page=${pageNo}`
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_CE_WP_URL}${api}${params}`);
      let jsonResponse = await res.json();
      setDataInState(jsonResponse, api);
    } catch (err) {
      console.log(err);
    }
  }

  const getAllData = async (filters) => {
    //needs to go in a certain order so we have to await each one
    getData(filters, APIS.countryAPI);
    getData(filters, APIS.diseaseAPI);
    getData(filters, APIS.newsAPI);
  }


  useEffect(() => {
    if (filters.length > 1) {
      setLoading(true);
      getData(filters, APIS.newsAPI);
      setLoading(false);
    }
    setLoading(true);
    //call each api
    getAllData(filters);
    setLoading(false);

    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <Row>
        <Col>
          {!preview ?
            <>
              <BreadCrumbBar appName={'ce_news'} />
              <h2 className='page-header'>Campaign Effectiveness News</h2>
            </>
            : null}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={9}>
          {
            !loading && fullNewsList ?
              fullNewsList.length > 0 ?
                <CENewsList
                  preview={preview}
                  fullNewsList={fullNewsList} />
              : <p>No results found for specified combination of filters</p>
            :
            <ListSkeletonLoader
              preview={preview} />
          }
        </Col>
        <Col xs={12} sm={3}>
          <CEFilterBar
            onFilterChanged={filterChanged}
            countryList={countryList}
            diseaseList={diseaseList}
            curFilters={filters} />
        </Col>
      </Row>
    </>
  )
}

export default WPEngineList