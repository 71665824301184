import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap'

import Select from 'react-select';
import CONSTANTS from "../../../constants"
import utils from "../../../utils"
import ObjectSelector from '../ObjectSelector';

function ProductionLocationEditor({apiKey, countries, production_location, setProductionLocation,
     deleteProductionLocation, index}) {
    const [country, setCountry] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {
        async function getProductionLocation() {
            if (production_location === null) {
                setProductionLocation(CONSTANTS.SHELL_PRODUCTION_LOCATION)
            } else {
                if (production_location.country !== null) {
                    setCountry(production_location.country)
                    if (production_location.country_id) {
                        const result = await utils.getData("get_geography_children", apiKey, "?parent_id=" + production_location.country_id)
                        if (result !== null) {
                            setCities(result)
                        } else {
                            alert("Data failed to load")
                            setCities(CONSTANTS.SHELL_CITY)
                        }
                    }
                }  
            }
        }
        getProductionLocation()
        return () => {}
    }, [country, production_location, apiKey, setProductionLocation])

    function handleCitySelect(city_name) {
        const cityobj = {
            ISO: production_location.country.ISO,
            admin_level: null,
            country: production_location.country.country,
            geography_id: null,
            geography_type: null,
            geography_name: null,
            parent_id: null
        }
        let state_city = city_name.split(' - ')

        for (const i of cities) {
            if (state_city.length > 1) {
                if (i.admin2_name === state_city[1]) {
                    cityobj.admin_level = 2
                    cityobj.geography_name = i.admin2_name
                    cityobj.geography_id = i.geography_id
                    cityobj.parent_id = i.parent_id
                    return cityobj
                }
            } else {
                if (i.admin1_name === state_city[0]) {
                    cityobj.admin_level = 1
                    cityobj.geography_name = i.admin1_name
                    cityobj.geography_id = i.geography_id
                    cityobj.parent_id = i.parent_id
                    return cityobj
                }
            }
          
        }
        alert("City not found")
        return null
    }

    function getCityLabel(geography_id) {
        for (const i of cities) {
            if (geography_id === i.geography_id) {
                return {
                    value: geography_id,
                    label: i.admin1_name + (i.admin2_name ? ' - ' + i.admin2_name : '')
                }
            }
        }
        return null
    }

    return (
        <Row key={index} className='full-width'>
            <Col>
                <div className='input-text'>Location Name</div>
                <input
                    className='form-ctrl'
                    type="text"
                    value={production_location.location_name !== null ? production_location.location_name : ''}
                    onChange={(e) => setProductionLocation(e.target.value, production_location.country_id, production_location.city_id, index)}
                />
            </Col>
            <Col>
                <div className='input-text'>Country</div>
                <ObjectSelector
                    apiKey={apiKey}
                    obj={production_location.country}
                    onChange={(e) => {
                        setCountry(e)
                        setProductionLocation(production_location.location_name, e !== null ? e.geography_id : null, null, index)
                        production_location.city = null
                        production_location.country = e
                        setCities([])
                    }}
                    isClearable={true}
                    type='geography'
                />
            </Col>
            <Col>
                <div className='input-text'>City Name</div>
                <Select
                    options={cities?.map((c) => { return {
                        value: c.geography_id,
                        label: c.admin1_name + (c.admin2_name ? ' - ' + c.admin2_name : '')
                    } })}
                    value={getCityLabel(production_location.city_id)}
                    onChange={(e) => {
                        if (e !== null) {
                            production_location.city = handleCitySelect(e.label)
                            production_location.city_id = e.value
                            setProductionLocation(production_location.location_name, production_location.country_id, production_location.city.geography_id, index)
                        } else {
                            production_location.city = null
                            production_location.city_id = null
                            setProductionLocation(production_location.location_name, production_location.country_id, null, index)
                        }
                    }}
                    isClearable={true}
                />
            </Col>
            <div className='col-right auto-width end-align'>
                <Button variant="danger"
                    size="sm"
                    onClick={() => deleteProductionLocation(index)}>-</Button>
            </div>
        </Row>
    )
}

export default ProductionLocationEditor;