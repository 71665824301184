import React, { useState, useEffect } from 'react';
import { Container, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import UserJourneyRow from './UserJourneyRow';
import utils from '../../utils';
import DynamicIcon from '../DynamicIcon';


function UserJourneyPanel({ userRecords }) {

    const today = new Date();
    const last_month = new Date(today.setDate(today.getDate() - 30));

    const [ timeSpanStart, setTimeSpanStart ] = useState(last_month.getTime()); //default to starting 30 days ago
    const [ timeSpanEnd, setTimeSpanEnd ] = useState(new Date().getTime()); //default to right now
    const [ loadingRecs, setLoadingRecs ] = useState(false);
    const [ trackingData, setTrackingData ] = useState([]);
    const [ form, setForm ] = useState({
        timeSpanStart: timeSpanStart,
        timeSpanEnd: timeSpanEnd
    });

    const [ mergedUserObj, setMergedUserObj ] = useState();
    const [ timeSlotArray, setTimeSlotArray ] = useState();

    const typeOfAccess = {
        'log-in': 'PersonFillLock',
        'app access': 'Windows',
        'data change': 'PencilFill'
    }

    const wrkspc_colors = {
        'BMGF' : 'bmgf-icon',
        'CHAI' : 'chai-icon',
        'GHMH' : 'public-access-icon',
        'CEPI' : 'cepi-icon',
        'GAVI' : 'gavi-icon',
        'META' : 'meta-icon',
        'LB' : 'lb-level-q-icon'
    }

    // const [ userSearchingFor, setUserSearchingFor ] = useState(''); //default to empty strings


    const matchMailToUserPrinc = (rawUserMail) => {
        //linksbridge and sassenach emails remain the same
        //everyone else gets converted to something like
        //mbadat.ic_clintonhealthaccess.org#EXT#@Linksbridge.onmicrosoft.com

        let retPrinc = rawUserMail;

        if( rawUserMail.length > 0 && 
            (rawUserMail.toLowerCase().indexOf('@linksbridge.com') < 1 &&
             rawUserMail.toLowerCase().indexOf('@sassenach.co') < 1)) {

            let emailPieces = rawUserMail.toLowerCase().split('@');
            // ['tim.anderson', 'linksbridge.com']

            retPrinc = emailPieces.join('_') + '#EXT#@Linksbridge.onmicrosoft.com';
        }

        return retPrinc;

    }

    const formatRecObject = (thisElasticRec) => {
        let recToReturn = {
            userPrincipalName: thisElasticRec.who?.raw,
            eventDateTime: new Date(thisElasticRec.when?.raw).getTime(),
            eventType: thisElasticRec.what?.raw,
            relevantData: thisElasticRec.where?.raw
        };

        return recToReturn;
    }

    const sortByEarliestDate = (recObj) => {
        
        Object.keys(recObj).forEach(userKey => {
            recObj[userKey].sort((a, b) => {
                let dateA = new Date(a.eventDateTime);
                let dateB = new Date(b.eventDateTime);

                return dateA - dateB;
            });
        });

        return recObj;
    }

    const getRidOfWeirdnessInMail = (mailAddr) => {
        if(mailAddr.indexOf(' (') > 0) {
            let mailAddrArr = mailAddr.split('(');
            if(mailAddrArr.length > 1) {
                let furtherSplit = mailAddrArr[mailAddrArr.length-1].split(')');
                return furtherSplit[0];
            }
        }

        return mailAddr;
    }
    
    // convert userRecords into an user-keyed object that contains events array of reverse-sorted events
    const createUserKeyedArray = (records) => {
        let recsToReturn = {};

        //build the object keyed by userPrincipalName with value being array of events
        records.forEach(rec => {
            let userKey = getRidOfWeirdnessInMail(matchMailToUserPrinc(rec.mail));
            
            if (!recsToReturn.hasOwnProperty(userKey.toLowerCase())) {
                recsToReturn[userKey.toLowerCase()] = [];
            }

            recsToReturn[userKey.toLowerCase()].push(rec);
        });

        //finally, sort the dates inside each key
        return recsToReturn;
    }

    const createTimeSlots = (startUnixStamp, endUnixStamp ) => {
        let timeSlots = [];

        const DIVISOR_NUM = 12;

        const timeSlotStart = startUnixStamp;
        const timeSlotEnd = endUnixStamp;

        const timeDiff = Math.abs(timeSlotEnd - timeSlotStart);

        const SlotDuration = Math.floor(timeDiff / DIVISOR_NUM); //since we have 12 cols, using that

        //for the last slot, we need to make sure it ends at the endUnixStamp

        let curEndTime = timeSlotStart;

        for(let i = 0; i < (DIVISOR_NUM-1); i++) {
            
            let thisTimeSlot = {
                startDateTime: curEndTime,
                endDateTime: curEndTime + SlotDuration
            };

            timeSlots.push(thisTimeSlot);

            curEndTime = thisTimeSlot.endDateTime + 1;
        }

        //add the last item manually to take up remaining space left over from rounding division
        timeSlots.push({
            startDateTime: curEndTime,
            endDateTime: timeSlotEnd 
        });

        return timeSlots;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //set timespanend and start from the form
        setTimeSpanEnd(form.timeSpanEnd);
        setTimeSpanStart(form.timeSpanStart);
        setTimeSlotArray(createTimeSlots(timeSpanStart, timeSpanEnd))
    }

    const handleDateChange = (e) => {
        let targetName = e.target.name;
        let targetValue = e.target.value;
        let frmState = {...form};

        frmState[targetName] = new Date(targetValue).getTime();

        setForm(frmState);
    }

    const getPageOfRecs = async (pageNum = 1, startDate, endDate) => {
        let resp = await fetch(`https://linksbridge.ent.us-central1.gcp.cloud.es.io/api/as/v1/engines/thoth-change-logs/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${process.env.REACT_APP_ELASTIC_PUB_KEY}`
            },
            body: JSON.stringify({
                "query": " ",
                "filters": {
                    "all": [
                        { 
                            "when": {
                                "from": new Date(startDate).toISOString(),
                                "to": new Date(endDate).toISOString()
                            }
                        },
                        {
                            "server" : "production"
                        }
                    ]
                },
                "page" : { "size": 1000, "current": pageNum},
                "sort" : { "when": "asc" }
            })
        });

        return await resp.json();

    }

    const mergeTrackingWithUserRecords = (trackingData, userRecords) => {

        let mrgedUsers = {}

        trackingData.forEach(trackingRec => {
            if(trackingRec && trackingRec.who){
                let theFixedUserKey = getRidOfWeirdnessInMail(trackingRec.who.raw.toLowerCase().trim())
                if(!mrgedUsers.hasOwnProperty(theFixedUserKey)) {
                    mrgedUsers[theFixedUserKey] = [];
                } 
                
                mrgedUsers[theFixedUserKey].push(formatRecObject(trackingRec));
            }

        });

        return sortByEarliestDate(mrgedUsers);
    }

    const getRecsFromThoth = async (startDate, endDate) => {
        setLoadingRecs(true);
        //fetch the records from the API
        let numPages = 1;

        //get the first page, look in meta.page.total_pages - if more than 1, get the rest
        let resPage = await getPageOfRecs(1, startDate, endDate);

        if(resPage.meta.page.total_pages > 1) {
            numPages = resPage.meta.page.total_pages;

            for(let i = 2; i <= numPages; i++) {
                let nextPage = await getPageOfRecs(i, startDate, endDate);
                resPage.results = resPage.results.concat(nextPage.results);
            }
        }

        setTrackingData(resPage.results);
        setMergedUserObj(mergeTrackingWithUserRecords(resPage.results,createUserKeyedArray(userRecords) ));
        setTimeSlotArray(createTimeSlots(timeSpanStart, timeSpanEnd));

        setLoadingRecs(false);
    }

    const getTimeInHoursAndMinutes = (dateStr) => {
        let myDate = new Date(dateStr);
        let retStr;

        if(!isNaN(myDate)) {
            let hrs = myDate.getHours();
            let min = myDate.getMinutes();

            if(hrs > 11) {
                retStr = 'PM';
                if(hrs > 12) {
                    hrs = hrs-12;
                }
                

            } else {
                retStr = 'AM';
            }

            retStr = (myDate.getMonth()+1) + '/' + myDate.getDate() + '/' + myDate.getFullYear() + '<br/>' + hrs + ':' + min +' ' + retStr;

            return retStr;
        }

        return ''
    }

    useEffect(() => {
        getRecsFromThoth(timeSpanStart, timeSpanEnd)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSpanStart, timeSpanEnd]);

    return (
        <Container className='user-journey-panel'>
            <>
                <h4>User Journey Panel</h4>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col><strong>Legend:</strong></Col>
                        <Col><DynamicIcon iconName={typeOfAccess['log-in']} /> Log In</Col>
                        <Col><DynamicIcon iconName={typeOfAccess['app access']} /> App Access</Col>
                        <Col><DynamicIcon iconName={typeOfAccess['data change']} /> Data Change</Col>
                        {
                            Object.keys(wrkspc_colors).map((key) => {
                                return (<Col><DynamicIcon iconName={'BookmarkFill'} className={wrkspc_colors[key]}/> {key}</Col>)
                            })
                        }
                    </Row>
                    <Row 
                        style={{marginBottom: '40px'}}
                        className='align-items-center' >
                        
                        <Col xs={5}>
                            <Form.Label>Tracking Start Date</Form.Label>
                            <Form.Control 
                                name="timeSpanStart"
                                type="date"
                                defaultValue={utils.formatDateForPicker(timeSpanStart)} 
                                onChange={(e) => handleDateChange(e)}/>
                        </Col>
                        <Col xs={5}>
                            <Form.Label>Tracking End Date (defaults to now)</Form.Label>
                            <Form.Control 
                                name="timeSpanEnd"
                                type="date"
                                defaultValue={utils.formatDateForPicker(timeSpanEnd)} 
                                onChange={(e) => handleDateChange(e)}/>
                        </Col>
                        <Col xs={2}>
                            <Button style={{width: '100%'}} type="submit">Apply</Button>
                        </Col>
                    </Row>
                </Form>
                {
                    loadingRecs === false && trackingData && trackingData.length > 0 && mergedUserObj && timeSlotArray ?
                        
                        <>
                        
                            <Row style={{borderBottom: '1px solid black'}}>
                                <Col style={{borderRight: '1px solid gray'}}>
                                    User
                                </Col>
                                {
                                    timeSlotArray.map( thisTimeSlot => {
                                        return (
                                            <Col style={{textAlign: 'right', paddingRight: '0'}}>
                                                <span dangerouslySetInnerHTML={{__html: getTimeInHoursAndMinutes(thisTimeSlot.endDateTime)}}></span>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                            { 
                                Object.keys(mergedUserObj).sort().map(userKey => {
                                    return (
                                        <UserJourneyRow 
                                            key={userKey} 
                                            userRecords={mergedUserObj[userKey]} 
                                            timeSlotArray={timeSlotArray} />
                                    )
                                })
                            }

                        </>
                    :
                        <Spinner animation="border" role="status" />
                }
            </>
        </Container>
        
    )
}

export default UserJourneyPanel