import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';


function CheckBoxDropDownSelector({options, displayOptionHandler, labelString, className=null, name=null, keepOpen=false, disabled}) {

  //options is expected to be an obj - {"name of label1": true \ false} - note that you'll have to use "" if you want spaces in your option name

  const [ show, setShow ] = useState(false);
  const optionsList = Object.keys(options);  

  const closeMenuHandler = (e) => {
    setShow(!show)
    displayOptionHandler(e)
  }

  return (
    <>
      <Dropdown  className={className ? `${className} standard-dropdown m-b-end-04 dropdown-text-left` : "standard-dropdown"} align="end" show={disabled === true ? false : show}>
        <Dropdown.Toggle
          variant="light"
          align="end"
          className="dropdown-text-left"
          id="multiSelectDropdown"
          onClick={(e) => setShow(!show)}
        >
          {labelString}
          { show && !disabled ? <ChevronUp /> : <ChevronDown /> }
        </Dropdown.Toggle>

        <Dropdown.Menu show={show} align="end" className="dropdown-menu" id="dropdown-menu">
          {optionsList.map((option) => (
            <div
              key={option}
              aria-labelledby="multiSelectDropdown"
              className="dropdown-item"
              >
              <input
                type="checkbox"
                className="checkbox"
                name={name || 'dropdown-checks'}
                id={option}
                value={option}
                options={options}
                checked={options[option] === true}
                onClick={(event) => {
                  keepOpen === true ? displayOptionHandler(event) : closeMenuHandler(event)
                }}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
    
}

export default CheckBoxDropDownSelector

