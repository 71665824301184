import React, {useState, useEffect} from 'react';
import { Button, Row, Col, Container, Spinner } from 'react-bootstrap'
import Select from 'react-select';

import AIContent from './AIContent';
import utils from '../../../utils';
import '../../../styles/layout/_layout.scss';

const LLM_SERVICE_URL = process.env.REACT_APP_LLM_SERVICE_URL
const LLM_SERVICE_API_KEY = process.env.REACT_APP_LLM_SERVICE_API_KEY


const headers = {
  'Content-Type': 'application/json',
  'access_token': LLM_SERVICE_API_KEY
}

const postRequestOptions = {
  method: 'POST',
  headers:headers
}

const getRequestOptions = {
  method: 'GET',
  headers:headers
}

function OpenAIService({apiKey, userName, assistantName}) {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [turns, setTurns] = useState([]);
  const [threadId, setThreadId] = useState();
  const [rating, setRating] = useState('');
  const [notes,setNotes] = useState('');


  async function createNewThread(){
    const threadId = await fetch(`${LLM_SERVICE_URL}create_thread`, getRequestOptions)
    let threadIdJson = await threadId.json()
    setThreadId(threadIdJson);
  }


  useEffect(()=>{
    createNewThread();
  },[]);
  
  async function insertLog(){
    const log = {
      thread_id:threadId,
      user:userName,
      rating:rating,
      notes:notes
    }
    const result = await utils.upsert('insert_ai_log', apiKey, log);
    if(!result){
      window.alert('Failed to insert log!');
    } else{
      createNewThread();
      setTurns([]);
      setQuery('');
      setRating('');
      setNotes('');
    }
  }

  async function getLLMResponse() {
    setLoading(true);
    // Create a run. This is basically sending a new message to the ai assistant.
    let runId = await fetch(`${LLM_SERVICE_URL}send_message?message=${query}&thread_id=${threadId}&assistant_name=${assistantName}`, postRequestOptions)
    let runIdJson = await runId.json();

    // Now that the query is used in the API call, reset it
    setQuery('');

    // Check in on the status of the run
    let runStatus = await fetch(`${LLM_SERVICE_URL}get_run_status?run_id=${runIdJson}&thread_id=${threadId}`, getRequestOptions);
    let runStatusJson = await runStatus.json();

    // Now check in again every second until the run is finished
    while (runStatusJson === "in_progress" || runStatusJson === "queued") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      runStatus = await fetch(`${LLM_SERVICE_URL}get_run_status?run_id=${runIdJson}&thread_id=${threadId}`, getRequestOptions);
      runStatusJson = await runStatus.json();
    }

    // The run is done now, so get back all messages from the thread.
    const messageList = await fetch(`${LLM_SERVICE_URL}get_formatted_messages?thread_id=${threadId}`, getRequestOptions);
    const messageListJson = await messageList.json();
    setTurns(messageListJson);
    setLoading(false);    
  }

  function handleQueryChange(e){
    // Let the user type a query, but ignore the enter character if typed.
    let cleanQuery = e.target.value.replace(/(\r\n|\n|\r)/gm, "");
    setQuery(cleanQuery);
  }


  async function handleEnterPress(e){
    // Handle the user pressing enter by getting the LLM Response
    let code = e.keyCode || e.which;
    if (code === 13){
      await getLLMResponse();
    }
  }

  return (
        <Container>
          <Row>
            <Col>
              <div className='input-text'>
                Introducing Amadya (<strong>A</strong>I-powered <strong>Ma</strong>rket <strong>Dy</strong>namics <strong>A</strong>ssistant.),
                your dedicated digital companion navigating the intricate landscape of vaccine market dynamics. Amadya stands at the forefront of
                cutting-edge technology, equipped with a specialized focus on public roadmaps within the realm of vaccine development and distribution.
                With a wealth of knowledge meticulously curated to provide insights into market trends and emerging innovations, Amadya serves as your
                indispensable guide through the complex terrain of the vaccine industry. Whether you seek clarity on the latest advancements, analysis
                of market dynamics, or strategic foresight into future trends, Amadya is here to empower you with actionable intelligence and
                unparalleled expertise. Welcome to a new era of informed decision-making, guided by the precision and proficiency of Amadya.
                <br />
                <br />
                Feel free to ask me any question about the public roadmaps!
              </div>
            </Col>
          </Row>
          {
            turns.map((t, i) => {
              return <div>
                <AIContent turn={t} index={i} />
              </div>
            })
          }
          <Row className='extra-padded-row'>
            <Col>
              <textarea
                className='textarea'
                value={query}
                onChange={handleQueryChange}
                onKeyDown={handleEnterPress}
              ></textarea>
            </Col>
            <Col>
              <Button onClick={getLLMResponse}>Ask!</Button>
            </Col>
          </Row>

        
      <Row className='extra-padded-row'>
        <Col>
          {loading && <span className='loading-message'><Spinner animation="border" />Amadya is busy researching...</span>

          }
        </Col>
      </Row>

      {
        // This section is for capturing user feedback. First check if there are any turns, if so ask how the conversation was.
        (turns.length > 1) &&
        <>
          <Row>
            <Col>
              <div className='input-text'>Please rate this response:</div>
              <Select
                options={[{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]}
                value={{ label: rating, value: rating }}
                onChange={(e) => setRating(e.value)}
              />
            </Col>
            <Col>
              <div className='input-text'>Do you have anything to share about this response?</div>
              <textarea
                className='textarea'
                value={notes}
                onChange={(e) => { setNotes(e.target.value) }}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={insertLog}
                disabled={!rating}
              >Submit Feedback</Button>
            </Col>
          </Row>
        </>
      }
      </Container>
  )
}

export default OpenAIService;