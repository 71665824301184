import React from 'react';
import { Nav, Row, Col, Button, Spinner } from 'react-bootstrap';
import utils from '../../../utils';
import { useLocation } from 'react-router-dom';
import DynamicIcon from '../../DynamicIcon';
import { LinkContainer } from 'react-router-bootstrap';
import WorkspaceNavDropdown from './WorkspaceNavDropdown';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { Gear } from 'react-bootstrap-icons';

function TenantAppAccordian({ tenant, tenantSlug = '', tenantApps, sa = false, show, raiseSupport, showNavHandler, screenSize, onLinkClick, showFakes=false }) {

  let { pathname } = useLocation();
  
  const { accounts } = useMsal();

  const getRouteName = (name) => {
    if (name === '/' || name === '/news' || name === '/intelligence') {
      onLinkClick(name)
    }
  }

  const appIsActive = (pth, apptype) => {
    //first blow pth apart
    let pthArray = pth.split('/');

    //the app should be only in the first or second item
    if(pthArray.length > 0) {
      return pthArray[0].toLowerCase() === apptype.trim().toLowerCase() || pthArray[1].toLowerCase() === apptype.trim().toLowerCase();
    }

    return false;
    
  }

  return (
    tenantApps ?
      //for lg and > viewports
      screenSize === 'large' ?
        <>
          <WorkspaceNavDropdown
            currentTenant={tenant}
            show={show}
          />
          <Nav className="flex-column">
            {
              show === true ?
                <Button className="sidebar-expand" onClick={showNavHandler}>Minimize Sidebar<DynamicIcon className='expand-icon' iconName={'ChevronLeft'} /></Button>
                :
                <Button onClick={showNavHandler}><DynamicIcon className='expand-icon' iconName={'ChevronBarRight'} /></Button>

            }
            <Row>
              <Row className='app-link-list'>
                <LinkContainer
                  to={'/'}
                  className={pathname === '/' ? 'active-tenant home-link sidenav-link' : 'home-link sidenav-link'}
                  onClick={(getRouteName(pathname))}
                >
                  <Row className={show && show === true ? 'showName' : 'hideName'}>
                    <Col md={2}>
                      <DynamicIcon className='app-icon' iconName={'House'} />
                    </Col>
                    <Col md={10} className='hideAppName'>
                      Home
                    </Col>
                  </Row>
                </LinkContainer>
                {
                  tenantApps.map((tenant, index) => {
                    return (
                      <>
                        <LinkContainer
                          key={index}
                          onClick={getRouteName(pathname)}
                          to={`/${tenant.fields.customctalink ? tenant.fields.customctalink : tenant.fields.apptype}`}
                          className={appIsActive(pathname, tenant.fields.apptype) ? "active-tenant sidenav-link" : 'sidenav-link'}
                        >
                          <Row>
                            <Col md={2}>
                              {
                                tenant.fields.iconname ?
                                  <DynamicIcon className='app-icon' iconName={tenant.fields.iconname} />
                                  : ''
                              }
                            </Col>
                            <Col md={10} className='hideAppName'>
                              {tenant.fields.appAlternateTitle ? tenant.fields.appAlternateTitle : utils.getAppName(tenant.fields.apptype)}
                            </Col>
                          </Row>
                        </LinkContainer>
                      </>
                    )
                  })
                }
                {
                  sa && sa === true && tenantSlug.toLowerCase() === 'lb-level-q' ?
                    <LinkContainer
                      key={'sa-app'}
                      to={`/super_admin`}
                      className={pathname.includes('super_admin') ? "active-tenant sidenav-link" : 'sidenav-link'}
                    >
                      <Row>
                        <Col md={2}>
                          {
                            <DynamicIcon className='app-icon' iconName={'Star'} />
                          }
                        </Col>
                        <Col md={10} className='hideAppName'>
                          Super Admin
                        </Col>
                      </Row>
                    </LinkContainer>
                    : null
                }
              </Row>

              <div className='support-request p-b-start-03' onClick={() => { raiseSupport(true) }}>
                <Row className='support-row'>
                  <Col xs={4} sm={3} md={2}>
                    <DynamicIcon className='app-icon' iconName='ChatQuote' />
                  </Col>
                  <Col xs={8} sm={9} md={10} className='hideAppName'>
                    Feedback
                  </Col>
                </Row>
              </div>
              {
                //right now, only settings are for user manager, only show for them
                accounts && accounts[0] && accounts[0].username && tenant ?
                  
                  <Nav.Link  className='support-request setting-link'  as={Link} to={'/settings'}  onClick={() => document.body.click()}>
                    <Row className='support-row'>
                      <Col xs={4} sm={3} md={2}>
                        <Gear className='app-icon' />
                      </Col>
                      <Col xs={8} sm={9} md={10} className='hideAppName'>
                        Settings
                      </Col>
                    </Row>
                  </Nav.Link>
                  
                :null
              }
            </Row>
          </Nav>
        </>
        :
        // For med, sm, xs viewports
        <Nav className="flex-column">
          <Row>
            <Col xs={2} sm={4} md={4}></Col>
            <Col xs={8} sm={5} md={5} className='nav-content'>
              <Row className='app-list'>
                <Row className='app-link-list'>
                  <LinkContainer
                    to={'/'}
                    className={pathname === '/' ? 'active-tenant home-link sidenav-link' : 'home-link sidenav-link'}
                  >
                    <Row className={show && show === true ? 'showName' : 'hideName'}>
                      <Col xs={4} sm={3} md={2} className='home-icon-col'>
                        <DynamicIcon className='app-icon' iconName={'House'} />
                      </Col>
                      <Col xs={8} sm={9} md={10} className='hideAppName'>
                        Home
                      </Col>
                    </Row>
                  </LinkContainer>
                  {
                    tenantApps.map((tenant, index) => {
                      return (
                        <LinkContainer
                          key={index}
                          to={`/${tenant.fields.customctalink ? tenant.fields.customctalink : tenant.fields.apptype}`}
                          className={pathname.includes(tenant.fields.apptype.toLowerCase()) ? "active-tenant sidenav-link" : 'sidenav-link'}
                        >
                          <Row>
                            <Col xs={4} sm={3} md={2} className='app-icon-col'>
                              {
                                tenant.fields.iconname ?
                                  <DynamicIcon className='app-icon' iconName={tenant.fields.iconname} />
                                  : ''
                              }
                            </Col>
                            <Col xs={8} sm={9} md={10} className='hideAppName'>
                            {tenant.fields.appAlternateTitle ? tenant.fields.appAlternateTitle : utils.getAppName(tenant.fields.apptype)}
                            </Col>
                          </Row>
                        </LinkContainer>
                      )
                    })
                  }
                </Row>

                <div className='support-request' onClick={() => { raiseSupport(true) }}>
                  <Row className='support-link'>
                    <Col xs={4} sm={3} md={2} className='support-icon-col'>
                      <DynamicIcon className='app-icon' iconName='ChatQuote' />
                    </Col>
                    <Col xs={8} sm={9} md={10} className='hideAppName'>
                      Feedback
                    </Col>
                  </Row>
                </div>
                {
                  //right now, only settings are for user manager, only show for them
                  accounts && accounts[0] && accounts[0].username && tenant ?
                    utils.isAUserManager(tenant.tenant, accounts[0]) ?
                    <Nav.Link  className='support-request'  as={Link} to={'/settings'}  onClick={() => document.body.click()}>
                      <Row className='support-link'>
                        <Col xs={4} sm={3} md={2} className='support-icon-col'>
                          <Gear className='app-icon' />
                        </Col>
                        <Col xs={8} sm={9} md={10} className='hideAppName'>
                          Settings
                        </Col>
                      </Row>
                    </Nav.Link>
                    : null
                  :null
                }
              </Row>
              {
                show === true ?
                  <div className='close-menu-row'>
                    <DynamicIcon className='close-menu' iconName={'X'} />
                    <Button onClick={showNavHandler}>Close Menu</Button>
                  </div>
                  :
                  <Row className='close-menu-row'>
                    <Col xs={2} sm={4} className='list-icon'>
                      <DynamicIcon className='close-menu' iconName={'List'} />
                    </Col>
                    <Col xs={10} sm={8} className='menu'>
                      <Button onClick={showNavHandler}>Menu</Button>
                    </Col>
                  </Row>
                }
            </Col>
            <Col xs={2} sm={4} md={3}></Col>
          </Row>
        </Nav>
      :
      <Spinner animation='border' />
  )

}

export default TenantAppAccordian;