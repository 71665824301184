import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import MarketActionsDisplay from '../../components/MarketActions/MarketActionsDisplay';
import BreadCrumbBar from '../../components/Layout/Nav/BreadCrumbBar';
import utils from '../../../src/utils';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';

import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';

function MarketActionsMarketPage({ tenant, filteredMarkets }) {
  const history = useHistory();
  let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.MARKET_ACTIONS);

  if(!hasAccess) {
      history.push('/');
  }

  let { marketID } = useParams();
  let marketIDs = marketID.split(',');
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  let [marketList, setMarketList] = useState();
  let [groupMembers, setGroupMembers ] = useState([]);
  let [loading, setLoading] = useState();
  let [organization, setOrganization] =useState();
  let [vaccine, setVaccine] = useState();
  let [labelString, setLabelString] = useState('-None-')
  let [shouldLoadList, setShouldLoadList] = useState(true);
  let [showArchived, setShowArchived] =  useState(false);
  let [filterDateRange, setFilterDateRange] = useState(false)
  let [options, setOptions] = useState({
    'Show Archived': false,
    'Due in the next 6 months': false,
    'Due in the next year': false,
  });

  let [forceThisView, setForceThisView] = useState(null);
  

  function getDropdownOption(temp) {
    const optionsArray = Object.keys(temp); 
    let returnString = "";
        let filteredArray = optionsArray.filter( (option) => {
         return temp[option] === true
        })
        if (filteredArray && filteredArray.length > 0) { 
          for (let i = 0; i < filteredArray.length; i++) { 
          if (i > 0) { 
            returnString = returnString + ", "  + filteredArray[i].slice(0, 3) + "..."; 
          } else {
            returnString = filteredArray[i];
          }
        } 
      } else {
        returnString = '-None-'
      }
    setLabelString(returnString);
  }
  
  function displayOptionHandler(event) {
    let temp = { ...options }
    temp[event.target.id] = event.target.checked
    setOptions(temp)
    getDropdownOption(temp)
    if (event.target.id === 'Show Archived') {
      setShowArchived(!showArchived)
      setShouldLoadList(true);
    } 
    if (event.target.id === 'Due in the next 6 months' && event.target.checked === false){
      setShouldLoadList(true);
    } 
    if (event.target.id === 'Due in the next year' && event.target.checked === false){
      setShouldLoadList(true);
    } else {
      checkForFilters(marketList, temp)
    }
  }





  const request = {
    scopes: ["User.Read"]
  };

  const groupID = tenant.tenant.fields.aDGroupID;

  const getAccessToken = async (instance, account) => {
    try {
      let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
      return theAccToken;
    } catch (error) {
      console.log(error);
    }
  }

  const getMembersOfGroup = async (grpID, accToken) => {
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-group-members?gid=${grpID}`, {
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });

    let jsonresponse = await response.json();
    setGroupMembers(jsonresponse);
  }

  const getMarketActionsSpace = (tenant) => {
    let ma_space_id;

    let actionsApp = tenant.fields.apps.filter((item) => {
      return item.fields.apptype === 'market_actions';
    });

    if (actionsApp.length > 0) {
      ma_space_id = actionsApp[0].fields.appSpecificDataField ? actionsApp[0].fields.appSpecificDataField : null;
    }

    return ma_space_id;
  }

  const getInterventionByID = (id, mktList) => {

      for( let x = 0; x < mktList.length; x++){
        for(let i=0; mktList[x].interventions.length; i++){
          if(mktList[x].interventions[i].id === id) {
            return {marketIdx: x, intIndex: i}
          }
        }
      }

      return {};
  }


  const updateInterventionInState = (interventionToUpdate) => {
    let intObj = getInterventionByID(interventionToUpdate.id, marketList);
    if (intObj.hasOwnProperty('marketIdx')){
      let tempMarketList = [...marketList];
      tempMarketList[intObj.marketIdx].interventions[intObj.intIndex] = interventionToUpdate;
      setMarketList(tempMarketList);
    }

  }

  const updateInterventionForMarket = (interventionInfo) => { 
    let curAccessToken;
    //put the updated field to said intervention

    let theBody = {};
    theBody.taskId = interventionInfo.id;
    theBody[interventionInfo.field] = interventionInfo.value;

    let param = `uid=${tenant.ourUserID}&on=int&id=${interventionInfo.id}&spc_id=${getMarketActionsSpace(tenant.tenant)}`;
    
    //first, get access token
    getAccessToken(instance, accounts[0])
    .then( (res) => { curAccessToken = res})
    .then(() => {
      //call the put endpoint with the updated intervention field
      fetch(`${process.env.REACT_APP_WEB_API}/api/market-actions?${param}`, {
        method: 'PUT',
        headers: {
          "Authorization": "Bearer " + curAccessToken.accessToken
        },
        body: JSON.stringify(theBody)
      })
      .then( (resJSON) => {
          if(resJSON.ok){
            return resJSON.json();
          } else {
            throw Error('Server error', resJSON.statusText);
          }  
      })
      .then( resp => {
          updateInterventionInState(resp);
      })
      .catch( (err) => {
        console.log('error: ', err);
        //we need to force a refresh of the table
        setMarketList(marketList);
      })
    });

  }

// total market list
  const getMarketList = async (accToken, marketID, archived) => {

      let param = `uid=${tenant.ourUserID}&on=mkt&id=${marketID}&spc_id=${getMarketActionsSpace(tenant.tenant)}&archived=${archived}`;
      let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/market-actions?${param}`, {
        headers: {
          "Authorization": "Bearer " + accToken.accessToken
        }
      });

      let jsonresponse = await response.json();
      return jsonresponse;
  }

  // one or multiple checked markets
  const getAllMarkets= async (accToken, marketIDArray, archived) => {
    let tempMktArr = [];
    for(let i=0; i < marketIDArray.length; i++ ){
      tempMktArr.push(await getMarketList(accToken, marketIDArray[i], archived));
  }
  checkForFilters(tempMktArr, options)
  }


  const checkForFilters = (tempMktArr, opts) => {
    let filteredMarkets;
    if (opts['Due in the next 6 months'] === true) {
      filteredMarkets = filterInDateRange(6, tempMktArr)
      setFilterDateRange(!filterDateRange)
    }
    if (opts['Due in the next year'] === true) {
      filteredMarkets = filterInDateRange(12, tempMktArr)
      setFilterDateRange(!filterDateRange)
    } 
    if (!filteredMarkets) {
      filteredMarkets = tempMktArr
    }
    setMarketList(filteredMarkets);
  }


  const filterInDateRange = (months, allMarketsList) => {
    let temp = allMarketsList 
    let startDate = new Date()
    let startDateWithTimeStamp = startDate.getTime()
    let endDate = new Date().setMonth(startDate.getMonth() + months)

    temp.map(( market ) => {
      let marketFilter = market.interventions.filter( (intervention) => { 
          return intervention.due_date && intervention.due_date >= startDateWithTimeStamp && intervention.due_date <= endDate
            })
            market.interventions = marketFilter && marketFilter.length > 0 ? marketFilter : [];
            return marketFilter
    })
    setMarketList(temp)
    setFilterDateRange(true)
  }
    
  const handleReload = (archived=null, setView=1) => {
    setShowArchived(false)
    setLabelString('-None-');
    setOptions({
      'Show Archived': false,
      'Due in the next 6 months': false,
      'Due in the next year': false,
    });
      setShouldLoadList(true);
      setForceThisView(setView);
  }

  useEffect(() => {
    setLoading(true);
    if (isAuthenticated) {
      try {
        (async () => {
          let accToken = await getAccessToken(instance, accounts[0]);
          if (shouldLoadList === true) {
            await getAllMarkets(accToken, marketIDs, showArchived);
            setShouldLoadList(false)
          } 
          let response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL, {

            headers: {
              "Authorization": "Bearer " + accToken.accessToken
            }
          }); 

          const responsejson = await response.json(); 

          // The API key is housed in the responsejson.value 
          const apiKey = responsejson.value; 

          //get organization data to pass to table selects and set in state
          const getOrg = await utils.getData('get_organization_names', apiKey)
          setOrganization(getOrg);

          //get vaccine data to pass to table selects and set in state
          const getVaccine = await utils.getData('get_vaccine_names', apiKey)
          setVaccine(getVaccine)

          
          await getMembersOfGroup(groupID, accToken);
          
          setLoading(false);
        }
        )()
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    //eslint-disable-next-line
  }, [isAuthenticated, showArchived, shouldLoadList, filterDateRange])

  return (
    !loading && marketList && groupMembers.length > 0 ?
      <div className='market-page-display'>
        <BreadCrumbBar
          appName={utils.findURLPath(tenant.tenant.fields.apps, "market_actions")}
        />

        <MarketActionsDisplay 
          marketList={marketList} 
          marketID={marketIDs} 
          spaceID={getMarketActionsSpace(tenant.tenant)} 
          handleMarketUpdate={updateInterventionForMarket} 
          memberList={groupMembers} 
          marketIDs={marketIDs}
          organization={organization}
          vaccine={vaccine}
          tenant={tenant}
          options={options}
          displayOptionHandler={displayOptionHandler}
          labelString={labelString}
          handleReload={handleReload}
          toggleLoading={() => setLoading(true)}
          forceView={forceThisView ? forceThisView : null} 
          />
      </div>
      : <ListSkeletonLoader preview={false} />
  )
}

export default MarketActionsMarketPage