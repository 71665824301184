import React from 'react'

function NavPageTitle({ navArray, infoID, overrideTitle = null }) {
    //this component filters the navArray to the item specified by infoID and display's its linkLabel as title

    const filterToItem = (navArray, infoID) => {

        // [{
        // "groupingTitle": "IRC Report",
            // "subNav": [
            //     {
            //         "urlForField": "data_overview",
            //         "linkLabel: "Data Overview"
            //     }
            // ]
        // }]

        //go through the first array, then go through the subNav array, return if found

        for(let i = 0; i < navArray.length; i++) {
            for(let j = 0; j < navArray[i].subNav.length; j++) {
                if(navArray[i].subNav[j].urlForField.toLowerCase() === infoID.toLowerCase()) {
                    return navArray[i].subNav[j].linkLabel;
                }
            }
        }

        return '';
    }

    return (
        <div>
            {
                overrideTitle ? 
                    <h1>{ overrideTitle }</h1>
                :
                    <h1>{ filterToItem(navArray, infoID) }</h1>
            }
        </div>
    )
}

export default NavPageTitle