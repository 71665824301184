import React, { useState, useEffect} from 'react'; 

import _ from "lodash" // For making deep copies of objects
import { Table, FormControl, Button, Modal, ButtonGroup, ButtonToolbar} from 'react-bootstrap'

import utils from "../../../utils"
import CONSTANTS from "../../../constants"
import '../../../styles/layout/_layout.scss'
import SimpleSelect from '../SimpleSelect'
import EnumSelector from '../EnumSelector'

function EnumDeleter(props) {
  /*--------------------------State variables---------------------------*/
  const [lbWord, setLbWord] = useState(props.enum === null?
    _.cloneDeep(CONSTANTS.SHELL_ENUM): 
    props.enum)
  const [replacement, setReplacement] = useState({value:{enum_id:null}})
  const partners = ['WHO', 'GAVI', 'BMGF', 'CHAI', 'UNICEF']
  const [errors, setErrors] = useState([])
  const [combinedSynonyms, setCombinedSynonyms] = useState([])

  /*-------------------------Submit form and new antigen----------------------------------*/
  async function handleSubmit (e){
    e.preventDefault();
    e.stopPropagation();
    let foundPartners = new Set()
    var submissionErrors = []
    for (let i = 0; i<combinedSynonyms.length; i++){
      if (foundPartners.has(combinedSynonyms[i].partner)){
        submissionErrors.push(combinedSynonyms[i].partner + " has two or more alternatives")
      }
      if (combinedSynonyms[i].partner !== null){
        foundPartners.add(combinedSynonyms[i].partner)
      }
    }
    setErrors(submissionErrors)
    if (submissionErrors.length > 0)  {
      return
    }

    var deleted_word = lbWord
    deleted_word.deleted = true
   
    if (replacement.value.enum_id !== null){
      deleted_word.replacement = replacement.value
      deleted_word.alt_enums = []
    }
    
    deleted_word.replacement_id = replacement.value.enum_id
    var result
    result = await utils.upsert('update_enum', props.apiKey, deleted_word)
    if (replacement.value.enum_id === null || result === null) {
      props.closePopup()
      return
    }
    var replacement_word = replacement.value
    replacement_word.alt_enums = combinedSynonyms
    result = await utils.upsert('update_enum', props.apiKey, replacement_word)
    if (result !== null) {
      props.closePopup()
    }
  }

  useEffect(() =>{
    function combineSynonyms(){
      if (replacement.value.enum_id === null){
        return
      }
      var tempSynonyms = [...lbWord.alt_enums]
      tempSynonyms.push({alt_enum: lbWord.enum, partner: null})
      
      tempSynonyms = tempSynonyms.concat([...replacement.value.alt_enums])
      setCombinedSynonyms(tempSynonyms)
    }
    combineSynonyms()
  }, [replacement, lbWord])


  /*--------------------------------Manage state variables-----------------------*/
  function addSynonym() {
    const tempAltEnums = [...combinedSynonyms]
    tempAltEnums.push({alt_enum: '', partner: null})
    setCombinedSynonyms(tempAltEnums)
  }

  function setSynonym(synonym, index){
    const tempAltEnums = [...combinedSynonyms]
    tempAltEnums[index].alt_enum = synonym
    setCombinedSynonyms(tempAltEnums)
  }

  function setPartner(partner, index){
    const tempAltEnums = [...combinedSynonyms]
    tempAltEnums[index].partner = partner
    setCombinedSynonyms(tempAltEnums)
  }

  function deleteSynonym(index) {
    const tempAltEnums = [...combinedSynonyms]
    tempAltEnums.splice(index, 1)
    setCombinedSynonyms( tempAltEnums)
  }

  function printLbWord(){
    console.log("lb word", lbWord)
    console.log("replacement", replacement)
    console.log("Combined synonyms", combinedSynonyms)
  }
  
  return (
    <Modal show = {true} onHide={props.closePopup}>
      <Modal.Header>
        <Modal.Title>LB Word Deleter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="error"><strong>This word will be marked deleted:</strong></div>
          <div className='input-text'>Word</div>
          <input
            className='form-ctrl'
            type='text'
            required
            disabled
            value = {lbWord.enum}
            onChange={(e)=> (e) = setLbWord({...lbWord, enum: e.target.value})}
          />
          <div className='input-text'>Replace With:</div>
          <EnumSelector isClearable = {true} 
            apiKey={props.apiKey}
            enum_type={lbWord.enum_type} 
            onChange = {(e) => setReplacement(e)}/>
          {
            replacement.value !== null?
            <>
              <div><strong>{replacement.label}</strong> will now have the following synonyms:</div>
              <Table>
                <thead>
                  <tr>
                    <td>Synonym</td>
                    <td>Organization</td>
                    <td>
                      <Button size="sm" 
                        onClick = {addSynonym}>
                          +
                        </Button>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {combinedSynonyms.map((ae, i) => {
                  return <tr key = {i}>
                    <td>
                      <FormControl value = {ae.alt_enum}
                        onChange = {(e) => setSynonym(e.target.value, i)}
                      ></FormControl>
                    </td>
                    <td>
                      <SimpleSelect
                        className = "select"
                        isClearable={true}
                        options = {partners}
                        onChange = {(partner) => setPartner(partner, i)}
                        value = {ae.partner}
                      />
                    </td>
                    <td>
                      <Button variant="danger" size = "sm"
                        onClick = {()=>deleteSynonym(i)}>-</Button>
                    </td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </>
            : null
          }
          
          {errors.length > 0 ?
            <div>
              <div className="error">Errors:</div>
              {
                errors.map((e) => <li className = "error">{e}</li>)
              }
            </div>
          : null}
            <ButtonToolbar className='editor-btn'>
              <ButtonGroup>
                <Button onClick = { props.closePopup } variant = "secondary">Close</Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick = {handleSubmit} variant = "primary">Submit</Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick = { printLbWord } variant = "primary">Check Word</Button>
              </ButtonGroup>
            </ButtonToolbar>
      </Modal.Body>
    </Modal>

  )
}

export default EnumDeleter;