import React from 'react';
import CompanyProfileApp from '../../components/CompanyProfiles/CompanyProfileApp';
import '../../styles/components/_coProApp.scss';
import { useHistory } from 'react-router';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';

function CoProfilesAppPage({ tenant, token }) {

    const history = useHistory();
    let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES);

    if(!hasAccess) {
        history.push('/');
    }

    return (
        token ?
            <div className="full-co-prof-app">
                <CompanyProfileApp tenantSetting={ tenant } preview={ false } />
            </div>
        : null
    )
}

export default CoProfilesAppPage
