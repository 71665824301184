import React, { useState } from 'react';

function ReactTblSelectEditCell({ initialValue, row, column, updateMyData, selectionValues, forName, customField=null }) {

    const [ myValue, setMyValue ] = useState(initialValue);
    // const [ setMarkedForChange ] = useState(false);

    const onChange = value => {
        
        setMyValue(value);
        
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        if(forName === 'hmfCategory'){
            updateMyData(row, column, [myValue]);
        }else{
            updateMyData(row, column, myValue);
        }
        
    };

    const isSelected = (optionVal) => {
        return optionVal === myValue;
    }

    return (
        <>
            <select 
                className="table-dropdown full-width"
                name={`select-${forName ? forName : 'select'}-${row}-${column}`} 
 
                onChange={(e) => onChange(customField ? e.target.label : e.target.value)} 
                onBlur={() => onBlur()}
            >
                { selectionValues.map((option, index) => {
                    return (
                        <option key={index} value={option.value} selected={isSelected(option.value)}> {option.label}</option>
                    )
                })}
            </select>
        </>
    )
}

export default ReactTblSelectEditCell