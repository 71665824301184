import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { ArrowBarLeft, PlusCircle } from 'react-bootstrap-icons';
import utils from '../../utils';
import { LinkContainer } from 'react-router-bootstrap';

function PMTNoItemInList({listName, tenant, handleShowAddItemModal, dynamicItemName, dynamicAppName, appUrl}) {
    return (
        <div>
          <h4 className='text-center m-b-start-13'>{`There are no ${dynamicItemName} in ${listName ? listName : 'List'}`}</h4>
          <p className='text-center m-b-end-11'>{`Add ${dynamicItemName} below, or go back to ${dynamicAppName} home page`}</p>
          <Row md={2} className="add-intervention-col">  
            <Button className='icon-btn  m-b-end-06' onClick={() => handleShowAddItemModal()}>
                <PlusCircle />
                Add intervention
            </Button>
          </Row>
          <Row className="add-intervention-col">
            <LinkContainer to={`/${utils.findURLPath(tenant.tenant.fields.apps, appUrl)}`}>
              <Button className='go-back-btn'>
                <ArrowBarLeft />
                Go Back
              </Button>
            </LinkContainer>
          </Row>
        </div>
      )
}

export default PMTNoItemInList