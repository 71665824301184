
import {useState, useEffect} from 'react'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import { useToast } from '../ToastProvider';
import utils from "../../utils"
import '../../styles/layout/_layout.scss'

function EnumSelector({enum_type, onChange, apiKey, disabled=false, value=null, isMulti=false, 
    clearAfterSelect=false, disableCreate=false, className=null,isClearable=false, prioritySort=false, orderType='descending'}) {

  /*--------------------------State variables---------------------------*/

  const [enumOptions, setEnumOptions] = useState('')
  const [deletedEnums, setDeletedEnums] = useState([])
  const [enumSelection, setEnumSelection] = useState(null)

  const { addToast } = useToast();

  useEffect(() => {
    if(!value){
      setEnumSelection(null);
      return
    }
    if (isMulti){
      setEnumSelection(
          value.map((enm) => {return {label: enm, value: enm}})
        )
    } else {
      setEnumSelection({label: value, value: value})
    }
    
  }, [value, isMulti])

  

  /*-------------------------Get the requested enums-------------------*/
  useEffect(() => {
    async function fetchData(){
      let enumResult = await utils.getData('get_enums', apiKey, '?enum_type='+enum_type+'&include_deleted=True')
      if (typeof enumResult === 'string'){
        addToast({
          title: 'Failed to load enum data. Error code 2-a',
          body: enumResult
        })
        return;
      }
      const tempList = enumResult.filter(
          en => en.enum_type === enum_type
          && en.deleted === false
        )
      let tempOptions = tempList.map(en => {return {label: en.enum, value:en}})
      tempOptions.sort((a, b) => utils.createStrSortOrder(a.label, b.label))
      // Apply custom sorting here
      if (prioritySort) { // enums are High, Medium, Low
        tempOptions = utils.sortPriorityLevels(tempOptions, orderType);
      } else {
      // Default alphabetical sorting
        tempOptions.sort((a, b) => utils.createStrSortOrder(a.label, b.label));
      }
      setEnumOptions(tempOptions)
      setDeletedEnums(enumResult.filter(en => en.deleted === true))
    }
    fetchData();
    },[enum_type, apiKey, addToast, prioritySort, orderType]
  )

  const insertEnumIfNew = async (enumSelection) => {
    // Inserts the enum into the database if user agrees and it passes the checks
    if (enumSelection.__isNew__){
      var deletedEnum = deletedEnums.filter(en => en.enum.toLowerCase() === enumSelection.value.toLowerCase())
      if (deletedEnum.length > 0){
        deletedEnum = deletedEnum[0]
        if (deletedEnum.replacement === null){
          alert("This enum has been deleted, please try another")
        } else {
          alert("This enum has been deleted, consider replacing with " + deletedEnum.replacement.enum)
        }
        
        return
      }
      const confirmation = window.confirm("Are you sure you want to add "+enumSelection.value+" to the database?")
      if (confirmation === false) {
        return
      }
      const data = {enum_type: enum_type, enum: enumSelection.value, deleted:false}
      const result = await utils.upsert('create_enum', apiKey, data)
      if (result) {
        enumOptions.push({label:enumSelection.label, value:enumSelection.value})
      }else{
        return
      }
    }
  }

  /*-------------------------Select the enum----------------------------------*/
  async function handleChange(e) {
    if (!e){
      setEnumSelection(null)
      onChange({value:null})
      return
    }
    
    if(isMulti){
      for (let enumSelection of e){
        insertEnumIfNew(enumSelection)
      }
    } else {
      insertEnumIfNew(e)
    }
    
    if (clearAfterSelect !== true){
      setEnumSelection(e)
    }
    onChange(e)
  }


  return (
    <div>
      {disableCreate? 
        <Select
          className = {className?className: "select-lg"}
          isClearable = {isClearable}
          options = { enumOptions }
          isDisabled = { disabled}
          onChange = {(e) => handleChange(e)}
          value = { enumSelection }
          isMulti = {isMulti}
        />:
        <CreatableSelect
          className = {className?className: "select-lg"}
          isClearable = {isClearable}
          options = { enumOptions }
          onChange = {(e) => handleChange(e)}
          value = { enumSelection }
          isDisabled={ disabled}
          isMulti = {isMulti}
      />
    }
    </div>
  )
}

export default EnumSelector;