import React, { useState } from 'react';
import { Nav, Form, InputGroup } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

function SearchComponent({ onQuery, tenantSetting, tenantName=null }) {

    let [ query, setQuery ] = useState('');

    const addKeysToQuery = (e) => {
        e.preventDefault();
        if (e.which === 32){
            e.target.value = `${e.target.value} `
        }
        if(e.keyCode === 13) {
            onQuery(query);
            setQuery('');
            e.currentTarget.value = "";
        } else {
            setQuery(e.target.value);
        }
    }

    return (
        <>
            <Nav.Link className="tenant-app-link app-search"> 
                <InputGroup className={tenantName === 'BMGF' ? 'search-margin' : ''}>
                    <InputGroup.Text><Search /></InputGroup.Text>
                    <Form.Control onKeyUp={(e) => addKeysToQuery(e)} type="text" placeholder="Search by Keyword" />
                </InputGroup>
            </Nav.Link>
        </>
    )
}

export default SearchComponent
