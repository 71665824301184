import React from 'react';
//dependency imports
import { Image } from 'react-bootstrap';
import { PieChartFill } from 'react-bootstrap-icons';

//file imports
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import marketActionsPreviewImage from '../../assets/market-actions-cover.png';
import PMTDisplay from './PMTDisplay';
import utils from '../../utils';
import CONSTANTS from '../../constants';



function PMTApp({ tenantSetting, suppliers, handleShowArchived, handleLoadList, handleReload, preview=false, apiKey, token, updateTopList, appName, archived, options, displayOptionHandler, parentPMTList, updateListItemForItemChange, loading=false, memberList }) {

    let breadCrumbName = utils.findURLPath(tenantSetting.tenant.fields.apps, "pmt_ma")

    const handleLoadArchived = (archived = null, setView = 1) => {
        handleShowArchived(true)
        handleLoadList();
    }

    return (
        <div className="market-actions-display">
            {
                !preview ?
                    <>
                        <BreadCrumbBar
                            appName={breadCrumbName}
                        />
                        <h2 className='m-b-end-08'>{CONSTANTS.APPS_BY_NAME[breadCrumbName.toUpperCase()]}</h2>

                        <h3><PieChartFill /> PowerBI Summary Chart Goes Here</h3>
                        <div style={{border: 'solid black 1px', height: '400px', background: 'grey', width: '100%'}}>Mock Summary Chart</div>
                        <PMTDisplay 
                            tenant={tenantSetting}
                            memberList={memberList}
                            suppliers={suppliers}
                            options={options}
                            displayOptionHandler={displayOptionHandler}
                            apiKey={apiKey}
                            appName={appName}
                            handleReload={handleReload}
                            updateTopList={updateTopList}
                            topList={parentPMTList}
                            loading={loading}
                            handleListUpdate={updateListItemForItemChange}
                            handleLoadArchived={handleLoadArchived}
                        />
                    </>
                    :
                    <div className='preview-img-div'>
                        <Image className='preview-card-img' src={appName && appName.fields && appName.fields.previewImage && appName.fields.previewImage.url ? appName.fields.previewImage.url : marketActionsPreviewImage} />
                    </div>
            }
        </div>
    )
}

export default PMTApp;