import React, { useState, useEffect } from 'react';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import FormattedArticleList from '../News/FormattedArticleList';
import getCountryISO2 from "country-iso-3-to-2";
import { getName } from 'country-list';
import { useToast } from '../ToastProvider';

function WPNewsWrapper({ cntryISO }) {
    //this component wraps the call to the WP API for news articles and adapts the data for the common news component

    const [newsLoading, setNewsLoading] = useState(true);
    const [newsArticles, setNewsArticles] = useState();
    const { addToast } = useToast();

    const regex = /<(?!a|\/a|p|\/p).*?>/gi;

    const stripNewsArticles = (articleArray) => {
        articleArray.forEach((thisArticle) => {
            thisArticle.content.rendered.replace(regex, '');
        });

        return articleArray;
    }

    const formatForNewsList = (rawList) => {
        //comes from WP as
        /*
        {
            "id": 2486,
            "link": "https://www.unicef.org/press-releases/vaccination-campaign-against-cholera-kicks-sudan",
            "title": {
                "rendered": "October 2019: Sudan launches five-day cholera campaign"
            },
            "excerpt": {
                "rendered": "<p>In response to an outbreak declared in September, Sudan launched a five-day cholera campaign on October 11, delivering 1.6 million doses of oral vaccine to people aged one year and above in the [&hellip;]</p>\n",
                "protected": false
            },
            "author": 5,
            "post_region_country": [
                254
            ]
        }
        */

        return rawList.map((thisArticle) => {
            return (
                {
                    fields: {
                        title: thisArticle.title.rendered,
                        body: thisArticle.content.rendered,
                        link: thisArticle.link,
                        datepublished: thisArticle.date
                    }
                })
        });

    };

    const getNewsFromWP = async (iso) => {

        let TwodigitCountryISO = getCountryISO2(iso);
        let countryName = getName(TwodigitCountryISO);

        try {
            let resp = await fetch(`${process.env.REACT_APP_WEB_API}/api/newscontentbytag?newssource=CE&tag=${countryName}`,
                {
                    headers: {
                        "x-functions-key": process.env.REACT_APP_NEWS_CONTENT_TAG
                    }
                });

            let data = await resp.json();
            setNewsArticles(stripNewsArticles(data));

        } catch (error) {
            addToast('Cannot load news data - please refresh browser.', 'An error occurred', 'danger');
        }
    };

    useEffect(() => {
        setNewsLoading(true);
        getNewsFromWP(cntryISO);
        setNewsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cntryISO]);

    return (
        newsLoading ?
            <ListSkeletonLoader preview={false} />
            :
                newsArticles && newsArticles.length > 0 ?
                    <FormattedArticleList
                        list={formatForNewsList(newsArticles)}
                        useStandardNewsLink={false}
                        showTags={false} />
                : null
    )
}

export default WPNewsWrapper