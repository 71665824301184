import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CountryMap from './CountryMap';

function CountryProfilePanel({ countryInfo, contentMaster }) {

    const formatForInfo = (cmField, countryData) => {
        let formattedData;

        //replace {{colname}} with actual data by that name
        formattedData = cmField.replace(/{{(.*?)}}/g, (match, p1) => {
            return countryData[p1] || '';
        });

        return formattedData;
    }

    return (
        <>
            <Row>
                <Col>
                    <div dangerouslySetInnerHTML={{ __html: formatForInfo(contentMaster.fields.countryProfileLayout, countryInfo) }} />
                </Col>
                <Col>
                    <CountryMap 
                        country={countryInfo} />
                </Col>
            </Row>
        </>
        
    )
}

export default CountryProfilePanel