import React, { useEffect, useState } from 'react';
import './../../styles/layout/_layout.scss';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ChevronLeft } from 'react-bootstrap-icons';
import EnumSelector from './EnumSelector';
import ToolTip from '../Layout/ToolTip';

const ConfirmationContent = ({ apiKey, onSubmit, renderContentSection, onGoBack, editorName, tooltip, getTooltipContentByKey, handleUpdate, infoSource }) => {


    const [updateNotes, setUpdateNotes] = useState();
    const [updateSource, setUpdateSource] = useState('');
    const [confirmationErrors, setConfirmationErrors] = useState([]);

    useEffect(() => {
        // Reset fields to null on first load
        setUpdateNotes(null);
        setUpdateSource(null);
    }, []);



    // Confirmation Page errors
    // Activity Editor allows for optional UpdateNotes and uses infoSource rather than UpdateSource
    const checkForConfirmationErrors = () => {
        const errors = [];
        if (editorName !== 'activity' && !updateNotes) {
            errors.push("Please enter Update Notes.");
        }
        if (editorName !== 'activity' && !updateSource) {
            errors.push("Please enter Source.");
        }
        if (editorName === 'activity' && !infoSource) {
            errors.push("Please enter Source.");
        }
        setConfirmationErrors(errors);
        return errors.length === 0;
    };

    // handleSubmit, after checking for errors
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = checkForConfirmationErrors();
        if (isValid) {
            onSubmit({ updateNotes, updateSource });
        }
    };

    return (
        <div className='confirmation-modal'>
            <Button className='btn-sm-invisible-dark p-0 start-0 mb-3' onClick={onGoBack}><ChevronLeft /> Go Back</Button>
            {renderContentSection ? renderContentSection() : null}

            <div className='input-text'>Update Notes</div>
            <textarea
                className="textarea"
                onChange={(e) => setUpdateNotes(e.target.value)}
                value={updateNotes}
            />

            {editorName !== 'activity' ? (
                <>
                    <div className='input-text'>Source</div>
                    <textarea
                        className="textarea"
                        onChange={(e) => setUpdateSource(e.target.value)}
                        value={updateSource}
                    />
                </>
            ) :
                <>
                    <div className='input-text'>Info Source * <ToolTip className='app-tooltip' tooltip={getTooltipContentByKey(tooltip, 'ce_info_source')} /></div>
                    <EnumSelector
                      className='select'
                      enum_type='info_source'
                      apiKey={apiKey}
                      disableCreate={false}
                      value={infoSource}
                      onChange={(e) => handleUpdate(e.label, 'info_source')}
                    />
                </>
            }

            {confirmationErrors.length > 0 && (
                <div className="error-messages">
                    {confirmationErrors.map((error, index) => (
                        <p key={`confirmation-error-${index}`} className="error">{error}</p>
                    ))}
                </div>
            )}
            <div className="m-b-start-03 d-flex justify-content-end">
                <ButtonGroup>
                    <Button onClick={onGoBack} variant="secondary" className='button-secondary m-i-end-03'>Cancel</Button>
                    <Button variant="primary" className='btn-primary' onClick={handleSubmit}>Submit</Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

export default ConfirmationContent;
