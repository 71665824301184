import React from 'react';
import { Route, useRouteMatch, Switch, Redirect, useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';

import AppSideNav from '../Layout/Nav/AppSideNav';
import FieldDisplay from '../FieldDisplay';
import ReactCountryFlag from 'react-country-flag';
import { Bank, BarChartLineFill, PeopleFill } from 'react-bootstrap-icons';
import RelatedArticleTeaser from '../Layout/RelatedArticleTeaser';
import CONSTANTS from '../../constants';
import countryList from 'country-list';
import utils  from '../../utils';

function CompanyDisplay({ tenant, profile, navs }) {

    const { path } = useRouteMatch();
    const {slug} = useParams();
   
    let useNavs = utils.cleanUpNavs(navs, profile);

    //make UK name shorter
    countryList.overwrite([{ code: 'GB', name: 'United Kingdom'}])

    const getExternalURL = (url) => {
        if (url.includes('https://' || 'http://')) {
            return url;
        } else {
            return `http://${url}`;
        }
    
    }

    return (
        profile && tenant ?
        <div className="company-display-container">
                <Row>
                    <Col className='gx-1' sm={12} md={3}>
                        <Card className="side-nav weird-corners company-side-nav">
                            {
                                profile.fields.logo ?
                                profile.fields.website && profile.fields.website.href ?
                                    <a href={getExternalURL(profile.fields.website.href)} target="_blank" rel="noreferrer">
                                        <Card.Img variant="top" src={ profile.fields.logo.url } />
                                    </a>
                                    : <Card.Img variant="top" src={ profile.fields.logo.url } />
                                : null
                            }
                            {
                                profile.fields.headquartersCountry ?
                                    <Row className='info-bubbles m-b-end-04'>

                                        <Col xs={3} className="logo-col">

                                            <ReactCountryFlag
                                                style={{ width: "1.5em", height: 'auto' }}
                                                countryCode={countryList.getCode(profile.fields.headquartersCountry)}
                                                svg />

                                        </Col>

                                        <Col xs={9}>
                                            <div className="label">Headquarters</div>
                                            {profile.fields.headquartersCountry}
                                        </Col>
                                    </Row>
                                : null
                            }
                            {
                                profile.fields.companyType ?
                                    <Row className='info-bubbles m-b-end-04'>
                                        <Col xs={3} className="logo-col">
                                            <PeopleFill style={{width: "1.5em", height: 'auto'}} />
                                        </Col>
                                        <Col xs={9}>
                                            <div className="label">Company Type</div>
                                            { profile.fields.companyType }
                                        </Col>
                                    </Row>
                                : null
                            }
                            {
                                profile.fields.totalRevenues ?
                                    <Row className='info-bubbles m-b-end-07'>
                                        <Col xs={3} className="logo-col">
                                            <Bank style={{width: "1.5em", height: 'auto'}}/> 
                                        </Col>
                                        <Col xs={9}>
                                            <div className="label">Total Revenues</div>
                                            { profile.fields.totalRevenues }
                                        </Col>
                                    </Row>
                                : null
                            }
                            {
                                profile.fields.vaccineRevenues ?
                                    <Card className="mini-weird-corners">
                                        <Row>
                                            <Col xs={3} className="logo-col">
                                                <BarChartLineFill style={{width: "1.5em", height: 'auto'}}/>
                                            </Col>
                                            <Col xs={9}>
                                                <div className="label">Vaccine Revenues</div>
                                                { profile.fields.vaccineRevenues }
                                            </Col>
                                        </Row>
                                    </Card>
                                : null
                            }
                            <AppSideNav
                                apppath={`company_profiles/${profile.fields.slugname}`}  
                                tenantSettings={tenant} 
                                appname={CONSTANTS.APPS_BY_APPTYPE.COMPANY_PROFILES}
                                navs={useNavs} 
                                subItems={true} />
                        </Card>
                        
                    </Col>
                    <Col sm={12} md={9} className='p-i-start-06 '>
                        
                        <RelatedArticleTeaser 
                            tenant={tenant}
                            entity={profile} 
                            entityType={'company-profiles'} 
                            header='Related News & Company Intelligence'
                            tagQuery={true}
                            searchTerm={ profile.fields.tags && profile.fields.tags.length > 0 ? profile.fields.tags[0].fields.tagtext : slug }
                        />
                        <Switch>
                            <Route exact path={`${path}/:infoID`}>
                                <FieldDisplay fields={profile} fieldsDescr={useNavs} infoToShow="introduction" />
                            </Route>
                            <Redirect exact from={`${path}/`} to={`${path}/introduction`} />
                        </Switch>
                    </Col>
                </Row>
            </div>
        : null
    )
}

export default CompanyDisplay
