import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import React, {useEffect, useState} from 'react';
import { Row, Col, InputGroup, Image } from 'react-bootstrap';
import Select from 'react-select';

import ProductSearch from './Product/ProductSearch';
import TxSearch from './Tx/TxSearch';
import VaccineSearch from './Vaccine/VaccineSearch';
import OrganizationSearch from './Organization/OrganizationSearch';
import EnumSearch from './Enum/EnumSearch';
import DemandSearch from './Demand/DemandSearch';
import CountrySearch from './Country/CountrySearch';
import ActivitySearch from './CE/ActivitySearch';
import PriceSearch from './Price/PriceSearch';
import '../../styles/layout/_layout.scss'
import utils from '../../utils';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import OpenAIService from './OpenAI/OpenAIService';

function AlchemyApp({ preview=false, tenantSetting, appType, content, displayFieldName, tooltip, getTooltipContentByKey}) {

  // tenant can be GAVI or Linksbridge
  // if tenant.tenant is Linksbridge Q return everything
  // if tenant.tenant GAVI, return just GAVI data
  const [editor, setEditor] = useState()
  const [editorOptions, setEditorOptions] = useState([]);
  const [disableProductEdits, setDisableProductEdits] = useState(true);
  const [tenantId, setTenantId] = useState(null);
  // Houses the list of confidentialities that the user can EDIT
  const [visibleConfidentialities, setVisibleConfidentialities] = useState([]);
  const [editableConfidentialities, setEditableConfidentialities] = useState([]);
  const [showMSTFields, setShowMSTFields] = useState(false);
  const userName = useMsal().accounts[0].username;

  //get the global health alchemy app from the tenant.tenant.fields.apps to see if alt title
  const getAppForAlchemy = (tenant) => {
    if(tenant) {
      let thisApp = tenant.tenant.fields.apps.filter( (thisAppl) => {
        return thisAppl.fields.apptype.toLowerCase() === 'glbl_hlth_alchemy';
      });
  
      if(thisApp.length > 0) {
        return thisApp[0];
      }
    }
    return { fields: {}};
  }

  useEffect(()=>{
    if (!tenantSetting||!tenantSetting.tenant||!tenantSetting.tenant.fields||!tenantSetting.tenant.fields.apps){
      return
    }
    // Set the tenant id
    setTenantId(tenantSetting.tenant.fields.aDGroupID)
    // Get the available list of Editors
    const alchemyAppList = tenantSetting.tenant.fields.apps.filter((app)=>app.fields.apptype === "glbl_hlth_alchemy");
    if(alchemyAppList.length === 0|| !alchemyAppList[0].fields||!alchemyAppList[0].fields.alchemyEditors){
      return
    }
    const alchemyApp = alchemyAppList[0];
    const editorsString = alchemyApp.fields.alchemyEditors;
    const editors = editorsString.split(',')
    setEditorOptions(
        editors.map(
          (o)=> {return {label: o, value:o}}
        )
      )
    // set the edittor to the 0th one
    setEditor({label:editors[0], value:editors[0]})
    // Intentionally pretty restrictive here, if it's not 'false' it's true, 
    // null, undefined... all result in disabling edits
    setDisableProductEdits(alchemyApp.fields.disableProductEdits==='false'?false:true)

    // Get the confidentiality information from the app itself.
    const visConfs = tenantSetting.tenant.fields.visibleConfidentialities?
    tenantSetting.tenant.fields.visibleConfidentialities.map((vc)=> {return vc.fields.confidentiality})
      :[]
    setVisibleConfidentialities(visConfs);
    const editConfs = tenantSetting.tenant.fields.editableConfidentialities?
    tenantSetting.tenant.fields.editableConfidentialities.map((vc)=> {return vc.fields.confidentiality})
      :[]
    
    setEditableConfidentialities(editConfs);
    setShowMSTFields(tenantSetting.tenant.fields.showMSTFields === 'true');

  },[tenantSetting])


  const isAuthenticated = useIsAuthenticated()
  const [apiKey, setApiKey] = useState()
  const [loading, setLoading] = useState(true)
  const { instance, accounts } = useMsal();

  let tenID = utils.getForceTenantValue();

  if (!tenID) {
    //if no currently set forceTenantValue, get the id from the agility record, and set it in localstorage
    tenID = tenantSetting.tenant.fields.aDGroupID || null;
    utils.setForceTenantValue(tenID);
  }

  const trackCall = async (accToken, appType) => {
    //no await on purpose, we don't need to ensure that it returns - just issue it
    if(!preview) {
      utils.trackAppView(appType, tenID, accToken);
    }
  }

  useEffect(() => {
    const getAccessToken = async (instance, account) => {
      const request = {
        scopes: ["User.Read"]
      };
      try {
        let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
        return theAccToken;
      } catch (error) {
        
      }
    }
    try {
      (async () => {
        setLoading(true);
        let accToken = await getAccessToken(instance, accounts[0]);
        // only track if on prod
        if(process.env.REACT_APP_ENV === 'PROD' ) {
          trackCall(accToken, 'alchemy');
        }
        let response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL,{
          headers: {
            "Authorization": "Bearer " + accToken.accessToken
          }
        });
        const responsejson  = await response.json();
        setApiKey(responsejson.value);
        setLoading(false);
      })()
    } catch (err) {
      
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, accounts, instance]);
  
  return (
    !loading ?
      !preview ?
        <div className='alc-app'>
          <BreadCrumbBar
           appName="GLOBAL_HEALTH_ALCHEMY"
           item={content ? content : ''} 
           displayFieldName={displayFieldName} 
          />
          <Row>
            <Col xs={7}>
              <h2 className='heading'>
                  {
                    getAppForAlchemy(tenantSetting).fields.appAlternateTitle && getAppForAlchemy(tenantSetting).fields.appAlternateTitle.length > 0 ?
                      getAppForAlchemy(tenantSetting).fields.appAlternateTitle
                      :
                      'Global Health Alchemy'
                  }
              </h2>
            </Col>
            {
              editorOptions.length > 1 ?
                <Col>
                  <InputGroup className='display-flex justify-end'>
                    <InputGroup.Text className='select-text'>
                      Editor:
                    </InputGroup.Text>
                    <strong>
                      <Select
                        className='select-lg'
                        options={editorOptions}
                        value={editor}
                        onChange={(e) => setEditor(e)}
                      />
                    </strong>
                  </InputGroup>
                </Col>
                : null
            }

          </Row>

          {editor.value === "Products" ?
            <ProductSearch
              apiKey={apiKey}
              disableProductEdits={disableProductEdits}
              tenantId={tenantId}
              tenantSetting={tenantSetting}
              visibleConfidentialities={visibleConfidentialities}
              editableConfidentialities={editableConfidentialities}
              showMSTFields={showMSTFields}
              userName={userName}
              editor={editor.value}
              appClass='alc-app'
            />
            : null
          }
          {editor.value === "Drug Product" ? <TxSearch apiKey={apiKey} editor={editor.value} appClass='alc-app' /> : null}
          {editor.value === "Vaccines" ? <VaccineSearch apiKey={apiKey} editor={editor.value} appClass='alc-app'/> : null}
          {editor.value === "Organizations" ? <OrganizationSearch apiKey={apiKey} editor={editor.value} appClass='alc-app'/> : null}
          {editor.value === "Countries" ? <CountrySearch apiKey={apiKey} userName={userName} editor={editor.value} appClass='alc-app'/> : null}
          {editor.value === "Synonyms" ? <EnumSearch apiKey={apiKey} editor={editor.value} appClass='alc-app'/> : null}
          {editor.value === "Demand" ? <DemandSearch apiKey={apiKey} editor={editor.value} appClass='alc-app'/> : null}
          {editor.value === "Price" ? <PriceSearch
            apiKey={apiKey}
            disableProductEdits={disableProductEdits}
            tenantId={tenantId}
            visibleConfidentialities={visibleConfidentialities}
            editableConfidentialities={editableConfidentialities}
            showMSTFields={showMSTFields}
            userName={userName}
            editor={editor.value}
            appClass='alc-app'
          /> : null}
          {editor.value === "CE" ? <ActivitySearch appClass='alc-app' apiKey={apiKey} userName={userName} tooltip={tooltip} getTooltipContentByKey={getTooltipContentByKey} editor={editor.value}/> : null}
          {editor.value === 'Amadya'? <OpenAIService apiKey={apiKey} userName={userName} assistantName='amadya' appClass='alc-app'/>:null}
        </div>
        :
        <div className='preview-img-div'>
          <Image className='preview-card-img'
            src={
              getAppForAlchemy(tenantSetting).fields.previewImage && getAppForAlchemy(tenantSetting).fields.previewImage.url ?
                getAppForAlchemy(tenantSetting).fields.previewImage.url
                : null
            }
          />
        </div>
      : <ListSkeletonLoader preview={preview} />
  )
}

export default AlchemyApp;
