import React from 'react';
import { Badge, Row, Col } from 'react-bootstrap';
import { PersonFill } from 'react-bootstrap-icons';
import ToolTip from './../../Layout/ToolTip';
import CONSTANTS from '../../../constants';

function PMTKanbanCard(props) {

  return (
    <>
    <div id={props.id} className={props.archived === true ? "lb-'kanban'-card archived" : "lb-kanban-card"} onClick={() => props.handleShowListItemModal(props.id)}>
      <Row>
        <Col xs={11}>
          <h4>
          {props.archived && props.archived === true ?
            `* Archived * ${props.title ? props.title.slice(0, 75) : '' }`
          :
          props.title ? props.title.slice(0, 75) + ' ...' : ''
          }
          </h4>
        </Col>
        <Col xs={1} className='tool-tip'>
        <ToolTip tooltip={props.tooltip}/>
        </Col>
      </Row>
        { props.description && props.description.length > 0 ?
          <p>
              { props.description }
          </p>
        : null }
        <Badge className='mkt-name-badge badge-radius-lg surface-brand-primary'>{props.list_name}</Badge>
        {
          props.customField_3 && props.customField_3.length > 0 ?
            props.customField_3.map((item, idx) => {
              return <Badge className={`${CONSTANTS.MULTI_SELECT_COLORS[idx]} m-i-start-03 badge-radius-lg`}>{item.hmf_category}</Badge>
            })
          : null
        }
        <Badge className='archived-badge badge-radius-lg'>{props.archived === true ? 'Archived': ''}</Badge>
        <Row className='m-b-start-04'>
          <Col xs={1}>
            <PersonFill />
          </Col>
          <Col className="person-responsible">
            <span>{props.assigned_to}</span>
          </Col>
        </Row>
    </div>
  </>
  )
}

export default PMTKanbanCard