import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CONSTANTS from '../../constants';
import NavUtils from '../../NavUtils';
import PMTApp from '../../components/PMT/PMTApp';
import PMTformatUtils from '../../PMTformatUtils';
import { useToast } from '../../components/ToastProvider';
import PMTCONSTANTS from '../../components/PMT/pmtConstants';
import ListSkeletonLoader from '../../components/Layout/ListSkeletonLoader';
import pmtutils from '../../components/PMT/pmtutils';
import utils from '../../utils';

function PMTAppPage({tenant, token}) {

  let [parentPMTList, setParentPMTList] = useState();
  let [loading, setLoading] = useState(true);
  let [ apiKey, setAPIKey] = useState();
  let [groupMembers, setGroupMembers ] = useState([]);
  let [shouldLoadList, setShouldLoadList] = useState(true);
  let [showArchived, setShowArchived] =  useState(false);
  let [appName, setAppName] = useState();
  let [suppliers, setSuppliers] = useState();
  let [options, setOptions] = useState({
    'Show Archived': false,
    'Due in the next 6 months': false,
    'Due in the next year': false,
});

  const groupID = tenant.tenant.fields.aDGroupID;
  const { addToast } = useToast();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  let hasAccess = NavUtils.hasAppAccess(tenant, CONSTANTS.APPS_BY_APPTYPE.MARKET_ACTIONS);
  const { instance, accounts } = useMsal();
  let userInfo = utils.findUserInfo(tenant.ourUserID, groupMembers)


  if(!hasAccess) {
      history.push('/');
  }

  const request = {
    scopes: ["User.Read"]
  };

  const updatedItem = (list, change) => {
    let retItem;
    let retArr = list.map((item) => {
      if(item.item_id === change.id) {
        item[change.field] = change.value
        retItem = item
        return item;
      }
      return item;
    })

    setParentPMTList(retArr)
    return retItem;
  }

  const updateListItemForItemChange = async(change) => {
 
    let newItem = updatedItem(parentPMTList, change)
    let listItem = pmtutils.sendToFormatter(appName, newItem, userInfo)

    let itemToSend = listItem[0];
    let app = appName.fields.appAlternateTitle;

    try {
      setLoading(true);
      const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${PMTCONSTANTS[app].UPSERT_ITEM}?${PMTCONSTANTS[app].ID_PARAM}=${change.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'access_token': apiKey
          //need to add other access token for workspace access here
        },
        body: JSON.stringify(itemToSend)
      })
      setShouldLoadList(true)
      if (result['status'] === 403) {
        addToast({
          title: 'isValid Key',
          body: "Invalid API key, access denied"})
      }
    } catch (error) {
      addToast({
        title: 'Update Error',
        body: 'Whoops!  something happened on this submit.  Please try again.'
      })
    }
  }
    
  const getAPIKey = async(accToken) => {
    let response = await fetch(process.env.REACT_APP_GVMM_API_KEY_URL,{
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });
    const responsejson  = await response.json();
    setAPIKey(responsejson.value)
    return responsejson.value;
  }

  const getPMTList = async (apiKey, app, inclDel = false) => {
    let url = PMTCONSTANTS[app].GET_LIST;

    const result = await fetch(`${process.env.REACT_APP_DB_API_URL}${url}?include_deleted=${inclDel}`, {
      headers: {
        'Content-Type': 'application/json',
        'access_token': apiKey
        //need to add other access token for workspace access here
      }
    })
    if (result['status'] === 403) {
      alert("Invalid API key, access denied")
    }

    let resJson = await result.json()
    formatDataBasedOnApp(resJson, app)
  }

  const getAppAltTitle = (ten) => {
    let PMTtype = ten.tenant.fields.apps.filter((app) => {
      return app.fields.apptype === 'pmt_ma';
    })
    setAppName(PMTtype[0])
    return PMTtype[0]
  }

  const formatDataBasedOnApp = (list, app) => {
    if( list && list.length > 0 ) {
      let retArr; 
      //TODO: As we add more apps turn into switch statement
      if ( app && app === 'PMT MA') {
        retArr = PMTformatUtils.formatMarketActions(list)
        setParentPMTList(retArr)
      }
    }
  }

  const getAccessToken = async (inst, account, req) => {
    try {
      let theAccToken = await inst.acquireTokenSilent({ ...req, account: account });
      return theAccToken;
    } catch (error) {
      console.log(error);
    }
  }

  const getMembersOfGroup = async (grpID) => {

    let accToken = await getAccessToken(instance, accounts[0], request);
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/get-group-members?gid=${grpID}`, {
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });

    let jsonresponse = await response.json();
    setGroupMembers(jsonresponse);
  }

  const updateTopList = (listItem) => {
    let copyList = [...parentPMTList]
    
    let updatedList = copyList.map((item) => {
      if(item.item_id === listItem.item_id) {
        item = listItem;
        return item;
      }
      return item;
    })
    setParentPMTList(updatedList)
  }

  useEffect(() => {
      try {
        (async () => {
          setLoading(true);
          let app = getAppAltTitle(tenant);
          let apiKey = await getAPIKey(token);
          
          if(shouldLoadList === true) {
            await getPMTList(apiKey, app.fields.appAlternateTitle, showArchived);
            setShouldLoadList(false)
          }
          let getSuppliers = await utils.getData('get_organization_names', apiKey)
          setSuppliers(getSuppliers);
          await getMembersOfGroup(groupID, token)
          setLoading(false);
        })()
      } catch (err) {
        addToast({
          title: 'Update Error',
          body: 'Whoops!  something happened on this submit.  Please try again.'
        })
        setLoading(false);
      }
      //eslint-disable-next-line
    }, [isAuthenticated, token, groupID, shouldLoadList])

  const handleShowArchived = () => {
    setShowArchived(!showArchived);
    setShouldLoadList(true);
  }
  
  const displayOptionHandler = (e) => {
    let temp = { ...options };
    temp[e.target.id] = e.target.checked;
    setOptions(temp);

    if (e.target.value === 'Show Archived') {
        handleShowArchived();
    }
}

const handleReload = () => {
  setOptions({
    'Show Archived': false,
    'Due in the next 6 months': false,
    'Due in the next year': false,
  })
  setShouldLoadList(true);
  setShowArchived(false);
}

  return (    
    !loading && token && parentPMTList && parentPMTList.length > 0 && tenant ?
        <div>
            <PMTApp 
              appName={appName}
              tenantSetting={ tenant } 
              suppliers={suppliers}
              preview={ false } 
              token={ token } 
              loading={ loading }
              parentPMTList={parentPMTList}
              apiKey={apiKey}
              updateListItemForItemChange={updateListItemForItemChange}
              memberList={groupMembers}
              updateTopList={updateTopList}
              handleShowArchived={handleShowArchived}
              handleReload={handleReload}
              handleLoadList={() => setShouldLoadList(true)}
              archived={showArchived}
              displayOptionHandler={displayOptionHandler}
              options={options}
            />
        </div>
        : <ListSkeletonLoader preview={false} />
  )
}

export default PMTAppPage;