import { Button, Table} from 'react-bootstrap'

import '../../../styles/layout/_layout.scss'
import ObjectSelector from '../ObjectSelector'
import EnumSelector from '../EnumSelector'

function ProductEditorProductOrgs({product, setProduct, apiKey, organizations, disableProductEdits}) {
  /*----------------------State Variables-----------------------------------*/


  function setRowOrganization(org, i) {
    // Set the organiztion in a row of organizations in the bottom right of the UI
    const organizations = product.organizations
    if (org === null){
      organizations[i].organization = null
      organizations[i].organization_id = null
    } else{
      organizations[i].organization = org
      organizations[i].organization_id = org.org_id
    }
    setProduct({
      ...product,
      organizations:organizations
    })
  }


  function addOrganization(){
    // Creates a new organization row based on organization template
    const organizations = product.organizations
    organizations.push({
      organization_id: null,
      funding: 0,
      org_role: null,
      organization: null,
      deleted:false
    })
    setProduct({
      ...product,
      organizations:organizations
    })
  }



  function setRowRole(e, i) {
    // Sets organization role for row i
    const organizations = product.organizations
    organizations[i].org_role = e.label
    setProduct({
      ...product,
      organizations:organizations
    })
  }


  function deleteOrganization(index) {
    const organizations = [...product.organizations]
    organizations.splice(index, 1)
    setProduct({...product, organizations: organizations})
  }
  return  (
    <div>
      <Table size = "sm">
        <thead className = "table-heading">
          <tr>
            <th>Organization</th>
            <th>Role</th>
            {
              disableProductEdits?
              <th></th>:
              <th className="clickable-text"
                onClick={addOrganization}>
                  add
              </th>
            }
            
          </tr>
        </thead>
        <tbody className ="table-body">
          {product.organizations.map((o, i) => {
            return <tr key = {i}>
              <td>
                <ObjectSelector
                apiKey = { apiKey }
                data={organizations}
                obj={product.organizations[i].organization}
                onChange={(e) => setRowOrganization(e, i)}
                isClearable={true}
                type='organization'
                disabled={disableProductEdits}
              />
              
              </td>
              <td>
                <EnumSelector
                  apiKey={apiKey}
                  className = "select"
                  enum_type = "product_org_role"
                  value = {product.organizations[i].org_role}
                  onChange = {(e) => setRowRole(e, i)}
                  disabled={disableProductEdits}
                />
              </td>
              <td className='right-align'>
                {disableProductEdits?
                  null:
                  <Button 
                    size="sm"
                    variant="danger" 
                    onClick = {() => deleteOrganization(i)}>
                    -
                  </Button>
                }
                
              </td>
            </tr>})
          }
        </tbody>
      </Table>
      
    </div>
  );
}  

export default ProductEditorProductOrgs;