import React from 'react';
import { Image } from 'react-bootstrap';

import AppsCollection from '../../components/AppsCollection';
import utils from '../../utils';
import AlertWrapper from '../../components/AlertWrapper';

function MainAppPage({ tenantSettings, token, showNav, windowSize, showFakes=false }) {

    return (
        tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields ?
            <>
            {
            tenantSettings && tenantSettings.tenant.fields.bannerImage && tenantSettings.tenant.fields.bannerImage.url ?
                    <div className='wksp-banner-content'>
                        <Image src={tenantSettings.tenant.fields.bannerImage.url}/> 
                        <div className='header-content'>
                            <h3>{tenantSettings.tenant.fields.name}</h3>
                            <div 
                                className="landing-page-intro" 
                                dangerouslySetInnerHTML={utils.getHTML(tenantSettings.tenant.fields.landingPageHeadingText)} />
                        </div>
                    </div>
                :
                <div className='wksp-no-bannerIMG'>
                        <div className='header-content'>
                            <h3>{tenantSettings.tenant.fields.name ? tenantSettings.tenant.fields.name : ''}</h3>
                            <div 
                                className="landing-page-intro" 
                                dangerouslySetInnerHTML={utils.getHTML(tenantSettings.tenant.fields.landingPageHeadingText ? tenantSettings.tenant.fields.landingPageHeadingText : '')} />
                        </div>
                </div>
            }
                
                <AppsCollection 
                    tenant={ tenantSettings }
                    tenantAppsList={tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : []} 
                    className="main-apps-collection" 
                    token={token} 
                    showNav={showNav} 
                    windowSize={windowSize}
                    showFakes={showFakes} />
                
                <AlertWrapper 
                    tenant={tenantSettings}
                    token={token}
                />
            </>
        :''
    );
};

export default MainAppPage
