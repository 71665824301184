import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams} from 'react-router-dom';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Paperclip } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { useToast } from '../ToastProvider';

import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import TagDisplay from '../TagDisplay';
import EyesSelector from './EyesSelector';
import utils from '../../utils';
import CONSTANTS from '../../constants';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import AzureStorageFileDisplayList from './AzureStorageFileDisplayList';
import ModalDynamic from '../Layout/ModalDynamic';
import BtnDynamic from '../Layout/BtnDynamic';

function IntelligenceDisplay({tenant}) {

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // let history = useHistory();
  let { reportID } = useParams();
  let [report, setReport] = useState();
  let [ infoOwner, setInfoOwner ] = useState();
  let [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState();
  let [tagsList, setTagsList] = useState([]);
  let [showArchive, setShowArchive] = useState(false);
  let [showDelete, setShowDelete] = useState(false);

  let history = useHistory();
  const { addToast } = useToast();

  const archiveBtnContent = {
    icon: utils.getIcon('archive'),
    name: report && report.archived === 'true' ? 'Un-archive Report' : 'Archive Report'
  }

  const deleteBtnContent = {
    icon: utils.getIcon('delete'),
    name: 'Delete Report'
  }

  const formatReport = (data) => {

    if (data && data.fields) {
      let retObj = {
        contentID: data.contentID,
        credibility: data.fields.credibility ? data.fields.credibility : '',
        date: utils.getDateString(new Date(), 'newDate'),
        informationOwner: data.fields.informationOwner ? data.fields.informationOwner : tenant.tenant.fields.functionalInformationOwner,
        internal_comment: data.fields.internalComment ? data.fields.internalComment :'',
        keyContact: data.fields.keyContact ? data.fields.keyContact : '',
        note: data.fields.note ? data.fields.note : '',
        reliability: data.fields.reliability ? data.fields.reliability : '',
        reportDate: data.fields.date ? data.fields.date : '',
        sensitivity: data.fields.sensitivity ? data.fields.sensitivity: '',
        source: data.fields.source ? data.fields.source : '',
        //SourceType - Backend expects reportType please keep
        reportType: data.fields.reportType ? data.fields.reportType : '',
        sourceLink: data.fields.sourceLink ? data.fields.sourceLink : '',
        attachedImageList: data.fields.attachedImageList ? data.fields.attachedImageList.split(',') : [],
        attachedRefFileList: data.fields.attachedRefFileList ? data.fields.attachedRefFileList.split(','): [],
        tags: data.fields.tags_ValueField ? data.fields.tags_ValueField : '',
        title: data.fields.title ? data.fields.title :'',
        reporter: data.fields.reporter ? data.fields.reporter :'',
        gaviEyes: data.fields.gaviEyes === "true" ? "on":"off",
        bmgfEyes: data.fields.bMGFEyes === "true" ? "on":"off",
        unicefEyes: data.fields.uNICEFEyes === "true" ? "on":"off",
        whoEyes: data.fields.wHOEyes === "true" ? "on":"off",
        chaiEyes: data.fields.cHAIEyes === "true" ? "on":"off",
        cepiEyes: data.fields.cEPIEyes === 'true' ? 'on' : 'off',
        archived: data.fields.archived ? data.fields.archived : 'false',
        approved: data.fields.approved ? data.fields.approved : 'false',
        modified: data.properties.modified ? data.properties.modified :''
      };
      setReport(retObj);
      setTagsList(data.fields.tags)
    }
  }

  const request = {
    scopes: ["User.Read"]
  };

  const reliabilityObj = {
    'A': 'Very High',
    'B': 'High',
    'C': 'Medium',
    'D': 'Low',
    'E': 'Very Low',
    'F': 'Cannot Be Determined'
  }

  const credibilityObj = {
    '1': 'Confirmed by other sources',
    '2': 'Probably True',
    '3': 'Possibly True',
    '4': 'Doubtful',
    '5': 'Improbable',
    '6': 'Truth cannot be judged'
  }

  const sensitivityObj = {
    '1': 'Public',
    '2': 'Privileged',
    '3': 'Confidential'
  }

  const getReport = async (reportID) => {
    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?id=${reportID}`, {});

    let jsonresponse = await response.json();
    setInfoOwner(utils.convertInfoOwnerNumberToSlug(jsonresponse.fields.informationOwner, CONSTANTS.FUNCTIONAL_INFO_OWNER) )
    formatReport(jsonresponse);
  }

  useEffect(() => {
    if (isAuthenticated) {
      try {
        (async () => {
          setLoading(true);
          
          if (!accessToken) {
            setAccessToken(await getAccessToken(instance, accounts[0]));
          }

          getReport(reportID);
          setLoading(false);
        }
        )()
      } catch (error) {
        addToast({
          title: 'Information Retrieval Failure',
          body: 'Whoops!  something happened.  Please try again.'
        })
        setLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [reportID])

  const getAccessToken = async (instance, account) => {
    try {
      let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
      setAccessToken(theAccToken);
      return theAccToken;
    } catch (error) {
      addToast({
        title: 'Access Denied',
        body: 'Whoops!  something happened.  Please try again.'
      })
    }
  }


// formats the URL, adding https:// if not provided
function formatUrl(url)
{
  const httpString = "http://";
  const httpsString = "https://";
  if (url && url.substr(0, httpString.length).toLowerCase() !== httpString && url.substr(0, httpsString.length).toLowerCase() !== httpsString)
    url = httpsString + url;
  return url;
}

const deleteModal = {
  type: 'delete',
  title: utils.getTitle("Confirm file removal"),
  body: "You are about to permanently remove this file. Once removed, it can't be recovered. Are you sure you want to proceed",
  app: 'intelligence',
  subName: 'Confirm'
}

const archiveModal = {
  type: 'archive',
  title: utils.getTitle(`You're about to ${report && report.archived === 'true' ? 'un-archive' : 'archive'} this report`),
  body: report && report.archived === 'true' ? 'Are you sure you want to continue?':'Archiving this report will remove it from other views. Are you sure you want to continue?',
  app: 'intelligence',
  subName: 'Confirm'
}

const setLoadingTrue = (state) => {
  setLoading(state);
}

const getTagsToDisplay = (tagArr) => {
  let tagsList = [];
  tagArr.forEach((tag) => {
    tagsList.push(tag.fields.tagtext)
  })
  return tagsList
}

const submitRequest = async (params) => {
  setLoadingTrue(true);

  utils.submitIntelligenceRequest(params, reportID, accessToken, report)
  setTimeout(() => {
    history.push('/intelligence')
  }, 7000)
}

  return (
    !loading && report && tenant && infoOwner ?
    <div className='cx-app'>
        <Col>
          <BreadCrumbBar
            appName="INTELLIGENCE"
            item={report}
            displayFieldName="title" />
        </Col>
        <Row className='article-display'>
          <Col className='article-col' xs={12} md={9}>
              <h2 className="page-title">
                {
                  report.archived && report.archived === 'true' ?
                    `*Archived* ${report.title}`
                  :
                    report.approved && report.approved !== "true" ?
                      `*Pending Approval* ${report.title}`
                      :
                      report.title
                }
              </h2>
              <a href={`mailto:${report.reporter}`}><span className='reported-by'>Reported By: </span>{report.reporter}</a>
              <div className='subtitle'>{`Created Date: ${utils.getDateString(report.reportDate, 'long')}`}</div>
              <div className='subtitle'>{`Last Edited: ${utils.getDateString(report.modified, 'long')}`}</div>
              <h4>Summary Notes</h4>
              <p
                className='body-text'
                dangerouslySetInnerHTML={utils.getHTML(report.note)}
              />
              {
                (tenant.tenant.fields.tenantslug.includes(infoOwner) || tenant.tenant.fields.tenantslug === "LB-Level-Q") && report.internalComment ?
                <>
                  <h4 className='internal-comment summary-notes'>Internal Comment</h4>
                  <p
                    className='body-text'
                    dangerouslySetInnerHTML={utils.getHTML(report.internalComment)}
                  /> 
                </>
                : ''
              }
                <h4>Report Information</h4>
                <div className='subtitle'>Reliability: {reliabilityObj[report.reliability]}</div> 
                <div className='subtitle'>Credibility: {credibilityObj[report.credibility]}</div> 
                <div className='subtitle'>Sensitivity: {sensitivityObj[report.sensitivity]}</div>
                <div className='subtitle'>Source Type: {report.reportType}</div>
                {
                  report.sourceLink ?
                  <>
                    <h4>Link to source</h4>
                    <div className='subtitle'><a href={formatUrl(report.sourceLink)} target='_blank' rel="noreferrer" >{report.sourceLink}</a></div>
                  </>
                  : ''
                }
                <h4>Key Contact(s)</h4>
                <div className='subtitle'>{report.keyContact}</div>
                <h4><Paperclip /> Attached Images</h4>
                <AzureStorageFileDisplayList 
                  contentArray={ report.attachedImageList }
                  svcName={"intellattachments"} 
                  folderName={"intelligence-attachments"} />
                <h4><Paperclip /> Attached Reference Files</h4>
                <AzureStorageFileDisplayList 
                  contentArray={ report.attachedRefFileList } 
                  svcName={"intellattachments"} 
                  folderName={"intelligence-resources"} />
                {
                  tagsList ?
                    <TagDisplay 
                      tagList={getTagsToDisplay(tagsList)} 
                      flatList={true}
                    />
                  : null
                }
          </Col>
          <Col xs={12} md={3}>
            {
              report.archived === 'false' ?
                <LinkContainer to={`/intelligence/edit/${report.contentID}`} id="edit-report">
                  <Button className='side-col-btn tertiary-button m-b-end-03'>Edit Report</Button>
                </LinkContainer>
              : ''
            }
            <BtnDynamic
              idName="archive-report"
              classes={'side-col-btn tertiary-button m-b-end-03'}
              content={archiveBtnContent}
              actionHandler={() => setShowArchive(!showArchive)}
            />
            <ModalDynamic
              show={showArchive}
              content={archiveModal}
              handleClose={() => setShowArchive(!showArchive)}
              submitRequest={() => submitRequest('archived=true')}
            />
            <BtnDynamic
              idName="delete-report"
              classes={'side-col-btn delete-btn'}
              content={deleteBtnContent}
              actionHandler={() => setShowDelete(!showDelete)}
            />
            <ModalDynamic
              show={showDelete}
              content={deleteModal}
              handleClose={() => setShowDelete(!showDelete)}
              submitRequest={() => submitRequest('del=true')}
            />
            <h4 className='info-owner'>Information Owner: {tenant.tenant.fields.tenantslug}</h4>
            <EyesSelector
              className='m-b-start'
              mode={'read'}
              tenant={tenant} 
              intelReport={report}
              onChange={()=>{}} 
            />
          </Col>
        </Row>
      </div>
      : <ListSkeletonLoader preview={false} />
  )
}

export default IntelligenceDisplay;