import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { Container, Spinner, Row, Card, Button, Image, Col } from 'react-bootstrap';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import ToastProvider from '../components/ToastProvider';

import TenantTopNav from '../components/Layout/Nav/TenantTopNav';
import utils from '../utils';
import tenantUtils from '../tenantUtils';
import SupportModal from '../components/SupportModal';
import '../styles/main.scss'
import MainAppPage from './appPages/MainAppPage';
import SigninLink from '../components/SigninLink';
import NewsAppPage from './appPages/news/NewsAppPage';
import ArticleDisplay from '../components/News/ArticleDisplay';
import CoProfilesAppPage from './companyProfiles/CoProfilesAppPage';
import CoProfileMainPage from './companyProfiles/CoProfileMainPage';
import SearchResultsPage from './SearchResultsPage';
import IndustryAnalysisAppPage from './industryAnalysis/IndustryAnalysisAppPage';
import VaccineMarketsAppPage from './vaccineMarkets/VaccineMarketsAppPage';
import VaccineMarketsListPage from './vaccineMarkets/VaccineMarketsListPage';
import IntelligenceAppPage from './intelligence/IntelligenceAppPage';
import IntelligenceDisplay from '../components/Intelligence/IntelligenceDisplay';
import IntellEditReport from '../components/Intelligence/IntellEditReport';
import AlchemyAppPage from './Alchemy/AlchemyAppPage';
import microsoftLogo from '../assets/microsoft-logo.png'
import MarketActionsAppPage from './marketActions/MarketActionsAppPage';
import MarketActionsMarketPage from './marketActions/MarketActionsMarketPage';
import PMTAppPage from './PMT/PMTAppPage';
import AdrsPopEstAppPage from './AddrsPopEstimator/AdrsPopEstAppPage';
import MetaAppPage from './meta/MetaAppPage';
import PrtnrProfilesAppPage from './PrtnrProfiles/PrtnrProfilesAppPage';
import PrtnrProfilesListPage from './PrtnrProfiles/PrtnrProfilesListPage';
import TenantAppAccordian from '../components/Layout/Nav/TenantAppAccordian';
import SettingsPage from './SettingsPage';
import LogsAppPage from './LogsApp/LogsAppPage';

import CONSTANTS from '../constants';
import TopicNodePage from '../components/News/TopicNodePage';
import Notification from '../components/Layout/Notification/Notification';
import SuperAdminMgmtPage from './LogsApp/SuperAdminMgmtPage';
import FirstTimeUserModal from '../components/FirstTimeUserModal';
import IRCAppPage from './IRCApp/IRCAppPage';
import CENewsAppPage from './CENews/CENewsAppPage';
import CEArticleDisplay from '../components/CENews/CEArticleDisplay';


function SignedInPage() {

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const history = useHistory();

    const { search } = useLocation();

    let [accessToken, setAccessToken] = useState(null);
    let [tenantSettings, setTenantSettings] = useState(null);
    let [firstTimeUser, setFirstTimeUser] = useState(false);
    let [loading, setLoading] = useState(true);
    let [queryRequest, setQueryRequest] = useState(null);
    let [tooltip, setTooltip] = useState([]);
    let [apiError, setApiError] = useState(null);
    let [supportModalShown, setSupportModalShown] = useState(false);
    let [supportContext] = useState();
    let [showNav, setShowNav] = useState(true);
    let [windowSize, setWindowSize] = useState(window.innerWidth);
    let [curNav, setCurNav] = useState();
    let [notifications, setNotifications] = useState();

    const setSupportModalIsShown = (newVal) => {
        setSupportModalShown(!!newVal);
    }

    const getNewScreenWidth = (screenSize) => {
        setWindowSize(screenSize);
    }

    const getPathnameFormat = (name) => {
        let pathname = name.split('/');
        pathname = pathname[1].toUpperCase();
        if (pathname) {
            document.title = `Workspace | ${CONSTANTS.APPS_BY_NAME[pathname]}`;
        } else {
            document.title = `Workspace | Home`
        }
        return pathname;
    }

    let urlPathname = window.location.pathname;
    getPathnameFormat(urlPathname)

    const request = {
        scopes: ["User.Read"]
    };

    let { path } = useRouteMatch();

    const onQuery = (query) => {
        setQueryRequest(query);
        history.push(`${path}search/${query}?pg=home`);
    }

    const getAccessToken = async (instance, account) => {
        try {
            let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
            setAccessToken(theAccToken);
            return theAccToken;
        } catch (error) {
            if (error.name === "InteractionRequiredAuthError") {
                try {
                    return await instance.acquireTokenPopup({ ...request, account: account });
                } catch (err) {
                    console.log('error: ', err);
                }

            } else {
                console.error(error);
            }
        }

    }

    //Filters list of notification to only show notifications not stored in localStorage
    const filterLocalStorageNotes = (notes, userSettings) => {
        let tempArr = {
            data: []
        }

        if (notes && notes.data && notes.data.length > 0) {
            notes.data.forEach((notification) => {
                //checks to see if notification and userID exists in local storage.
                let localStrNote = localStorage.getItem(`${notification.contentID}${userSettings.ourUserID}`);
                //if not it is added to temp array and added to local storage
                if (!localStrNote) {
                    tempArr.data.push(notification);
                    localStorage.setItem(`${notification.contentID}${userSettings.ourUserID}`, notification.contentID)
                }
            })
            setNotifications(tempArr)
        } else {
            setNotifications(notes)
        }
    }

    //Gets a list of notifications to alert the user with
    const getNotifications = async (token, wkspID, userSettings) => {

        if (!token) {
            token = utils.getAccessToken(instance, accounts[0], request)
        }
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/notifications?spc_id=${wkspID}`, {
            headers: {
                "Authorization": "Bearer " + token.accessToken
            }
        });
        let wkspNotifications = await response.json();
        //sends list of notifications to filter to only show ones user has not seen.
        filterLocalStorageNotes(wkspNotifications, userSettings);
    }

    const getTooltip = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/cms-content?contenttype=tooltips`, {});
        let jsonresponse = await response.json();
        setTooltip(jsonresponse.items);
    }


    useEffect(() => {
        let curAccess;
        try {
            (async () => {
                let tid = utils.getForceTenantValue();

                const urlParams = new URLSearchParams(search);

                if (urlParams.has('tenid') && urlParams.get('tenid').length > 0) {
                    utils.setForceTenantValue(urlParams.get('tenid'));
                    tid = utils.getForceTenantValue();
                }

                setLoading(true);

                if (!accessToken) {
                    curAccess = await getAccessToken(instance, accounts[0]);
                }

                if (curAccess) {
                    await utils.setAccessTokenForPyramid(curAccess);
                }
                getTooltip();
                // Get the return from getTenantInfo
                const settings = await tenantUtils.getTenantInfo(curAccess, tid)
                if (typeof settings === 'string') {
                    // If it was a string then the status was something other than 200
                    // so set the apiError to the string.
                    setApiError(settings)
                } else {
                    setTenantSettings(settings);

                    //catch self-signed-up users that haven't been truly added to tenant yet
                    if (settings && settings.firstTime && settings.firstTime === true) {
                        setFirstTimeUser(true);
                    }
                }
                if (settings) {
                    getNotifications(curAccess, tid, settings)
                }
                setLoading(false);
            }
            )()

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    const getTooltipInfo = (tooltipArr) => {
        let tooltipItem;

        tooltipArr.forEach((item) => {
            if (item.fields.key === "sensitivityTooltip") {
                tooltipItem = item.fields.content;
            }
            return tooltipItem;
        })
        return tooltipItem
    }

    const getTooltipContentByKey = (tooltipArr, key) => {
        const tooltipItem = tooltipArr?.find(item => item.fields.key === key);
        return tooltipItem ? tooltipItem.fields.content : null;
    }

    const showNavHandler = () => {
        setShowNav(!showNav);
    };

    const onLinkClick = (navLink) => {
        setCurNav(navLink);
    };

    // Needed for Workspaces with the Alchemy app
    // Forces token reset so user can sign out and is dirrected to the sign in page. 
    const resetToken = () => {
        setAccessToken(null);
    };

    //if they self-signed and are viewing this page for the first time
    //intercept and fill up with fake app stuff

    return (
        loading === true && windowSize ?
            <Spinner animation='border' />
            :
            tenantSettings && isAuthenticated === true ?
                <div className={tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields ? tenantSettings.tenant.fields.tenantslug : ''}>

                    <Row className='content-row'>
                        {

                            windowSize > 991 ?
                                <Col
                                    lg={
                                        showNav === true && windowSize > 1199 ?
                                            2
                                            :
                                            showNav === true && windowSize > 991 && windowSize <= 1199 ?
                                                3
                                                :
                                                1
                                    }

                                    className={showNav ?
                                        'side-workspace-nav show'
                                        : 'side-workspace-nav hide'}>
                                    <div className='nav-container'>
                                        <TenantAppAccordian
                                            tenantSlug={tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.tenantslug}
                                            tenantApps={
                                                tenantSettings &&
                                                    tenantSettings.tenant &&
                                                    tenantSettings.tenant.fields &&
                                                    tenantSettings.tenant.fields.apps
                                                    ? tenantSettings.tenant.fields.apps
                                                    : ''}
                                            raiseSupport={setSupportModalIsShown}
                                            sa={
                                                tenantSettings &&
                                                    tenantSettings.tenant &&
                                                    tenantSettings.tenant.fields &&
                                                    tenantSettings.tenant.fields.sa ?
                                                    tenantSettings.tenant.fields.sa
                                                    : ''
                                            }
                                            tenant={tenantSettings}
                                            show={showNav}
                                            showNavHandler={showNavHandler}
                                            screenSize='large'
                                            onLinkClick={onLinkClick}
                                        />
                                    </div>
                                </Col>
                                : ''
                        }
                        <Col className='nav-content-col' lg={
                            showNav === true && windowSize > 1199 ?
                                10
                                :
                                showNav === true && windowSize > 991 && windowSize <= 1199 ?
                                    9
                                    :
                                    11
                        }>
                            <TenantTopNav
                                className={showNav ? 'show' : 'hide'}
                                tenant={tenantSettings && tenantSettings.tenant ? tenantSettings.tenant : null}
                                onQuery={onQuery}
                                raiseSupport={setSupportModalIsShown}
                                onWidthChange={getNewScreenWidth}
                                windowSize={windowSize}
                                tenantName={
                                    tenantSettings &&
                                        tenantSettings.tenant &&
                                        tenantSettings.tenant.fields &&
                                        tenantSettings.tenant.fields.tenantSlug ?
                                        tenantSettings.tenant.fields.tenantslug : ''}
                                resetToken={resetToken}
                                showFakes={firstTimeUser}
                            />
                            {
                                windowSize < 992 ?
                                    <Col md={12} onClick={() => setShowNav(!showNav)} className={showNav ? 'top-workspace-nav show' : 'top-workspace-nav hide'}>
                                        <TenantAppAccordian
                                            tenantApps={
                                                tenantSettings &&
                                                    tenantSettings.tenant &&
                                                    tenantSettings.tenant.fields &&
                                                    tenantSettings.tenant.fields.apps ?
                                                    tenantSettings.tenant.fields.apps : ''}
                                            raiseSupport={setSupportModalIsShown}
                                            show={showNav}
                                            tenant={tenantSettings}
                                            showNavHandler={showNavHandler}
                                            screenSize='small'
                                            onLinkClick={onLinkClick}
                                            showFakes={firstTimeUser}
                                        />
                                    </Col>
                                    : ''
                            }
                            <Container fluid className={showNav ? "profiles-page body-container show" : "profiles-page body-container hide"}>
                                {
                                    notifications && notifications.data && notifications.data.length > 0 ?
                                        notifications.data.map((note) => {
                                            return (
                                                <Notification notificationType={note.fields.notificationType} content={note.fields} />
                                            )
                                        })
                                        : ''
                                }
                                <ToastProvider>
                                    <Row className="main-body-text">
                                        {
                                            tenantSettings && accessToken && isAuthenticated ?
                                                <div className='isAuth-container'>
                                                    <div className={showNav ? 'isAuth-landing-page show' : 'isAuth-landing-page hide'}>
                                                        <Switch>
                                                            <Route exact path={`${path}`}>
                                                                <MainAppPage
                                                                    tenantSettings={tenantSettings}
                                                                    token={accessToken}
                                                                    showNav={showNav}
                                                                    windowSize={windowSize}
                                                                    showFakes={firstTimeUser} />
                                                            </Route>

                                                            <Route exact path={`${path}settings`}>
                                                                <SettingsPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}logs`}>
                                                                <LogsAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}super_admin`}>
                                                                <SuperAdminMgmtPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}news`}>
                                                                <NewsAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}ce_news`}>
                                                                <CENewsAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}ce_news/:articleID`}>
                                                                <CEArticleDisplay wokrspace={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}topic/:topicSlug`}>
                                                                <TopicNodePage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}news/:storyID`}>
                                                                <ArticleDisplay tenant={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}company_profiles`}>
                                                                <CoProfilesAppPage tenant={tenantSettings} token={accessToken} />
                                                            </Route>
                                                            <Route path={`${path}company_profiles/:slug`}>
                                                                <CoProfileMainPage tenant={tenantSettings} token={accessToken} singularCompany={false} />
                                                            </Route>
                                                            <Route path={`${path}my_company_profile`}>
                                                                <CoProfileMainPage tenant={tenantSettings} token={accessToken} singularCompany={true} />
                                                            </Route>
                                                            <Route exact path={`${path}partner_profiles`}>
                                                                <PrtnrProfilesListPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}partner_profiles/:slug`}>
                                                                <PrtnrProfilesAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}search/:query`}>
                                                                <SearchResultsPage tenant={tenantSettings} query={queryRequest} curNav={curNav} />
                                                            </Route>
                                                            <Route exact path={`${path}glbl_hlth_alchemy`}>
                                                                <AlchemyAppPage tenant={tenantSettings} tooltip={tooltip} getTooltipContentByKey={getTooltipContentByKey} />
                                                            </Route>
                                                            <Route path={`${path}industry_analysis`}>
                                                                <IndustryAnalysisAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields&& tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "irc_app")}/:cntryISO?`}>
                                                                <IRCAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "market_actions")}`}>
                                                                <MarketActionsAppPage tenant={tenantSettings} token={accessToken} />
                                                            </Route>
                                                            <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "market_actions")}/markets/:marketID`}>
                                                                <MarketActionsMarketPage tenant={tenantSettings} token={accessToken} />
                                                            </Route>
                                                            <Route path={`${path}${utils.findURLPath(tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields && tenantSettings.tenant.fields.apps && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : '', "pmt_ma")}`}>
                                                                <PMTAppPage tenant={tenantSettings} token={accessToken} />
                                                            </Route>
                                                            <Route path={`${path}addressable_pop`}>
                                                                <AdrsPopEstAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}meta`}>
                                                                <MetaAppPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route exact path={`${path}vaccine_markets`}>
                                                                <VaccineMarketsListPage tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}vaccine_markets/:diseaseSlug`}>
                                                                <VaccineMarketsAppPage tenant={tenantSettings} token={accessToken} />
                                                            </Route>
                                                            <Route exact path={`${path}intelligence`}>
                                                                <IntelligenceAppPage tenant={tenantSettings} token={accessToken} />
                                                            </Route>
                                                            <Route path={`${path}intelligence/reports/:reportID`}>
                                                                <IntelligenceDisplay tenant={tenantSettings} />
                                                            </Route>
                                                            <Route path={`${path}intelligence/create`}>
                                                                <IntellEditReport type={'create'} tenant={tenantSettings} tooltip={getTooltipInfo(tooltip)} />
                                                            </Route>
                                                            <Route path={`${path}intelligence/edit/:reportID`}>
                                                                <IntellEditReport type={'edit'} tenant={tenantSettings} tooltip={getTooltipInfo(tooltip)} />
                                                            </Route>
                                                        </Switch>
                                                    </div>
                                                </div>
                                                :
                                                apiError ?
                                                    apiError === '503-Service Unavailable' ?
                                                        <div className="text-center">
                                                            <h1>Setting Up Your Account</h1>
                                                            <p>Please wait 30 seconds to a minute and refresh your browser.</p>
                                                        </div>

                                                        : <div className='signin-landing-page'>
                                                            <Card className='welcome-back'>
                                                                <p>{apiError}</p>
                                                                <h5>Something's missing!</h5>
                                                                <Button variant='primary' onClick={() => history.goBack()}>Go Back</Button>
                                                            </Card>
                                                        </div> :
                                                    <div className='signin-landing-page'>
                                                        <Card className='welcome-back'>
                                                            <h1>Welcome Back</h1>
                                                            <p>Please sign in to view the application</p>
                                                            <SigninLink>
                                                                <Button style={{ margin: 'auto', cursor: 'pointer' }}>
                                                                    <Image
                                                                        src={microsoftLogo}
                                                                        alt='Microsoft Logo'
                                                                        style={{
                                                                            width: "auto",
                                                                            height: "1.25em"
                                                                        }}
                                                                    />
                                                                    Sign in with Microsoft
                                                                </Button>
                                                            </SigninLink>
                                                        </Card>
                                                    </div>
                                        }

                                    </Row>
                                </ToastProvider>
                                <SupportModal
                                    userEmail={accounts && accounts.length > 0 ? accounts[0].username : ''}
                                    show={supportModalShown}
                                    onSubmitted={setSupportModalIsShown}
                                    contextInfo={supportContext}
                                />
                                {
                                    firstTimeUser && firstTimeUser === true ?
                                        <FirstTimeUserModal
                                            userID={tenantSettings && tenantSettings.ourUserID ? tenantSettings.ourUserID : ''}
                                        />
                                        : null
                                }
                                {/* firsttimeuser modal here */}
                            </Container>
                        </Col>
                        {/* <Container fluid className="body-container" >
                            <ProfilesFooter
                                raiseSupport={setSupportModalIsShown}
                                tenantApps={tenantSettings && tenantSettings.tenant && tenantSettings.tenant.fields.apps ? tenantSettings.tenant.fields.apps : []} />
                        </Container> */}
                    </Row>
                </div>
                : ''
    )
}

export default SignedInPage