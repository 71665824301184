import React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function CompanyTeaserLine({ profile, showLink=false, className, singleCompanyView=false }) {
    //logo, name, description, sluglink

    return (
        singleCompanyView===true ?
            <Row className='app-list-item'>
                <Col>
                    <LinkContainer to={`/company_profiles/${profile.fields.slugname}`} className='link-container'>
                        <a href={`/company_profiles/${profile.fields.slugname}`}>
                            { 
                                profile.fields.logo ? 
                                    <Image className="co-teaser-logo big single_co" src={profile.fields.logo.url} alt={profile.fields.name} /> 
                                : null
                            }
                        </a>
                    </LinkContainer>
                </Col>
            </Row>
        :
        <Row className={"profile_list_item"+className ? ' '+className:''}>
            <Col className="text-center" xs={6}>
                <LinkContainer to={`/company_profiles/${profile.fields.slugname}`}>
                    <a href={`/company_profiles/${profile.fields.slugname}`}>
                        { 
                            profile.fields.logo ? 
                                <Image className="co-teaser-logo" src={profile.fields.logo.url} alt={profile.fields.name} /> 
                            : null
                        }
                    </a>
                </LinkContainer>
            </Col>
            <Col xs={6} style={{alignSelf: 'center'}}>
                <LinkContainer to={`/company_profiles/${profile.fields.slugname}`}>
                    <a className="no-color secondary-link-plain" href={`/company_profiles/${profile.fields.slugname}`}>
                        <h6>{ profile.fields.name }</h6>
                    </a>
                </LinkContainer>
                <p className="highlighted-text">
                    Updated on { new Date(profile.properties.modified).toLocaleDateString() }
                </p>
            </Col>
            <Col xs={12} className="profile-detail">
            </Col>
            
                {
                    showLink ? 
                        <Col>
                            <LinkContainer to={`/company_profiles/${profile.fields.slugname}`}>
                                <Button>Learn More</Button>
                            </LinkContainer>
                        </Col>
                    : null
                }
            
        </Row>
    )
}

export default CompanyTeaserLine
