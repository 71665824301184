import '../../../styles/layout/_layout.scss';

function AIContentItem({message, role}){
    
    return <>
        {message.text&&
            <div>{
                role==='user'?
                <strong>{message.text}</strong>
                :<>{message.text}</>
                }
            </div>
        }
        {
            message.file_contents&&
                <img alt='Not found' className='ai-picture' src={`data:image/png;base64, ${message.file_contents}`}/>
        }
        </>
}

export default AIContentItem;