import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import BreadCrumbBar from '../Layout/Nav/BreadCrumbBar';
import marketActionsPreviewImage from '../../assets/market-actions-cover.png';
import MarketActionsListView from './MarketActionsListView';
import utils from '../../utils';
import MarketActionsSummaryPanel from './MarketActionsSummaryPanel';
import ChartSettingsPanel from './ChartSettingsPanel';
import { PieChartFill } from 'react-bootstrap-icons';
import MarketAndYearFilterPanel from './MarketAndYearFilterPanel';
import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import CONSTANTS from '../../constants';

function MarketActionsApp({ tenantSetting, preview = false, tenantApp }) {

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();


  let [markets, setMarkets] = useState();
  let [loading, setLoading] = useState(true);

  let [selMarketList, setSelMarketList] = useState([]);
  let [selSummaryYears, setSelSummaryYears] = useState([]);

  let [summaryYears, setSummaryYears] = useState({});
  let [summaryMkts, setSummaryMkts] = useState({});

  let [showEdit, setShowEdit] = useState(false);


  const request = {
    scopes: ["User.Read"]
  };

  let breadCrumbName = utils.findURLPath(tenantSetting.tenant.fields.apps, "market_actions")

  const getAccessToken = async (instance, account) => {
    try {
      let theAccToken = await instance.acquireTokenSilent({ ...request, account: account });
      return theAccToken;
    } catch (err) {
      //TODO: Put a message in toast
      console.log(err);
    }
  }

  const getIDOfMarketByName = (mktName, marketSrc) => {
    
    let foundMktArray = marketSrc.lists.filter((thisMkt) => {
      return thisMkt.name.includes(mktName);
    });

    if (foundMktArray.length > 0) {
      return foundMktArray[0].id;
    }

    return null
  }

  //get tenant's market action space ID
  const getMarketActionsSpace = (tenant) => {
    let ma_space_id;

    let actionsApp = tenant.fields.apps.filter((item) => {
      return item.fields.apptype === 'market_actions';
    });

    if (actionsApp.length > 0) {
      ma_space_id = actionsApp[0].fields.appSpecificDataField ? actionsApp[0].fields.appSpecificDataField : null;
    }

    return ma_space_id;
  }

  const getMarketActions = async (accToken) => {

    let param = `uid=${tenantSetting.ourUserID}&config=true&spc_id=${getMarketActionsSpace(tenantSetting.tenant)}${preview === null || preview === false ? '&full_app=true' : ''}`;

    let response = await fetch(`${process.env.REACT_APP_WEB_API}/api/market-actions?${param}`, {
      headers: {
        "Authorization": "Bearer " + accToken.accessToken
      }
    });

    let jsonresponse = await response.json();

    setMarkets(jsonresponse);
  }

  const handleSelectedMarketsInLists = (mktsSelList) => {
    setSelMarketList(mktsSelList);
  }

  const updateChart = () => {
    //set filters
    let yrs = convertObjToArray(summaryYears);

    setSelSummaryYears(yrs);

    let mkts = convertObjToArray(summaryMkts);

    let mktsIDs = mkts.map( (thisSumMkt) => {
      return getIDOfMarketByName(thisSumMkt, markets);
    })

    setSelMarketList(mktsIDs);

    setShowEdit(false);
  }

  const convertObjToArray = (stateObj) => {

    let retArray = [];

    for (const thisprop in stateObj) {
      if (stateObj[thisprop] === true) {
        retArray.push(thisprop);
      }
    }

    return retArray;
  }


  const addToState = (stateObj, newItem) => {
    let cpyState = { ...stateObj };

    cpyState[newItem] = true;

    return cpyState;
  }

  const removeFromState = (stateObj, itemToRemove) => {
    let cpyState = { ...stateObj };

    if (stateObj.hasOwnProperty(itemToRemove)) {
      delete cpyState[itemToRemove];
    }

    return cpyState;
  }

  const setTheFilters = (newObj, which) => {

    //newobj = { 'Select All': true} or { '2020': true }

    //look for select-all, in which case, pull in all

    for (const thisProp in newObj) {
      if (newObj[thisProp] === false) {
        if (which === 'markets-selector') {

          //markets remove
          let newStateObj = removeFromState(summaryMkts, thisProp);
          setSummaryMkts(newStateObj);
        } else {

          //years remove
          let newStateObj = removeFromState(summaryYears, thisProp);
          setSummaryYears(newStateObj);
        }
      } else {
        if (which === 'markets-selector') {

          //market add
          let newStateObj = addToState(summaryMkts, thisProp);
          setSummaryMkts(newStateObj);
        } else {

          //years add
          let newStateObj = addToState(summaryYears, thisProp);
          setSummaryYears(newStateObj);
        }
      }
    }
  }

  const summaryFilterSet = (info) => {
    let what = info.from;

    delete (info.from);
    setTheFilters(info, what)
  }

  useEffect(() => {
    try {
      (async () => {
        setLoading(true);
        await getAccessToken(instance, accounts[0]);
        let accToken = await getAccessToken(instance, accounts[0]);
        getMarketActions(accToken);
        setLoading(false);
      })()
    } catch (err) {
      //TODO:  put a message in toast
      console.log(err);
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [isAuthenticated])

  return (
    <div className="market-actions-display">

      {
        !loading && markets ?
          !preview ?
            <>
              <BreadCrumbBar
                appName={breadCrumbName}
              />
              <h2 className='m-b-end-08'>{CONSTANTS.APPS_BY_NAME[breadCrumbName.toUpperCase()]}</h2>

              <ChartSettingsPanel
                header={<h3><PieChartFill /> Summary</h3>}
                show={showEdit}
                onOpen={() => { setShowEdit(true) }}
                onClose={() => { updateChart() }} 
              >

                <MarketAndYearFilterPanel
                  onSet={summaryFilterSet}
                  marketList={markets}
                  selMkts={summaryMkts}
                  selYears={summaryYears} 
                />

              </ChartSettingsPanel>

              <MarketActionsSummaryPanel
                markets={markets}
                marketSelections={selMarketList}
                yearsSelections={selSummaryYears} 
              />

              <MarketActionsListView
                tenant={tenantSetting}
                content={markets}
                spaceID={getMarketActionsSpace(tenantSetting.tenant)}
                onMarketsSelected={handleSelectedMarketsInLists}
              />

            </>
          : <div className='preview-img-div'>
              <Image className='preview-card-img' src={tenantApp.fields.previewImage.url ? tenantApp.fields.previewImage.url : marketActionsPreviewImage} />
            </div>
        : <ListSkeletonLoader preview={preview} />
      }
    </div>
  )
}

export default MarketActionsApp;