import PMTformatUtils from "../../PMTformatUtils";

const pmtutils = {
    getMultiArrayObj: (field, val, id=null) => {
        switch(field) {
            case 'customField_3':
                let retArr = val.map((item) => {
                    let obj = {}
                    obj.hmf_category = item;
                    if(id) {
                        obj.intervention_id=id;
                    }
                    return obj;
                });
                return retArr;
            default:
            break;
        }
    },
    //Will add more cases as add apps
    //finds the correct formater to send the form data to to reformat to what is expected in the backend
    sendToFormatter: (app, formItem, user) => {
        switch (app) {
            case "PMT MA":
                let infoToSend =PMTformatUtils.deformatMarketActions(formItem, user);
                return infoToSend;
            default:
             break;
        }
    }
}

export default pmtutils;