import React, {useEffect, useState} from 'react'
import { Form, Table, Button, Pagination } from 'react-bootstrap'
import Select from 'react-select';

import EnumEditor from './EnumEditor'
import EnumDeleter from './EnumDeleter'
import utils from '../../../utils'
import '../../../styles/layout/_layout.scss';
import TableHeader from '../TableHeader';

function EnumSearch(props) {
  // Raw api response
  const [apiEnums, setApiEnums] = useState([])
  // Local representation with data augmentations
  const [enums, setEnums] = useState([])
  // The filtered products displayed on the current page
  const [filteredEnums, setFilteredEnums] = useState([])
  const [sortedEnums, setSortedEnums] = useState([])
  const [pageEnums, setPageEnums] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageNumbers, setPageNumbers] = useState([])
  // Support for filtering
  const [enumNameOptions, setEnumNameOptions] = useState([])
  const [enumNameFilter, setEnumNameFilter] = useState(null)
  const [enumTypeFilter, setEnumTypeFilter] = useState({label:'antigen', value:'antigen'})
  const [synonymOptions, setSynonymOptions] = useState([])
  const [synonymFilter, setSynonymFilter] = useState(null)
  // Enum that gets edited when double click happens
  const [e, setE] = useState(null)
  // A toggle for showing the edit product modal
  const [showEditEnum, setShowEditEnum] = useState(false)
  const [showDeleteEnum, setShowDeleteEnum] = useState(false)
  const [xferMessage, setXferMessage] = useState()
  // Automatic trigger to refetch the data whenever the user is done editing the data.
  // This is automatically triggered by switching the t/f variable for showing the editing modal to false
  async function fetchData() {
    setPageEnums([])
    let result = await utils.getData("get_enums", props.apiKey, '?enum_type='+enumTypeFilter.value+'&include_deleted=True')
    if (result !== null) {
      setApiEnums(result);
    }
  }
  
  useEffect(() => {
    if(showEditEnum === false && showDeleteEnum === false){
      fetchData()
    }
    // eslint-disable-next-line
  },[showEditEnum, showDeleteEnum, enumTypeFilter]
  )

  async function refreshSearchIndex(){
    setXferMessage("Working...")
    let result = await utils.getData("refresh_search_index", props.apiKey)
    if(result){
      setXferMessage("Done")
    } else{
      setXferMessage("Error!")
    }
  }

  // Whenever there is a change to the api input, automatically trigger the data augmentations
  useEffect(() => {
    const temp = apiEnums.map(obj=> ({ ...obj, 
      synonyms: obj.alt_enums.map(a => {return a.alt_enum}) }))
    setEnums(temp)
    setFilteredEnums(temp)
  },[apiEnums])

  function createSortedOptions(tempList){
    tempList = [...new Set(tempList)]
    tempList = tempList.map((tl) => {return {value: tl, label: tl!==null?tl:"<none>"}})
    tempList.sort((a, b)=> utils.createStrSortOrder(a.label, b.label))
    return tempList
  }
  useEffect(()=>{
    var tempList
    tempList = filteredEnums.map(e => {return e.enum})
    tempList = createSortedOptions(tempList)
    setEnumNameOptions(tempList)

    // Get the flattened list of all synonyms associated with all filtered enums
    tempList = filteredEnums.map(e => e.synonyms.map(synonym => 
      {return synonym})).flat()
    // Convert to set to remove duplicates
    tempList = createSortedOptions(tempList)
    setSynonymOptions(tempList)
    
    var tempPageNums = []
    for (var i =1; i<=Math.ceil(filteredEnums.length/20); i++){
      tempPageNums.push({value:i, label:i})
    }
    setPageNumbers(tempPageNums)
  },[filteredEnums])

  // Reset temp products when a filter changes or the list of products itself changes
  useEffect(() => {
    function filterEnums(){
      const synonym1 = synonymFilter !== null && 
      synonymFilter.length >= 1 ? synonymFilter[0].value : null
      const synonym2 = synonymFilter !== null && 
      synonymFilter.length >= 2 ? synonymFilter[1].value : null
      const synonym3 = synonymFilter !== null && 
      synonymFilter.length >= 3 ? synonymFilter[2].value : null
      const synonym4 = synonymFilter !== null && 
      synonymFilter.length >= 4 ? synonymFilter[3].value : null
      const synonym5 = synonymFilter !== null && 
      synonymFilter.length >= 5 ? synonymFilter[4].value : null
      const tempEnums = enums.filter(
        (e => 
          (enumNameFilter === null || e.enum === enumNameFilter.value) &&
          (enumTypeFilter === null || e.enum_type === enumTypeFilter.value) &&
          (synonym1 === null || e.synonyms.includes(synonym1)) &&
          (synonym2 === null || e.synonyms.includes(synonym2)) &&
          (synonym3 === null || e.synonyms.includes(synonym3)) &&
          (synonym4 === null || e.synonyms.includes(synonym4)) &&
          (synonym5 === null || e.synonyms.includes(synonym5)) 
        )
      )
      setFilteredEnums( tempEnums )
    };
    filterEnums()
    },[enumNameFilter, enumTypeFilter, synonymFilter, enums]
  )

  //Reset page number ONLY when filters change
  useEffect(()=>{
    setPageNumber(1)
  }, [enumNameFilter, enumTypeFilter, synonymFilter])

  useEffect(() =>{
    function sortEnums(){
      var temp;
      const tempFilteredVaccines = [...filteredEnums]
      temp = tempFilteredVaccines.sort((a, b) => utils.createStrSortOrder(
        a.enum.toLowerCase(),
        b.enum.toLowerCase()))
      setSortedEnums(temp)
    }
    sortEnums()
  }, [filteredEnums])


  useEffect(() =>{
    function updatePageEnums() {
      setPageEnums(sortedEnums.slice((pageNumber-1)*20, pageNumber*20))
    }
    updatePageEnums()
  }, [pageNumber, sortedEnums])

  function clearFilters() {
    setEnumNameFilter(null)
    setSynonymFilter(null)
  }

  function printState (){
    console.log('enums', enums)
    console.log('filtered enums', filteredEnums)
    console.log('page enums', pageEnums)
  };
  

  // Under review whether we need this
  function createNew(){
    setShowEditEnum(true)
    setE(null)
  }
  
  function showEditor(e){
    setShowEditEnum(true)
    setE(e)
  }

  function showDeleter(e){
    setShowDeleteEnum(true)
    setE(e)
  }

  async function unDelete(i){
    const tempEnum = pageEnums[i]
    const confirmation = window.confirm("Are you sure you want bring back "+tempEnum.enum+"?")
    if (confirmation === false) {
      return
    }
    tempEnum.deleted = false
    tempEnum.replacement_id = null
    await utils.upsert('update_enum', props.apiKey, tempEnum)
    await fetchData()
  }
  
  const enumTypeOptions = [
    {value: 'antigen', label: 'antigen'},
    {value: 'development_phase', label: 'development_phase'},
    {value: 'form', label: 'form'},
    {value: 'platform', label: 'platform'},
    {value: 'route_of_administration', label: 'route_of_administration'},
  ]

  return (
    <div>
      {showEditEnum ?
        <EnumEditor enum = { e }
        enumType = {enumTypeFilter.value}
        closePopup = {() => setShowEditEnum(false) }
        show = {showEditEnum}
        apiKey = {props.apiKey}
        />  
        : null  
      }
      {showDeleteEnum ?
        <EnumDeleter enum = { e } 
        closePopup = { () => setShowDeleteEnum(false) }
        show = {showDeleteEnum}
        apiKey = {props.apiKey}
        />  
        : null  
      }
      <Form>
        <Button onClick={printState} className='surface-primary'>Print State</Button>{' '}
        {enumTypeFilter.value !== "vaccine_subtype"?
          <Button onClick={createNew} className='surface-primary'>Create New</Button>
          :null}{' '}
        <Button onClick = {refreshSearchIndex} className='surface-primary'>Refresh Search</Button>{' '}
        <Button onClick={clearFilters} className='surface-primary'>Clear Filters</Button>
        <div>{xferMessage}</div>
        <Table hover size = "sm">
          <thead className="table-heading">
            <tr>
              <TableHeader
                title='LB Word'
                options = { enumNameOptions }
                value = { enumNameFilter}
                onChange={(e) => setEnumNameFilter(e)}
                isClearable = {true}
              />
              <TableHeader
                title='Word Type'
                options = { enumTypeOptions }
                value = { enumTypeFilter}
                isClearable={false}
                onChange={(e) => setEnumTypeFilter(e)}
              />
              <TableHeader
                title='Replacement'
              />
              <TableHeader
                title='Synonyms'
                options = {synonymOptions}
                isMulti
                value = { synonymFilter }
                onChange={(e) => setSynonymFilter(e)}
                isClearable = {true}
              />
              <TableHeader
                title='Delete'
              />
            </tr>
          </thead>
          <tbody className ="table-body">
            {pageEnums.map((e, i) => {
              return <tr className={e.deleted?"deleted":null} key = {i} onDoubleClick={() => showEditor(e, i)} >
                <td>{e.enum}</td>
                <td>{e.enum_type}</td>
                <td>{e.replacement_id!== null?e.replacement.enum: ''}</td>
                <td>{e.alt_enums.map(ae => { 
                  return ae.alt_enum}).join(", ")}
                </td>
                <td className='right-align'>
                {e.deleted?
                  <Button 
                    variant="success" 
                    size = "sm"
                    onClick = {() => unDelete(i)}
                  >+</Button>:
                  <Button 
                    variant="danger" 
                    size = "sm"
                    onClick = {() => showDeleter(e, i)}
                  >-</Button>
                }
                </td>
              </tr>
              
              })}
           </tbody>
        </Table>
        <Pagination>
          <Pagination.Prev onClick = {() => setPageNumber(pageNumber-1)}
            disabled = {pageNumber <= 1}/>
          <Select 
            className="select-sm"
            options={pageNumbers} 
            value = {{value:pageNumber, label:pageNumber}} 
            onChange={(e)=>setPageNumber(e.value)}/>
          <Pagination.Next onClick = {() => setPageNumber(pageNumber+1)} 
          disabled = {pageNumber >= Math.ceil(filteredEnums.length / 20)}/>
          
        </Pagination>
        <Form.Text>Page Number: {pageNumber}</Form.Text>
      </Form>
    </div>
    
  )
}

export default EnumSearch;