import utils from "../../utils";

const PMTCONSTANTS = {

    STATUS_LIST: [
        "Not started",
        "In progress",
        "Delayed",
        "Deprioritized",
        "Complete"
    ],

    PRIORITY_LIST: [
        "Not specified",
        "High",
        "Medium",
        "Low"
    ],
    LIST_ITEM_NAME: {
       'PMT MA': 'Intervention',
    },
    LIST_NAME: {
        'PMT MA': 'Market',
    },
    PMT_APPS: {
        'PMT MA': 'pmt_ma',
    },
  
    'PMT MA': { 
        CUSTOM_KEYS: {
            customField_1: 'Objective',
            customField_2: 'Target Outcome',
            customField_3: 'HMF Category',
            customField_4: 'Supplier',
            customField_5: 'Stakeholder Accountable',
            customField_6: 'Stakeholder Supporting',
            customField_7: 'Due Date Type',
            customField_8: 'Tags',
            customField_9: 'Vaccine Roadmap'
        },
        CUSTOM_FIELD_NAME: {
            customField_1: 'objective',
            customField_2: 'target_outcome',
            customField_3: 'hmf_category',
            customField_4: 'supplier',
            customField_5: 'stakeholder_accountable',
            customField_6: 'stakeholder_supporting',
            customField_7: 'due_date_type',
            customField_8: 'tags',
            customField_9: 'vaccine_roadmap'
        },
        ARCHIVE_MODAL: {
            type: 'archive',
            title: utils.getTitle("You're about to archive this intervention"),
            body: 'Archiving this intervention will remove it from other views. Are you sure you want to continue?',
            app: 'market actions',
            subName: 'Confirm',
        },
        GET_LIST: 'get_ma_interventions',
        UPSERT_ITEM: 'upsert_ma_intervention',
        ID_PARAM: 'intervention_id',
        CUSTOM_FIELD_3_OPTIONS: [
            'Demand',
            'Supply',
            'Innovation'
        ]
    }  
};
 export default PMTCONSTANTS;