import React, { useEffect, useState } from 'react';  
import { Form, Button, ButtonGroup, ButtonToolbar, Modal, Col, Row} from 'react-bootstrap'
import _ from 'lodash';

import ScheduleRoundEditor from './ScheduleRoundEditor';
import ObjectSelector from '../ObjectSelector';
import SimpleSelect from '../SimpleSelect';
import EnumSelector from '../EnumSelector';
import CONSTANTS from "../../../constants"
import utils from "../../../utils"
import '../../../styles/layout/_layout.scss'
import ConfirmationContent from '../ConfirmationContent';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import RelatedDataTable from '../RelatedDataTable';
import { useToast } from '../../ToastProvider';


function ProgramEditor({programId, closePopup, show, apiKey, userName, appClass, toastClick, getFromLocalStore}) {
  const [program, setProgram] = useState(null)
  const [wastages, setWastages] =useState([])
  const [productCharacteristics, setProductCharacteristics] = useState(null)
  const [errors, setErrors] = useState([])
  const [coverages, setCoverages] = useState([])
  const [uptake, setUptake] = useState(null)
  const [geographyId, setGeographyId] = useState(null)
  const [routine, setRoutine] = useState(null)
  const [gender, setGender] = useState(null)
  const [vaccineGroup, setVaccineGroup] = useState(null)
  const [programYears, setProgramYears] = useState([]);
  const [showRelatedActivities, setShowRelatedActivities] = useState(false);
  const [relatedActivities, setRelatedActivities] = useState([]);
  const [ currentView, setCurrentView] = useState('editor');
  const { addToast } = useToast();

  useEffect(()=>{
    async function getProgram(){
      var result
      if (getFromLocalStore){
        result = localStorage.getItem('errorProgram');
        result = JSON.parse(result);
      }  else if (programId === null){
        result = CONSTANTS.SHELL_PROGRAM;
      } else{
        result = await utils.getData("get_program", apiKey, "?program_id="+programId)
      }
      if (typeof result === 'string'){
        addToast({
          title: 'Failed to load data. Error code 3-a',
          body: result
        })
        closePopup();
      }else if(result !== null) {
        setProgram(result)
        setGender(result.gender)
        setRoutine(result.routine)
        setGeographyId(result.geography_id)
        setVaccineGroup(result.vaccine_group)
      }
      const wastageResult = await utils.getData("get_wastages", apiKey)
      if (typeof wastageResult ==='string'){
        addToast({
          title: 'Failed to load data. Error code 3-b',
          body: wastageResult
        })
        closePopup();
      }else{
        setWastages(wastageResult)
      }
    }
    getProgram()
    // React 18 ready
    return () => { };
    // eslint-disable-next-line
  },[programId, apiKey, getFromLocalStore])

  function sendToLocalStorage(){
    localStorage.setItem('errorProgram', JSON.stringify(program));
  }

  useEffect(() => {
    if (showRelatedActivities === false){
      return;
    }
    const fetchData = async () => {
      if (!apiKey || !program || !program.vaccine_group || !program.geography_id) {
        return
      }
      const response = await utils.getData('get_related_activities', apiKey, 
          '?vaccine_group=' + program.vaccine_group+'&geography_id='+program.geography_id)
      if (typeof response === 'string') {
        sendToLocalStorage();
        addToast({
          title: 'Failed to load data. Error code 3-c',
          body: response,
          onClick:toastClick,
          buttonText:"Reopen program?"
        });
        closePopup();
      } else {
        const formattedResponse = response.map((r)=>{return {...r, target_population:utils.formatNumberWithCommas(r.target_population)}});
        setRelatedActivities(formattedResponse);
      }
    }
    fetchData()
    // React 18 ready
    return () => { };
    // eslint-disable-next-line
  }, [apiKey, program, showRelatedActivities])

  // Recompute the min year and max year whenever the program changes
  useEffect(()=>{
    if (!program){
      return
    }
    var minYear = 3000;
    var maxYear = 0;
    for (var i=0; i<program.schedule_rounds.length; i++){
      var sr = program.schedule_rounds[i];
      for (var j=0; j<sr.product_selections.length; j++){
        var ps = sr.product_selections[j];
        var startYear = Math.max(ps.start_year, 2000)
        if (startYear < minYear){
          minYear = startYear
        }
        if (ps.end_year >maxYear){
          maxYear=ps.end_year
        }
      }
    }
    setProgramYears(_.range(minYear,maxYear+1))
    // React 18 ready
    return () => { };
  },[program])

  useEffect(() => {
    async function refreshData(){
      if (geographyId === null){
        return
      }
      const uptakeResult = await utils.getData("get_uptake_curve",
          apiKey, 
          "?geography_id="+geographyId)
      if (typeof uptakeResult === 'string'){
        addToast({
          title: 'Failed to load data. Error code 3-d',
          body: uptakeResult
        });
        closePopup();
      }else{
        setUptake(uptakeResult)
      }
      const coverageResult = await utils.getData("get_coverages", 
          apiKey, 
          "?geography_id="+geographyId)
      if (typeof coverageResult === 'string'){
        addToast({
          title: 'Failed to load data. Error code 3-e',
          body: uptakeResult
        });
        closePopup();
      }else{
        setCoverages(coverageResult)
      }
    }
    refreshData()
    // React 18 ready
    return () => { };
  }, [apiKey, geographyId, closePopup, addToast])

  useEffect(() => {
    async function refreshProductCharacteristics(){
      if (!vaccineGroup){
        return
      }
      const characteristicResult = await utils.getData("get_product_characteristics",
                        apiKey,"?vaccine_group="+vaccineGroup)
      if (typeof characteristicResult === 'string'){
        addToast({
          title: 'Failed to load data. Error code 3-e',
          body: characteristicResult
        });
        closePopup();
      }else{
        setProductCharacteristics(characteristicResult)
      }
    }
    refreshProductCharacteristics()
    // React 18 ready
    return () => { };
  },[vaccineGroup, apiKey, closePopup, addToast])
  
  /*-----------------Form Submission-----------------------*/


  // preSubmit - checking for errors before confirmation page
  async function preSubmit (e){
   // const data = program
    e.preventDefault();
    e.stopPropagation();
    let submissionErrors = new Set()

    for (let sr of program.schedule_rounds){
      for (let ps of sr.product_selections){
        // Can't use !ps.min_age because it might be 0
        if (ps.min_age === null || ps.min_age === undefined){
          submissionErrors.add("At least one product selection has no minimimum age")
        }
        if (ps.max_age === null || ps.max_age === undefined){
          submissionErrors.add("At least one product selection has to maximum age")
        }
        // This is a UUID or null so ! works.
        if (!ps.product_characteristic_id){
          submissionErrors.add("At least one product selection has no product selected")
        }
      }
    }
    submissionErrors = Array.from(submissionErrors)
    setErrors(submissionErrors)
    if (submissionErrors.length > 0)  {
      return
    }
    setCurrentView('confirmation')
  }

// Final Submit - used on Confirmation Page
async function finalSubmit({ updateNotes, updateSource, updatedBy }) {
  const submissionDetail = {
      ...program,
      update_notes: updateNotes, 
      update_source: updateSource,
      updated_by: updatedBy,
  };

  const result = await utils.upsert("upsert_program", apiKey, submissionDetail)
  if (typeof result === 'string') {
    sendToLocalStorage();
        addToast({
          title: 'Failed to load data. Error code 3-a',
          body: result,
          onClick:toastClick,
          buttonText:"Reopen program?"
        });
        closePopup();
  }else{
    closePopup()
  }
}

  // Check Program
  function checkProgram(){
    console.log(program)
  }


// go back button goes from confirmation page back to the editor view
const handleGoBack = () => {
  setCurrentView('editor');
}


// Confirmation Page Header
function renderProgramDetails(program) {
  return (
    <div>
    <div><strong>Target Demographic: </strong>{program.target_demographic}</div>
    <div><strong>Routine/Campaign: </strong>{program.routine === true ? 'Routine' : 'Campaign'}</div>
    <div><strong>Vaccine Group: </strong>{program.vaccine_group}</div>
    <div><strong>Geography: </strong>{program.geography.geography_name}</div>
    <div><strong>Gender: </strong>{program.gender}</div>
  </div>
  );
}


  function addScheduleRound(){
    setProgram({...program, schedule_rounds:[...program.schedule_rounds,
      {
        'dose_sequence':program.schedule_rounds.length+1,
        'coverage':null,
        'product_selections':[],
        'trs':[]
      }
    ]})
  }

  function moveProductSelection(from_sr, from_ps, to_sr){
    const prodSelCopy = _.cloneDeep(program.schedule_rounds[from_sr-1].product_selections[from_ps])
    // Unassociate the product selection copy with its existing schedule round.
    // delete prodSelCopy.schedule_round_id;
    const temp = program.schedule_rounds;
    temp[to_sr-1].product_selections.push(prodSelCopy);
    temp[from_sr-1].product_selections.splice(from_ps,1);
    setProgram({...program,
      schedule_rounds:temp
    });
  }

  function deleteScheduleRound(i){
    let temp = program.schedule_rounds
    temp.splice(i, 1)
    for (let k = i;  k<temp.length; k++){
      temp[k].dose_sequence--
    }
    setProgram({...program, schedule_rounds: temp})
  }
  return (
    <Modal show={show} dialogClassName='modal-90w' className={appClass}>
      <Modal.Header>
        <Modal.Title>Program Editor</Modal.Title>
      </Modal.Header>
      <div className='scrollable'>
          <Modal.Body>
          {program && currentView === 'editor' && (
        <>
            <Row>
              <Col sm={4}>
                <div className='input-text'>Target Demographic</div>
                <input
                  className='form-ctrl'
                  type="text"
                  value={program.target_demographic}
                  onChange={(e) => setProgram({ ...program, target_demographic: e.target.value })} />
              </Col>
              <Col sm={4}>
                <div className='input-text'>Routine/Campaign</div>
                <SimpleSelect
                  options={['Routine', 'Campaign']}
                  value={program.routine === true ? 'Routine' : 'Campaign'}
                  onChange={(e) => {
                    program.routine = e === 'Routine'
                    setRoutine(e === 'Routine')
                  }}
                  isClearable={false}
                  className='select'
                />
              </Col>
              <Col sm={2}>
                <div className='input-text'>Deleted</div>
                <Form.Check
                  type='checkbox'
                  checked={program.deleted}
                  onChange={()=>{
                    // Transition from true to false is ok.
                    if (program.deleted === true 
                        || window.confirm("Are you sure you want to delete this program?")){
                          setProgram({...program, deleted:!program.deleted})
                    }
                }}
                />
              </Col>
              <Col sm={2}>
                <Button className='surface-primary' onClick = {()=>setShowRelatedActivities(true)}>See Related Activites</Button>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <div className='input-text'>Vaccine Group</div>
                <EnumSelector
                  value={program.vaccine_group}
                  className={null} // The default class name works pretty well here
                  enum_type='vaccine_group'
                  apiKey={apiKey}
                  onChange={(e) => {
                    setProgram({ ...program, vaccine_group: e.label })
                    setVaccineGroup(e.label)
                  }}
                />
                <div>
                  <strong>
                    {program.vaccine_group ==='Covid-19'? 'Vaccinations will reoccur anually based on this vaccine group':null}
                  </strong>
                </div>
              </Col>
              <Col sm={4}>
                <div className='input-text'>Geography</div>
                <ObjectSelector
                  apiKey={apiKey}
                  onChange={(e) => {
                    setProgram({ ...program, geography_id: e.geography_id, geography: e })
                    setGeographyId(e.geography_id)
                  }}
                  obj={program.geography}
                  type = 'geography'
                />
                                
              </Col>
              <Col sm={4}>
                <div className='input-text'>Gender</div>
                <EnumSelector
                  value={program.gender}
                  className={null} // The default class name works pretty well here
                  enum_type='gender'
                  apiKey={apiKey}
                  onChange={(e) => {
                    setProgram({ ...program, gender: e.label })
                    setGender(e.label)
                  }}
                />
              </Col>
            </Row>
            {program.schedule_rounds.map((sr, i) => {
                  return <ScheduleRoundEditor
                    productCharacteristics={productCharacteristics}
                    coverages={coverages}
                    wastages={wastages}
                    apiKey={apiKey}
                    scheduleRound={sr}
                    routine={routine}
                    gender={gender}
                    uptake={uptake}
                    noSchedRounds={program.schedule_rounds.length}
                    geography={program.geography}
                    vaccineGroup={program.vaccine_group}
                    deleteRound={() => deleteScheduleRound(i)}
                    onMove={moveProductSelection}
                    programYears={programYears}
                    onChange={(e) => {
                      const temp = [...program.schedule_rounds]
                      temp[i] = e
                      setProgram({ ...program, schedule_rounds: temp })
                    }
                    }
                  />}
                )
              }
            <Row>
              <Col>
                <Button 
                  onClick={addScheduleRound}
                  disabled={geographyId===null || vaccineGroup ===null}
                >Add Round</Button>
              </Col>
            </Row>
            <Row className = 'padded-row'>
              {errors.length > 0 ?
                <div>
                  <div className="error">Errors:</div>
                  {
                    errors.map((e) => <li className="error">{e}</li>)
                  }
                </div>
                : null}
            </Row>
            <ButtonToolbar className='editor-btn m-b-end-05'>
              <ButtonGroup>
                <Button onClick={closePopup} variant="secondary" className='button-secondary m-i-end-03'>Close</Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={preSubmit} className='btn-primary m-i-end-03'>Next Step<ArrowRightCircle /></Button>{' '}
              </ButtonGroup>
              <ButtonGroup>
                <Button onClick={checkProgram}className='btn-primary'>Check Program</Button>
              </ButtonGroup>
            </ButtonToolbar>
        </>
        )}
        {program && currentView === 'confirmation' && (
        <ConfirmationContent
          apiKey={apiKey}
          updatedBy={userName}
          updateNotes={program.update_notes}
          updateSource={program.update_source}
          onSubmit={({ updateNotes, updateSource }) => finalSubmit({ updateNotes, updateSource, updatedBy: userName })}
          onGoBack={handleGoBack}
          renderContentSection={() => renderProgramDetails(program)}
        />
      )}
      {
        showRelatedActivities&&
        <RelatedDataTable
          data={relatedActivities}
          closePopup={()=>setShowRelatedActivities(false)}
          title='Related CE Activities'
        />
      }
      </Modal.Body>
      </div>
    </Modal>
  )
}

export default ProgramEditor;