import { Row, Col } from "react-bootstrap"

import AIContentItem from "./AIContentItem";
import '../../../styles/layout/_layout.scss';


function AIContent({turn}) {
    return  <Row>
            {turn.role==='assistant'&&<Col xs={2}/>}
            <Col>
                {turn.messages.map((m)=> {return <AIContentItem message={m} role={turn.role}/>})}
            </Col>
            {turn.role==='user'&&<Col xs={2}/>}
        </Row>
}

export default AIContent