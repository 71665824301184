import React from 'react';
import { Row, Col } from 'react-bootstrap';
import VizUtils from '../../VizUtils';
import PBIEmbeddedViz from '../PBIEmbeddedViz';


function OverviewByYearPanel({ fields, navItem }) {
    //this component will show the overview of the year in pbi

    const theYearTitle = navItem.fields && navItem.fields.itemTitle ? navItem.fields.itemTitle : new Date().getFullYear();

    //interpret the curYear as this year 

    let curYear = new Date().getFullYear();
    let yearToPass = curYear;

    if(theYearTitle.includes("-2")) {
      yearToPass = curYear-2;  
    }

    if(theYearTitle.includes("-1")) {
        yearToPass = curYear-1;  
    }



   //add the year filter into the navItems
    //    navfields.pBI_Filter: [
    //         {
    //             $schema: "http://powerbi.com/product/schema#basic",
    //             target: {
    //                 table: thisFilter.fields.targetTable,
    //                 column: thisFilter.fields.targetColumn
    //             },
    //             filterType: models.FilterType.BasicFilter,
    //             operator: thisFilter.fields.operator,
    //             values: this.replaceFieldRefWithValue(thisFilter.fields.filterValues, fields),
    //             requireSingleSelection: thisFilter.fields.requireSingleSelection ? thisFilter.fields.requireSingleSelection : false
    //         }
    //     ]

    
   if(navItem.fields.pBI_Filter) {
        navItem.fields.pBI_Filter = VizUtils.addValueToFilter(navItem)
        console.log("navItem.fields.pBI_Filter", navItem.fields.pBI_Filter)
   } else {
        navItem.fields = {
            ...navItem.fields,
            pBI_Filter: VizUtils.addValueToFilter(navItem)
        }
        console.log("navItem.fields.pBI_Filter", navItem.fields.pBI_Filter)
   }


    return (
        <>
            <Row>
                <Col>
                    <h3>{ yearToPass }</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <PBIEmbeddedViz {...VizUtils.getOptionsForPBI(navItem, fields)} />
                </Col>
            </Row>
        </>
    )
}

export default OverviewByYearPanel