import CONSTANTS from "../../constants";
import { getCode } from 'country-list';

const ircUtils = {
    getThreeLetter: (ctryName, countriesList) => {
        let adjCtryName = ctryName;
    
        if( CONSTANTS.COUNTRY_ADAPTER.toCE.hasOwnProperty(ctryName) ) {
            adjCtryName = CONSTANTS.COUNTRY_ADAPTER.toCE[ctryName];
        }
    
        return countriesList.find( (itemInList) => {
            //add transform back to our three letters here, ie: DRC / CAF / Cote d'Ivoire
            
            return itemInList.country === adjCtryName;
        });
    },


    createJVector: (countryList) => {
        let retObj = {};
    
        countryList.forEach( (itemInList) => {
    
            let countryName = itemInList.country;
    
            //check our adapter to see if this is a mismatched case
            if(CONSTANTS.COUNTRY_ADAPTER.toJvector.hasOwnProperty(itemInList.country)){
                countryName = CONSTANTS.COUNTRY_ADAPTER.toJvector[itemInList.country];
            } 
    
           retObj[getCode(countryName)] = 1;
        })
    
        return retObj;
      }

}

export default ircUtils;