import React, { useState, useEffect} from 'react';
import { Button, Modal, Table, ButtonGroup, ButtonToolbar, Row, Col } from 'react-bootstrap';

import '../../styles/layout/_layout.scss';
import utils from '../../utils';

function RelatedDataTable({data, closePopup, title}) {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [keys, setKeys] = useState([]);

  
  const updateDimensions = () => {
    setwindowWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
    // eslint-disable-next-line    
  }, []);

  useEffect(()=>{
    // I basically just did this use effect to make the code clearer
    // I cold have put Object.keys(data[0]) directly in a few places but
    // that seemed messy.
    if(data&&data.length>0){
      setKeys(Object.keys(data[0]));
    }
  },[data])


  /*-------------------------------Rendering--------------------------------*/
  return (
    <div>
      <div>
        <Modal dialogClassName='modal-90w' size='xl' show={true}>
          <Modal.Header>
            <Modal.Title className='product-name-header'>{title}</Modal.Title>
          </Modal.Header>
          
          <Modal.Body className='alc-product-modal'>
          {data && data.length > 0 && keys && keys.length > 0 ?
            <div className={data.length > 11 ? 'max-height-600 overflow-y-scroll' : windowWidth < 1200 ? 'overflow-x-scroll' : ''}>
              <Table size='sm'>
                <thead className='table-heading'>
                  <tr>
                    {keys.map((k) => {
                      return <th>{utils.snakeToTitleCase(k)}</th>
                    })}
                  </tr>
                </thead>
                <tbody className='table-body'>
                  {data.map((d) => {
                    return <tr>
                      {keys.map((k) => { return <td>{d[k]}</td> })}
                    </tr>
                  })
                  }
                </tbody>
              </Table>
            </div>
            : <Row><Col><div className='input-text'>Sorry, no data to display!</div></Col></Row>}
          <ButtonToolbar className='editor-btn'>
            <ButtonGroup>
              <Button className='btn-primary m-i-start-03 m-b-end-03' onClick={closePopup}>Close</Button>
            </ButtonGroup>
          </ButtonToolbar>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}  

export default RelatedDataTable;