import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { PlusCircle, ChevronDown } from 'react-bootstrap-icons';
import moment from 'moment';

import ListSkeletonLoader from '../Layout/ListSkeletonLoader';
import CheckBoxDropDownSelector from '../MktActionsFilterBarComps/CheckBoxDropDownSelector';
import MarketActionsExcelExport from '../MarketActions/MarketActionsExcelExport';
import CONSTANTS from '../../constants';
import PMTGanttView from './Ganntt/PMTGanttView';
import PMTTableView from './Table/PMTTableView';
import PMTKanbanView from './Kanban/PMTKanbanView';
import PMTListViewManipulator from './PMTListViewManipulator';
import ganttUtils from '../MarketActions/ganttUtils';
import PMTCreateEditItemPopup from './PMTCreateEditItemPopup';
import PMTNoItemInList from './PMTNoItemInList';
import PMTCONSTANTS from './pmtConstants';

function PMTDisplay({ tenant, memberList, suppliers, options, displayOptionHandler, apiKey, appName, handleReload, updateTopList, topList, loading, handleListUpdate }) {

    let [selectedListItems, setSelectedListItems] = useState([]);
    let [firstLoad, setFirstLoad] = useState(true);
    let [listViewOption, setListViewOption] = useState('2'); // '2' is Kanban
    let [sepByField, setSepByField] = useState();
    let [currentView, setCurrentView] = useState({
        'YEAR': new Date().getFullYear(),
        'MONTH': new Date().getMonth() + 1,
        'WEEK': Math.round(new Date().getDate() / 7),
        'QUARTER': ganttUtils.getCurrentQuarter(new Date())
    });
    let [zoom] = useState('QUARTERS');
    let [setFilteredList] = useState(topList);
    let [show, setShow] = useState(false);
    let [itemID, setItemID] = useState();

    const listViews = [
        { name: 'Table', value: '1' },
        { name: 'Kanban', value: '2' },
        { name: 'Gantt', value: '3' },
    ]

    const listViewObj = {
        "1": "Table",
        "2": "Kanban",
        "3": "Gantt"
    }

    useEffect(() => {
        if (topList && topList.length > 0 && firstLoad === true) {
            alphabetizedList(topList);
            setFirstLoad(false)
        }
        //eslint-disable-next-line
    }, [topList, firstLoad])

    let isDisabled = topList.length > 0 ? false : true;

    //Alphabetizes list 
    const alphabetizedList = (list) => {
        let alphaList = list.sort((a, b) => a.list_name < b.list_name);
        let listToFilter = alphaList[0].list_name;
        setSelectedListItems(listToFilter)
    }

    //Filters out only the first list item on load or the listNames that are selected.
    const filterToListsSelected = (list, selected) => {
        let retArr;
        let selectedItems = ['test market']
        let filteredArray = list.filter((listObj) => {
            return selectedItems.includes((listObj.list_name.toLowerCase()))
        });
        //filters to show testmarket only leave for trouble shooting
        // IMPORTANT: Add back once implemented to filter to the first alphabetized list name
        // let selectedItems = selected && selected.length > 0 ? selected : ['Test Market']
        // let filteredArray = list.filter((listObj) => selectedItems.includes((listObj.list_name)));
        // return filteredArray;
        //check if filters are applied if so display filtered data
        let listWithFilters = checkForFilters(filteredArray, options)

        if (listWithFilters && listWithFilters.length > 0) {
            retArr = listWithFilters;
        } else {
            retArr = filteredArray;
        }
        return retArr;
    }

    const resetList = () => {
        setFilteredList(topList);
    }

    const filterFullFilterLines = (filterList) => {
        //only return filter lines where the field, operator, and comparator have been specified
        return filterList.filter((filterLine) => {
            return ((filterLine.fieldForFilter !== null && filterLine.fieldForFilter !== '') &&
                (filterLine.operator !== null && filterLine.operator !== '') &&
                (filterLine.comparator !== null && filterLine.comparator !== ''));
        })
    }

    const handleShowChange = (e) => {
        setSepByField(e.value);
    }

    const filterItemByFilterItems = (list, filterList) => {
        //run the item through each filter
        let runningList = [...list];

        filterList.forEach((item) => {
            runningList = filterByItemFromFilterPopup(runningList, item);
        })
        return runningList;
    }

    const dateCompare = (date1, date2, operator) => {
        switch (operator) {
            case "is_on":
                return moment(date1).isSame(date2);
            case "is_before":
                return moment(date1).isBefore(date2);
            case "is_after":
                return moment(date1).isAfter(date2);
            default:
                break;
        }
    }

    const filterByItemFromFilterPopup = (list, item) => {
        //{ fieldForFilter, operator, comparator }

        //filtering is different based on comparator type
        switch (item.fieldForFilter) {

            case "Created":
                return list.filter((listItem) => {
                    const itemDate = new Date(parseInt(listItem.date_created));
                    const compDate = new Date(item.comparator);

                    return dateCompare(itemDate, compDate, item.operator);
                });

            case "Last Updated":
                //return list; 
                return list.filter((listItem) => {
                    const itemDate = new Date(parseInt(listItem.last_updated));
                    const compDate = new Date(item.comparator);
                    return dateCompare(itemDate, compDate, item.operator);
                });

            case "Intervention Title":
                return list.filter((listItem) => {
                    return textCompare(listItem.task_name, item.comparator, item.operator);
                });

            case "Assigned To":
                return list.filter((listItem) => {
                    let tempValue = '';

                    if (listItem.assigned_to !== null) {
                        tempValue = listItem.assigned_to;
                    }
                    let results = tempValue.toLowerCase().includes(item.comparator.toLowerCase());

                    if (item.operator === 'is_not_assigned_to') {
                        results = !results;
                    }

                    return results;
                });

            // Leaving for now not sure if needed
            //   case "HMF Category":
            //     return list.filter((listItem) => {
            //       let valToCompare = '';
            //       if (Array.isArray(listItem.hmf_category)) {
            //         valToCompare = listItem.hmf_category[0];
            //       } else {
            //         valToCompare = listItem.hmf_category;
            //       }

            //       let results = valToCompare === item.comparator;

            //       if (item.operator === 'does_not_equal') {
            //         results = results === true ? false : true;
            //       }

            //       return results;
            //     });

            case "Status":
                return list.filter((listItem) => {
                    return textCompare(listItem.status, CONSTANTS.MARKET_ACTIONS_STATUS[item.comparator], item.operator);
                });
            default:
                return list;
        }
    }

    const textCompare = (text1, text2, operator) => {
        switch (operator) {
            case "contains":
                return (text1.toLowerCase()).includes(text2.toLowerCase());
            default:
                return ((text1.toLowerCase()).includes(text2.toLowerCase()));
        }
    }

    const getEarliestFrameStart = (tasks) => {
        let LatestEndTask = ganttUtils.getTaskWithEarlieststart(tasks);
        let myDate = new Date();

        if (LatestEndTask.start_date) {
            myDate = new Date(parseInt(LatestEndTask.start_date));
            return {
                YEAR: myDate.getFullYear(),
                MONTH: myDate.getMonth() + 1,
                QUARTER: Math.floor(myDate.getMonth() / 4),
                WEEK: Math.round(myDate.getDate() / 7)
            }
        }
        return null;
    }

    const getLatestFrameStart = (tasks) => {
        let LatestEndTask = ganttUtils.getTaskWithLatestend(tasks);
        let myDate = new Date(parseInt(LatestEndTask.due_date));

        return {
            YEAR: myDate.getFullYear() - 1,
            MONTH: myDate.getMonth() + 2,
            QUARTER: Math.ceil(myDate.getMonth() / 4),
            WEEK: (Math.floor(myDate.getDate() / 7) + 1) <= 4 ? Math.round(myDate.getDate() / 7) + 1 : Math.abs((Math.round(myDate.getDate() / 7) + 1) - 4)
        }
    }

    const handleViewManipOptionChange = (srcObj) => {
        let appliedLines;
        resetList();

        switch (srcObj.src) {
            case 'FILTERBY':
                //make sure we have a full filter line before applying it to the list filtering
                appliedLines = filterFullFilterLines(srcObj.data);

                if (appliedLines.length > 0) {
                    setFilteredList(filterItemByFilterItems(topList, appliedLines));
                } else {
                    resetList();
                }
                break;
            case 'PAGER':
                if (zoom === 'YEARS') {
                    if (srcObj.data === 'rewind') {
                        //zoom level, year, rewind - set currentView -1 month
                        let newYear = currentView.YEAR - 1;
                        let newMonth = 1;

                        setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                    }

                    if (srcObj.data === 'full-rewind') {
                        //zoom level, year, full rewind - set currentView to start year of oldest task
                        //TODO - find earliest task and set year to that
                        let resp = getEarliestFrameStart(topList);

                        if (resp) {
                            setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }

                    if (srcObj.data === 'forward') {
                        //zoom level, year, forward - set currentView +1 month
                        let newYear = currentView.YEAR + 1;
                        let newMonth = 1;

                        setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                    }

                    if (srcObj.data === 'full-forward') {
                        //zoom level, year, full forward - set currentView to end year of oldest task
                        //TODO - set year of last end date
                        let resp = getLatestFrameStart(topList);

                        if (isNaN(resp.YEAR)) {
                            let newYear = currentView.YEAR + 1;
                            let newMonth = 1;

                            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                        } else {
                            setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }
                }

                if (zoom === 'QUARTERS') {
                    if (srcObj.data === 'rewind') {
                        //zoom level, year, rewind - set currentView -1 month
                        let newQuarter = currentView.QUARTER - 1;
                        let newYear = currentView.YEAR;

                        if (newQuarter < 1) {
                            newQuarter = 4;
                            newYear = currentView.YEAR - 1;
                        }
                        setCurrentView({ ...currentView, QUARTER: newQuarter, YEAR: newYear });
                    }

                    if (srcObj.data === 'full-rewind') {
                        //zoom level, year, full rewind - set currentView to start year of oldest task
                        //TODO - find earliest task and set year to that
                        let resp = getEarliestFrameStart(topList);

                        if (resp) {
                            setCurrentView({ YEAR: resp.YEAR, QUARTER: ganttUtils.getQuarter(resp.MONTH), MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }

                    if (srcObj.data === 'forward') {
                        //zoom level, year, forward - set currentView +1 month
                        let newQuarter = currentView.QUARTER + 1;
                        let newYear = currentView.YEAR;

                        if (newQuarter > 4) {
                            newQuarter = 1;
                            newYear = currentView.YEAR + 1;
                        }
                        setCurrentView({ ...currentView, QUARTER: newQuarter, YEAR: newYear });
                    }

                    if (srcObj.data === 'full-forward') {
                        //zoom level, year, full forward - set currentView to end year of oldest task
                        //TODO - set year of last end date
                        let resp = getLatestFrameStart(topList);

                        if (isNaN(resp.YEAR)) {
                            let newYear = currentView.YEAR + 1;
                            let newMonth = 1;

                            setCurrentView({ ...currentView, QUARTER: resp.QUARTER, MONTH: newMonth, YEAR: newYear });
                        } else {
                            setCurrentView({ YEAR: resp.YEAR, QUARTER: resp.QUARTER, MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }
                }

                if (zoom === 'YEAR') {
                    if (srcObj.data === 'rewind') {
                        //zoom level, year, rewind - set currentView -1 month
                        let newMonth = currentView.MONTH - 1;
                        let newYear = currentView.YEAR;

                        if (newMonth < 1) {
                            newMonth = 11;
                            newYear = newYear - 1;
                        }
                        setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                    }

                    if (srcObj.data === 'full-rewind') {
                        //zoom level, year, full rewind - set currentView to start year of oldest task
                        //TODO - find earliest task and set year to that
                        let resp = getEarliestFrameStart(topList);

                        if (resp) {
                            setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }

                    if (srcObj.data === 'forward') {
                        //zoom level, year, forward - set currentView +1 month
                        let newMonth = currentView.MONTH + 1;
                        let newYear = currentView.YEAR;

                        if (newMonth > 12) {
                            newMonth = 1;
                            newYear = newYear + 1;
                        }
                        setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                    }

                    if (srcObj.data === 'full-forward') {
                        //zoom level, year, full forward - set currentView to end year of oldest task
                        //TODO - set year of last end date
                        setCurrentView({ ...currentView, MONTH: currentView.MONTH + 1 });

                        let resp = getLatestFrameStart(topList);
                        if (isNaN(resp.YEAR)) {
                            let newMonth = currentView.MONTH + 1;
                            let newYear = currentView.YEAR;

                            if (newMonth > 12) {
                                newMonth = 1;
                                newYear = newYear + 1;
                            }
                            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                        } else {
                            setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }
                }

                if (zoom === 'MONTH') {
                    if (srcObj.data === 'rewind') {
                        //zoom level, year, rewind - set currentView -1 month
                        let newMonth = currentView.MONTH - 1;
                        let newYear = currentView.YEAR;

                        if (newMonth < 1) {
                            newMonth = 11;
                            newYear = newYear - 1;
                        }

                        setCurrentView({ YEAR: newYear, MONTH: newMonth });
                    }

                    if (srcObj.data === 'full-rewind') {
                        //zoom level, year, full rewind - set currentView to start year of oldest task
                        let resp = getEarliestFrameStart(topList);

                        if (resp) {
                            setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }

                    if (srcObj.data === 'forward') {
                        //zoom level, year, forward - set currentView +1 month
                        let newMonth = currentView.MONTH + 1;
                        let newYear = currentView.YEAR;

                        if (newMonth > 12) {
                            newMonth = 1;
                            newYear = newYear + 1;
                        }
                        setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                    }

                    if (srcObj.data === 'full-forward') {
                        //zoom level, year, full forward - set currentView to end year of oldest task
                        setCurrentView({ ...currentView, MONTH: currentView.MONTH + 1 });

                        let resp = getLatestFrameStart(topList);
                        if (isNaN(resp.YEAR)) {
                            let newMonth = currentView.MONTH + 1;
                            let newYear = currentView.YEAR;

                            if (newMonth > 12) {
                                newMonth = 1;
                                newYear = newYear + 1;
                            }
                            setCurrentView({ ...currentView, MONTH: newMonth, YEAR: newYear });
                        } else {
                            setCurrentView({ YEAR: resp.YEAR, MONTH: resp.MONTH, WEEK: resp.WEEK });
                        }
                    }
                }
                break;
            default:
                break;
        }
    }

    const listViewHandle = (e) => {
        setListViewOption(e);
        //reset our list
        // setFilteredList(topList);
    }

    const getStatusList = (listObj) => {
        if (listObj.hasOwnProperty('statuses')) {
            return listObj.statuses.map((listItem) => {
                return listItem.status;
            });
        }
        return [];
    }

    const handleShowListItemModal = (id = null) => {
        if (id) {
            setItemID(id)
        }
        if (show === true) {
            setItemID(null);
        }
        setShow(!show);
    }

    const filterInDateRange = (months, allListItems) => {
        let temp = allListItems
        let startDate = new Date()
        let startDateWithTimeStamp = startDate.getTime()
        let endDate = new Date().setMonth(startDate.getMonth() + months)

        let filteredList = temp.filter((listItem) => {
            let dueDate = new Date(listItem.due_date).getTime();
            return dueDate && dueDate >= startDateWithTimeStamp && dueDate <= endDate
        })
        return filteredList;
    }

    const checkForFilters = (tempArr, opts) => {
        let filteredList = [];

        if (opts['Show Archived'] === true) {
            filteredList = tempArr;
        }
        if (opts['Due in the next 6 months'] === true) {
            filteredList = filterInDateRange(6, tempArr)
        }
        if (opts['Due in the next year'] === true) {
            filteredList = filterInDateRange(12, tempArr)
        }
        return filteredList;
    }

    const getDropdownOption = (temp) => {
        const optionsArray = Object.keys(temp);
        let returnString = "";
        let filteredArray = optionsArray.filter((option) => {
            return temp[option] === true
        })
        if (filteredArray && filteredArray.length > 0) {
            for (let i = 0; i < filteredArray.length; i++) {
                if (i > 0) {
                    returnString = returnString + ", " + filteredArray[i].slice(0, 3) + "...";
                } else {
                    returnString = filteredArray[i];
                }
            }
        } else {
            returnString = 'Select...'
        }
        return returnString;
    }

    return (
        !loading && topList && tenant ?
            <div>
                {/* PMT Toolbar */}
                <Row className="markets-toolbar" sm={9} md={12}>
                    {/* Table | Kanban | Gantt */}
                    <Col className='view-btns-col'>
                        <label className='dropdown-label'>View</label>
                        <Dropdown className='market-view-btns standard-dropdown dropdown-text-left'>
                            <Dropdown.Toggle>
                                {listViewObj[listViewOption]}
                                <ChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    listViews.map((opt, idx) => {
                                        return (
                                            <Dropdown.Item
                                                disabled={isDisabled}
                                                eventKey={idx}
                                                id={`opt${idx}`}
                                                name={opt.value}
                                                active={listViewOption === opt.value}
                                                onClick={(e) => listViewHandle(e.target.name)}
                                            >
                                                {opt.name}
                                            </Dropdown.Item>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    {/* filter-by or grouped-by dropdown */}
                    <Col className='dropdown-col'>
                        <PMTListViewManipulator
                            className="dropdown-text-left"
                            disabled={isDisabled}
                            whichView={listViewOption}
                            type="dropdown"
                            onChange={handleShowChange}
                            assigneeList={memberList}
                            statusList={getStatusList(topList)}
                            onOptionsChange={handleViewManipOptionChange}
                        />
                    </Col>
                    {/* Checkbox "More Display Options" dropdown */}
                    <Col className='dropdown-col'>
                        <label className='dropdown-label'>More Display Options</label>
                        <CheckBoxDropDownSelector
                            className="dropdown-text-left"
                            type="dropdown"
                            options={options}
                            displayOptionHandler={displayOptionHandler}
                            labelString={getDropdownOption(options)}
                        />
                    </Col>

                    {/* Export Button*/}
                    <Col className="add-intervention-col m-b-start-03" sm={12} lg={3}>

                        <MarketActionsExcelExport
                            className='icon-btn btn-primary-lg'
                        // disabled={isDisabled}
                        // filteredMarkets={filteredList}
                        />
                        {/* Add Intervention Button*/}
                        <Button className='icon-btn surface-brand-primary btn-primary-lg'
                            onClick={() => handleShowListItemModal()}
                        >
                            <PlusCircle />
                            {`Add ${PMTCONSTANTS.LIST_ITEM_NAME[appName.fields.appAlternateTitle]}`}
                        </Button>
                    </Col>
                </Row>
                {/* Intervention Display*/}
                <Row>
                    {
                        <Col
                            className={topList.length < 1 ? `view-display no-interventions ${CONSTANTS.MARKET_ACTIONS_REVERSE_VIEWS[listViewOption]}` : `view-display ${CONSTANTS.MARKET_ACTIONS_REVERSE_VIEWS[listViewOption]}`}
                        >
                            {
                                filterToListsSelected(topList, selectedListItems) && filterToListsSelected(topList, selectedListItems).length > 0 ?

                                    listViewOption && listViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.KANBAN ?
                                        <PMTKanbanView
                                            listNames={selectedListItems}
                                            topList={filterToListsSelected(topList, selectedListItems)}
                                            onUpdate={handleListUpdate}
                                            separateByField={sepByField}
                                            tenant={tenant}
                                            handleShowListItemModal={handleShowListItemModal}
                                        />
                                        :
                                        listViewOption === CONSTANTS.MARKET_ACTIONS_VIEWS.GANTT ?
                                            <PMTGanttView
                                            />
                                            :
                                            <PMTTableView
                                            />
                                    :
                                    <PMTNoItemInList
                                        listName={topList.list_name}
                                        tenant={tenant}
                                        handleShowAddItemModal={handleShowListItemModal}
                                        dynamicItemName={'interventions'}
                                        dynamicAppName={tenant.tenant.tenantslug === 'Gavi MST' ? 'Roadmap Actions' : 'Market Actions'}
                                        appUrl='market_actions'
                                    />
                            }
                        </Col>
                    }
                </Row>
                {
                    show && show === true ?
                        <PMTCreateEditItemPopup
                            updateTopList={updateTopList}
                            tenant={tenant}
                            groupMembers={memberList}
                            show={show}
                            handleHideModal={() => setShow(false)}
                            listName={'Test Market'}// listName={selectedListItems && selectedListItems.length > 0 ? filterToListsSelected(topList, selectedListItems)[0].list_name : null}
                            itemID={itemID ? itemID : null}
                            mode={itemID && itemID !== null ? 'edit' : 'create'}
                            topList={topList}
                            appName={appName}
                            apiKey={apiKey}
                            clearItemID={() => setItemID()}
                            handleReload={handleReload}
                            suppliers={suppliers}
                            currentView={listViewOption}
                        />
                        : null
                }
            </div>
            :
            <ListSkeletonLoader preview={false} />
    )
}

export default PMTDisplay;